const DEFAULT = "--";
const BASE_GOOGLE_URL = "http://www.google.com/search?q=";
const BASE_NASDAQ_IPO_URL = "https://www.nasdaq.com/market-activity/ipos/overview?dealId=";
const BASE_NASDAQ_STOCK_URL = "https://www.nasdaq.com/market-activity/stocks/";

function StockBuilder(name_, symbol_, date_, exchange_, status_, price_, shares_, value_, dealId_) {
    this.name = name_;
    this.symbol = symbol_;
    this.date = date_;
    this.exchange = exchange_;
    this.status = status_;
    this.price = price_;
    this.shares = shares_;
    this.value = value_;
    this.dealId = dealId_;
}

StockBuilder.prototype.CreatePerformer = function (dealId_, name_, symbol_, date_, status_, logo_, lastPrice_ , lastVariation_) {
    this.dealId = dealId_;
    this.name = name_;
    this.symbol = symbol_;
    this.date = date_;
    this.status = status_;
    this.logo = logo_;
    this.lastPrice = lastPrice_;
    this.lastVariation = lastVariation_;
}

StockBuilder.prototype.CreateTrending = function (name_, status_, dealId_) {
    this.name = name_;
    this.status = status_;
    this.dealId = dealId_;
}

StockBuilder.prototype.Deserialize = function (jsonObject_) {
    if (!jsonObject_) return;
    this.name = jsonObject_.name;
    this.symbol = jsonObject_.symbol;
    this.date = jsonObject_.date;
    this.exchange = jsonObject_.exchange;
    this.status = jsonObject_.status;
    this.price = jsonObject_.price;
    this.shares = jsonObject_.shares;
    this.value = jsonObject_.value;
    this.dealId = jsonObject_.dealId;
    this.employee = jsonObject_.employee;
    this.shareOverAlloted = jsonObject_.shareOverAlloted;
    this.address = jsonObject_.address;
    this.phone = jsonObject_.phone;
    this.website = jsonObject_.website;
    this.ceo = jsonObject_.ceo;
    this.stateOfInc = jsonObject_.stateOfInc;
    this.totalExpense = jsonObject_.totalExpense;
    this.shareholdersSharesOffered = jsonObject_.shareholdersSharesOffered;
    this.sharesOutstanding = jsonObject_.sharesOutstanding;
    this.lockupPeriod = jsonObject_.lockupPeriod;
    this.lockupExpiration = jsonObject_.lockupExpiration;
    this.quietPeriodExpiration = jsonObject_.quietPeriodExpiration;
    this.cik = jsonObject_.cik;
    this.description = jsonObject_.description;
    this.revenue = jsonObject_.revenue;
    this.netIncome = jsonObject_.netIncome;
    this.totalAssets = jsonObject_.totalAssets;
    this.totalLiabilities = jsonObject_.totalLiabilities;
    this.stockholdersEquity = jsonObject_.stockholdersEquity;
    this.experts = jsonObject_.experts;
    this.country = jsonObject_.country;
    this.state = jsonObject_.state;
    this.city = jsonObject_.city;
    this.industry = jsonObject_.industry;
    this.sector = jsonObject_.sector;
    this.logo = jsonObject_.logo;
    this.overwritePrice = jsonObject_.overwritePrice;
    this.lastUpdate = jsonObject_.lastUpdate;
    this.lastPrice = jsonObject_.lastPrice;
    this.lastVariation = jsonObject_.lastVariation;
    this.logoDownloadUrl = jsonObject_.logoDownloadUrl;
    
}

StockBuilder.prototype.isUnknown = function (param_) {
    return ((!param_) || (param_ === DEFAULT) || (param_.length < 1) || (JSON.stringify(param_).includes("undefined")));
};

StockBuilder.prototype.getName = function () {
    return !this.isUnknown(this.name) ? this.name : DEFAULT;
};

StockBuilder.prototype.getSymbol = function () {
    return !this.isUnknown(this.symbol) ? this.symbol : DEFAULT;
};

StockBuilder.prototype.getDate = function () {
    return !this.isUnknown(this.date) ? this.date : DEFAULT;
};

StockBuilder.prototype.setDate = function (new_) {
    this.date = new_;
};

StockBuilder.prototype.getExchange = function () {
    return !this.isUnknown(this.exchange) ? this.exchange : DEFAULT;
};

StockBuilder.prototype.getStatus = function () {
    return !this.isUnknown(this.status) ? this.status : DEFAULT;
};

StockBuilder.prototype.setStatus = function (new_) {
    this.status = new_;
};

StockBuilder.prototype.getPrice = function () {
    return !this.isUnknown(this.price) ? this.price : DEFAULT;
};

StockBuilder.prototype.getShares = function () {
    return !this.isUnknown(this.shares) ? this.shares : DEFAULT;
};

StockBuilder.prototype.getValue = function () {
    return !this.isUnknown(this.value) ? this.value : DEFAULT;
};

StockBuilder.prototype.getDealId = function () {
    return this.dealId ? this.dealId : DEFAULT;
};

StockBuilder.prototype.setDealId = function (dealId_) {
    this.dealId = dealId_;
};

StockBuilder.prototype.getEmployee = function () {
    return !this.isUnknown(this.employee) ? this.employee : DEFAULT;
};

StockBuilder.prototype.setEmployee = function (new_) {
    this.employee = new_;
};

StockBuilder.prototype.getShareOverAlloted = function () {
    return !this.isUnknown(this.shareOverAlloted) ? this.shareOverAlloted : DEFAULT;
};

StockBuilder.prototype.setShareOverAlloted = function (new_) {
    this.shareOverAlloted = new_;
};

StockBuilder.prototype.getAddress = function () {
    return !this.isUnknown(this.address) ? this.address : DEFAULT;
};

StockBuilder.prototype.setAddress = function (new_) {
    this.address = new_;
};

StockBuilder.prototype.getPhone = function () {
    return !this.isUnknown(this.phone) ? this.phone : DEFAULT;
};

StockBuilder.prototype.setPhone = function (new_) {
    this.phone = new_;
};

StockBuilder.prototype.getWebsite = function () {
    return !this.isUnknown(this.website) ? this.website : DEFAULT;
};

StockBuilder.prototype.setWebsite = function (new_) {
    this.website = new_;
};

StockBuilder.prototype.getCeo = function () {
    return !this.isUnknown(this.ceo) ? this.ceo : DEFAULT;
};

StockBuilder.prototype.setCeo = function (new_) {
    this.ceo = new_;
};

StockBuilder.prototype.getStateOfInc = function () {
    return !this.isUnknown(this.stateOfInc) ? this.stateOfInc : DEFAULT;
};

StockBuilder.prototype.setStateOfInc = function (new_) {
    this.stateOfInc = new_;
};

StockBuilder.prototype.getFiscalYearEnd = function () {
    return !this.isUnknown(this.fiscalYearEnd) ? this.fiscalYearEnd : DEFAULT;
};

StockBuilder.prototype.setFiscalYearEnd = function (new_) {
    this.fiscalYearEnd = new_;
};

StockBuilder.prototype.getTotalExpense = function () {
    return !this.isUnknown(this.totalExpense) ? this.totalExpense : DEFAULT;
};

StockBuilder.prototype.setTotalExpense = function (new_) {
    this.totalExpense = new_;
};

StockBuilder.prototype.getShareholdersSharesOffered = function () {
    return !this.isUnknown(this.shareholdersSharesOffered) ? this.shareholdersSharesOffered : DEFAULT;
};

StockBuilder.prototype.setShareholdersSharesOffered = function (new_) {
    this.shareholdersSharesOffered = new_;
};

StockBuilder.prototype.getSharesOutstanding = function () {
    return !this.isUnknown(this.sharesOutstanding) ? this.sharesOutstanding : DEFAULT;
};

StockBuilder.prototype.setSharesOutstanding = function (new_) {
    this.sharesOutstanding = new_;
};

StockBuilder.prototype.getLockupPeriod = function () {
    return !this.isUnknown(this.lockupPeriod) ? this.lockupPeriod : DEFAULT;
};

StockBuilder.prototype.setLockupPeriod = function (new_) {
    this.lockupPeriod = new_;
};

StockBuilder.prototype.getLockupExpiration = function () {
    return !this.isUnknown(this.lockupExpiration) ? this.lockupExpiration : DEFAULT;
};

StockBuilder.prototype.setLockupExpiration = function (new_) {
    this.lockupExpiration = new_;
};

StockBuilder.prototype.getQuietPeriodExpiration = function () {
    return !this.isUnknown(this.quietPeriodExpiration) ? this.quietPeriodExpiration : DEFAULT;
};

StockBuilder.prototype.setQuietPeriodExpiration = function (new_) {
    this.quietPeriodExpiration = new_;
};

StockBuilder.prototype.getCik = function () {
    return !this.isUnknown(this.cik) ? this.cik : DEFAULT;
};

StockBuilder.prototype.setCik = function (new_) {
    this.cik = new_;
};

StockBuilder.prototype.getDescription = function () {
    if (!this.description || this.description.length < 1) return DEFAULT;
    let customEscape = "--xx ";
    let formattedDescription = this.description;
    formattedDescription = formattedDescription.replace(/(\r\n|\n|\r)/gm, customEscape);
    let re = new RegExp(customEscape + customEscape, "g");
    formattedDescription = formattedDescription.replace(re, "\n");
    re = new RegExp(customEscape, "g");
    formattedDescription = formattedDescription.replace(re, " ");
    re = new RegExp(" +", "g");
    formattedDescription = formattedDescription.replace(re, " ");
    return formattedDescription;
};

StockBuilder.prototype.setDescription = function (new_) {
    this.description = new_;
};

StockBuilder.prototype.getRevenue = function () {
    return !this.isUnknown(this.revenue) ? this.revenue : DEFAULT;
};

StockBuilder.prototype.setRevenue = function (new_) {
    this.revenue = new_;
};

StockBuilder.prototype.getNetIncome = function () {
    return !this.isUnknown(this.netIncome) ? this.netIncome : DEFAULT;
};

StockBuilder.prototype.setNetIncome = function (new_) {
    this.netIncome = new_;
};

StockBuilder.prototype.getTotalAssets = function () {
    return !this.isUnknown(this.totalAssets) ? this.totalAssets : DEFAULT;
};

StockBuilder.prototype.setTotalAssets = function (new_) {
    this.totalAssets = new_;
};

StockBuilder.prototype.getTotalLiabilities = function () {
    return !this.isUnknown(this.totalLiabilities) ? this.totalLiabilities : DEFAULT;
};

StockBuilder.prototype.setTotalLiabilities = function (new_) {
    this.totalLiabilities = new_;
};

StockBuilder.prototype.getStockholdersEquity = function () {
    return !this.isUnknown(this.stockholdersEquity) ? this.stockholdersEquity : DEFAULT;
};

StockBuilder.prototype.setStockholdersEquity = function (new_) {
    this.stockholdersEquity = new_;
};

StockBuilder.prototype.getExperts = function () {
    return !this.isUnknown(this.experts) ? this.experts : DEFAULT;
};

StockBuilder.prototype.setExperts = function (new_) {
    this.experts = new_;
};

StockBuilder.prototype.getCountry = function () {
    return !this.isUnknown(this.country) ? this.country : DEFAULT;
};

StockBuilder.prototype.setCountry = function (new_) {
    this.country = new_;
};

StockBuilder.prototype.getState = function () {
    return !this.isUnknown(this.state) ? this.state : DEFAULT;
};

StockBuilder.prototype.setState = function (new_) {
    this.state = new_;
};

StockBuilder.prototype.getCity = function () {
    return !this.isUnknown(this.city) ? this.city : DEFAULT;
};

StockBuilder.prototype.setCity = function (new_) {
    this.city = new_;
};

StockBuilder.prototype.getIndustry = function () {
    return !this.isUnknown(this.industry) ? this.industry : DEFAULT;
};

StockBuilder.prototype.setIndustry = function (new_) {
    this.industry = new_;
};

StockBuilder.prototype.getSector = function () {
    return !this.isUnknown(this.sector) ? this.sector : DEFAULT;
};

StockBuilder.prototype.setSector = function (new_) {
    this.sector = new_;
};

StockBuilder.prototype.getLogo = function () {
    return this.logo ? this.logo : null;
};

StockBuilder.prototype.setLogo = function (new_) {
    this.logo = new_;
};

StockBuilder.prototype.getOverwritePrice = function () {
    return this.overwritePrice ? this.overwritePrice : DEFAULT;
};

StockBuilder.prototype.setOverwritePrice = function (new_) {
    this.overwritePrice = new_;
};

StockBuilder.prototype.getLastUpdate = function () {
    return this.lastUpdate ? formattDate(this.lastUpdate) : DEFAULT;
};

StockBuilder.prototype.setLastUpdate = function (new_) {
    this.lastUpdate = new_;
};

StockBuilder.prototype.getLastPrice = function () {
    return this.lastPrice ? this.lastPrice : DEFAULT;
};

StockBuilder.prototype.setLastPrice = function (new_) {
    this.lastPrice = new_;
};

StockBuilder.prototype.getVariation = function () {
    if (!this.lastVariation) return DEFAULT;
    try {
        let parsed = parseFloat(this.lastVariation);
        let variationString = "";
        parsed = parsed.toFixed(2);
        if (!(parsed < 0)) {
            variationString += "+";
        }
        variationString += parsed + "%";
        return variationString;
    } catch (err) {
        return this.lastVariation;
    }
};

StockBuilder.prototype.setVariation = function (new_) {
    this.lastVariation = new_;
};

StockBuilder.prototype.getLogoDownloadUrl = function () {
    return this.logoDownloadUrl ? this.logoDownloadUrl : null;
};

StockBuilder.prototype.setLogoDownloadUrl = function (new_) {
    this.logoDownloadUrl = new_;
};

StockBuilder.prototype.getInvestingLink = function () {
    return this.investingLink ? this.investingLink : null;
};

StockBuilder.prototype.setInvestingLink = function (new_) {
    this.investingLink = new_;
};

StockBuilder.prototype.getGoogleLink = function () {
    if (!this.isUnknown(this.googleLink)) return this.googleLink;
    let formattedName = getFormattedName(this , this.name);
    if (formattedName && formattedName.length > 1) {
        formattedName = formattedName.replace(/-/g, "+");
    }
    let searchUrl = BASE_GOOGLE_URL + formattedName;
    this.googleLink = searchUrl;
    return this.googleLink;
}

StockBuilder.prototype.setGoogleLink = function (new_) {
    this.googleLink = new_;
}

StockBuilder.prototype.getNasdaqLink = function () {
    if (this.isUnknown(this.symbol)) {
        return BASE_NASDAQ_IPO_URL + this.dealId;
    } else {
        return BASE_NASDAQ_STOCK_URL + this.symbol;
    }
}

StockBuilder.prototype.getRobinhoodLink = function () {
    return this.robinhoodLink ? this.robinhoodLink : null;
};

StockBuilder.prototype.setRobinhoodLink = function (new_) {
    this.robinhoodLink = new_;
};

StockBuilder.prototype.getWebullLink = function () {
    return this.webullLink ? this.webullLink : null;
};

StockBuilder.prototype.setWebullLink = function (new_) {
    this.webullLink = new_;
};

StockBuilder.prototype.getEtoroLink = function () {
    return this.etoroLink ? this.etoroLink : null;
};

StockBuilder.prototype.setEtoroLink = function (new_) {
    this.etoroLink = new_;
};

StockBuilder.prototype.getAmeritradeLink = function () {
    return this.ameritradeLink ? this.ameritradeLink : null;
};

StockBuilder.prototype.setAmeritradeLink = function (new_) {
    this.ameritradeLink = new_;
};

StockBuilder.prototype.getFidelityLink = function () {
    return this.fidelityLink ? this.fidelityLink : null;
};

StockBuilder.prototype.setFidelityLink = function (new_) {
    this.fidelityLink = new_;
};

function getFormattedName(context_ , name_) {
    if (context_.isUnknown(name_)) return null;
    try {
        let formattedName = name_;
        formattedName = formattedName.replace(/\s/g, "-");
        formattedName = formattedName.replace(/&/g, "and");
        formattedName = formattedName.replace(/\./g, "");
        formattedName = formattedName.replace(/,/g, "");
        formattedName = formattedName.toLowerCase();
        return formattedName;
    } catch (e) {
        return null;
    }
}

function formattDate(date_) {
    if (!date_) return date_;
    let yyyy = date_.slice(0, 4);
    let mm = date_.slice(4, 6);
    let dd = date_.slice(-2);
    return yyyy + "-" + mm + "-" + dd;
}

module.exports = StockBuilder;