import React from "react";
import { useHistory } from "react-router-dom";
import FireBaseManager from "managers/FireBaseManager";
import StockBuilder from 'builders/StockBuilder';

export default function StockLogo(props) {
    const {
        stock,
        width,
        height,
        minWidth,
        minHeight,
        round,
        padding
    } = props;

    const [logoUrl, setLogoUrl] = React.useState(stock && stock.getLogoDownloadUrl() ? stock.getLogoDownloadUrl() : null);
    const history = useHistory();
    const style = {
        minWidth: minWidth,
        minHeight: minHeight,
        borderRadius: round ? "50%" : null,
        backgroundColor: logoUrl ? "white" : null,
        padding: padding,
        flex: 1,
        alignItems: 'center'
    }

    function navigateToStockInfo(stockBuilder_) {
        if (!stockBuilder_) return;
        if (!(stockBuilder_ instanceof StockBuilder)) return;
        if (stockBuilder_.isUnknown(stockBuilder_.getDealId())) return;
        history.push("/u/ipo-info?id=" + stockBuilder_.getDealId());
    }

    React.useEffect(() => {
        async function fetchLogoUrl() {
            var firebaseManager = new FireBaseManager();
            let url_ = await firebaseManager.getDownloadUrl(stock.getLogo());
            stock.setLogoDownloadUrl(url_);
            setLogoUrl(url_);
        }

        if (!stock || !stock.getLogo()) {
            setLogoUrl(null);
            return;
        }

        if (stock.getLogoDownloadUrl()) {
            setLogoUrl(stock.getLogoDownloadUrl());
            return;
        }

        fetchLogoUrl();
    }, [stock])

    return (
        logoUrl ?
            <a href="/#" onClick={e => {
                e.preventDefault();
                navigateToStockInfo(stock);
            }}>
                <img id={logoUrl} src={logoUrl} width={width} heigth={height} alt="..." style={style} />
            </a>
            : null
    );
}

StockLogo.defaultProps = {
    stock: null,
    width: "40",
    height: "20",
    minWidth: "80px",
    minHeight: "80px",
    round: false,
    padding: ".5px"
};
