import React from "react";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import Rating from '@material-ui/lab/Rating';
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardImage from "components/Card/CardImage.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Accordion from "components/Accordion/Accordion.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";

//Custom stuff
import UserManager from "managers/UserManager";
import BrokerManager from "managers/BrokerManager";
import StockLogo from "utilities/StockLogo";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: TopPerformersView ";
const DEBUG = false;
const brokerManager = new BrokerManager();
const userManager = new UserManager();
const showLoadingBar = <LinearProgress />;
const emptyContent = <div></div>;
const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}

export default function RegularTables() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();

    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [brokerListItems, setBrokerListItems] = React.useState([]);
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    React.useEffect(() => {

        function createBrokerItem(brokerItem_ , index_) {
            return (
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <MetaTags>
                        <title>Top Brokers</title>
                        <meta name="description" content="Discover the list of the best Brokers for IPO trading and subscription. We provide up to date ratings and eligibility requirements.
                        Find ratings for IPO brokers like webull, robinhood, interactive brokers, etoro, td ameritrade, fidelity, Charles Schwab and more." />
                        <meta property="og:title" content="Top IPO Brokers" />
                    </MetaTags>

                    <Card>
                        <CardHeader stats icon>
                            <CardImage color="transparent">
                                <StockLogo stock={brokerItem_} round={false} width="80" height="40" minWidth="80px" minHeight="80px" />
                            </CardImage>
                            <h4 className={classes.cardCategory}>{brokerItem_.getName()}</h4>
                            <h3 className={classes.cardTitle}> <b> #{index_} </b> </h3>
                        </CardHeader>
                        <CardBody>
                            <Accordion
                                active={2}
                                collapses={[
                                    {
                                        title: <h4 className={classes.cardCategory} > <Success><b> Eligibility </b></Success> </h4>,
                                        content:
                                            <h5 className={classes.stats} style={{ whiteSpace: 'pre-wrap' }} > {brokerItem_.getEligibility()} </h5>
                                    },
                                    {
                                        title: <h4 className={classes.cardCategory} > <Success><b>About </b></Success> </h4>,
                                        content:
                                            <div className={classes.stats} style={{ whiteSpace: 'pre-wrap' }}>
                                                {brokerItem_.getDescription()}
                                            </div>
                                    },
                                    {
                                        title: <h4 className={classes.cardCategory} > <Success><b> Review </b></Success> </h4>,
                                        content:
                                            <table class="center" style={{ marginLeft: "auto", marginRight: "auto" }}>
                                                <tr>
                                                    <td >Overall</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getOverallNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <td>Commissions & Fees</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getCommissionsNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <td>Offering of investments</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getOfferingNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <td>Platforms & Tools</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getToolsNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <td>Research</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getRresearchNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <td>Education</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getEducationNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <td>Mobile Trading</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getMobileNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <td>Customer Service</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getCustomerServiceNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <td>Ease of Use</td>
                                                    <td><Rating name="half-rating-read" defaultValue={brokerItem_.getUsageNote()} precision={0.5} readOnly /></td>
                                                </tr>
                                            </table>
                                    }
                                ]}
                            />
                        </CardBody>
                        <CardFooter stats>
                            <a href={"#link" + brokerItem_.getName()} onClick={e => {
                                e.preventDefault();
                                openInNewTab(brokerItem_.getUrl());
                            }}>
                                <p className={classes.cardCategory} ><Info> {brokerItem_.getUrl()} </Info></p>
                            </a>
                        </CardFooter>
                    </Card>
                </GridItem>
            );
        }

        function updateBrokerView(brokerList_) {
            let brokerListItems_ = emptyContent;
            if (brokerList_ && brokerList_.length >= 1) {
                brokerListItems_ = [];
                for (let i = 0; i < brokerList_.length; i++) {
                    let brokerItem_ = brokerList_[i];
                    brokerListItems_.push(createBrokerItem(brokerItem_ , i+1));
                }
            }
            setBrokerListItems(brokerListItems_);
        }

        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            setLoadingBar(showLoadingBar);
            let newData = await brokerManager.getBrokers();
            if (newData && !newData.length < 1) {
                updateBrokerView(newData);
            } else {
                updateBrokerView([]);
            }
            setLoadingBar();
        }

        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            } else {
                if (userManager.isConnected()) {
                    fetchData();
                }
            }
        });
    }, [classes]);

    if (adBlockDetected) {
        return (<SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="An Blocker is detected !"
            onConfirm={() => window.location.reload()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="I have disabled AdBlock"
        >
            This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
        </SweetAlert>);
    } else if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        const login = () => {
            history.push("/auth/login-page");
        };
        const goBack = () => {
            history.goBack();
        };

        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <div>
                <AdsenseView slotType="homeTop" />
                {loadingBar }
                < GridContainer >
                    {brokerListItems }
                </GridContainer >
                <AdsenseView slotType="homeBottom" />
            </div>
        );
        return userConnectedLayout;
    }
}
