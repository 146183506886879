import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import AccessTime from "@material-ui/icons/AccessTime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

//Custom stuff
import { NewsManager} from "managers/NewsManager";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: NewsViewComponent ";
const DEBUG = false;
const useStyles = makeStyles(styles);
const showLoadingBar = <LinearProgress />;
const emptyContent = <div></div>;
const emptyItemsList = [emptyContent, emptyContent, emptyContent];
var newsManagers = [];
function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}

export default function NewsViewComponent(props) {
    const {
        newsProvider,
        searchName,
        maxItems
    } = props;

    const classes = useStyles();
    const [newsloadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [newsListItems, setNewsListItems] = React.useState(emptyItemsList);

    React.useEffect(() => {

        function createNewsItem(newsItem_) {
            return (
                <GridItem md={12} key={newsItem_.getLink()}>
                    <Card product className={classes.cardHover}>
                        <CardHeader image className={classes.cardHeaderHover}
                            thumbnail newsUrl={newsItem_.getLink()} newsThumbnail={newsItem_.getThumbnail()}>
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardProductTitle}>
                                <a href="#pablo" onClick={e => {
                                    e.preventDefault();
                                    openInNewTab(newsItem_.getLink());
                                }}>
                                    {newsItem_.getTitle() ? newsItem_.getTitle() : ""}
                                </a>
                            </h4>
                            <p className={classes.cardProductDesciprion}>
                                {newsItem_.getDescription() ? newsItem_.getDescription() : ""}
                            </p>
                        </CardBody>
                        <CardFooter product>
                            <div className={`${classes.stats} ${classes.productStats}`}>
                                <AccessTime /> {newsItem_.getPubDate() ? newsItem_.getPubDate() : ""}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            );
        }

        function createAdItem(newsItem_) {
            return (
                <GridItem md={12} key={"AD" + newsItem_.getLink()}>
                    <AdsenseView slotType="newsFeed" classNames={classes} />
                </GridItem>
            );
        }

        function updateNewsView(newsList_) {
            let newsListItems_ = [];
            newsListItems_[0] = emptyContent;
            newsListItems_[1] = emptyContent;
            newsListItems_[2] = emptyContent;
            if (newsList_ && newsList_.length >= 1) {
                newsListItems_[0] = [];
                newsListItems_[1] = [];
                newsListItems_[2] = [];
                let index_ = 0;
                for (let i = 0; i < newsList_.length && i < maxItems; i++) {
                    let newsitem_ = newsList_[i];
                    newsListItems_[index_].push(createNewsItem(newsitem_));
                    index_++;
                    if (index_ > 2) {
                        index_ = 0;
                    }
                    if (i % 4 === 0) {
                        newsListItems_[index_].push(createAdItem(newsitem_));
                        index_++;
                        if (index_ > 2) {
                            index_ = 0;
                        }
                    }
                    
                    
                }
            }
            setNewsListItems(newsListItems_);
        }

        async function fetchNews() {
            setLoadingBar(showLoadingBar);
            let newData;
            if (searchName) {
                if (DEBUG) console.log(TAG + "fetchNews called for " + searchName);
                newData = await newsManager.fetchSpecificNews(searchName);
            } else {
                if (DEBUG) console.log(TAG + "fetchNews called for " + newsProvider.name);
                newData = await newsManager.fetchNews(newsProvider);
            }
            if (newData && !newData.length < 1) {
                updateNewsView(newData);
                setLoadingBar();
            } else {
                if (DEBUG) console.log(TAG + "News not found for " + searchName);
                updateNewsView([]);
            }
        }

        var newsManager = null;
        if (!searchName && !newsProvider) return;
        let newsManagerKey = searchName;
        if (!newsManagerKey) newsManagerKey = newsProvider ? newsProvider.name : null;
        newsManager = newsManagers[newsManagerKey];
        if (!newsManager) {
            newsManager = new NewsManager();
            newsManagers[newsManagerKey] = newsManager;
        }

        fetchNews();

    }, [classes, searchName, newsProvider, maxItems])

    return (
        searchName || newsProvider?
            <div>
                {newsloadingBar}
                < br />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <GridContainer>{newsListItems[0]}</GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <GridContainer>{newsListItems[1]}</GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <GridContainer>{newsListItems[2]}</GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
            : <div> {newsloadingBar} </div>
    );
}

NewsViewComponent.defaultProps = {
    searchName: null,
    newsProvider: null,
    maxItems: 50
};
