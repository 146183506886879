import React from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import LinearProgress from '@material-ui/core/LinearProgress';
import FormLabel from "@material-ui/core/FormLabel";

//Custom stuff
import UserManager from "managers/UserManager";
import UserBuilder from 'builders/UserBuilder';
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: UserProfileView ";
const DEBUG = false;
const userManager = new UserManager();
const showLoadingBar = <LinearProgress />;
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formStyles);
const defaultUserInfo = new UserBuilder();

async function onClickSignout(history_) {
    if (DEBUG) console.log(TAG + "onClickSignout called");
    await userManager.signOut();
    history_.push("/u/home");
}

async function onClickDelete(history_) {
    if (DEBUG) console.log(TAG + "onClickDelete called");
    await userManager.deleteUser();
    history_.push("/u/home");
}

export default function UserProfile() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const history = useHistory();
    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [userInfo, setUserInfo] = React.useState(defaultUserInfo);

    if (!userManager.isConnected()) {
        history.push("/u/home");
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (DEBUG) console.log(TAG + "handleSubmit : called ");
        let pseudo = event.target.Pseudo;
        if (pseudo.value) {
            await userManager.updatePseudo(pseudo.value);
            history.push("/u/home");
        }
    }

    React.useEffect(() => {
        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            setLoadingBar(showLoadingBar);
            let newUserInfo = await userManager.getUserInfo();
            if (newUserInfo) {
                await setUserInfo(newUserInfo);
            } else {
                await setUserInfo(defaultUserInfo);
            }
            setLoadingBar();
        }
        if (userManager.isConnected()) {
            fetchData();
        }
    }, []);

    return (
        <div>
            <AdsenseView slotType="homeTop" />
            <GridContainer>
                <GridItem xs={12}>
                    {loadingBar}
                    <Card profile>
                        <CardAvatar profile>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                <img src={userInfo.getPhoto()} alt="..." />
                            </a>
                        </CardAvatar>
                        <CardBody profile>
                            <h4 className={classes.cardTitle}>{userInfo.getName()}</h4>
                            <form
                                onSubmit={handleSubmit}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={formClasses.labelHorizontal}>
                                            Email
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CustomInput
                                            id="email_adress"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "email",
                                                placeholder: `${userInfo.getMail()}`,
                                                disabled: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={formClasses.labelHorizontal}>
                                            Inscription date </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CustomInput
                                            id="inscription-date"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "text",
                                                placeholder: `${userInfo.getInscription()}`,
                                                disabled: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={formClasses.labelHorizontal}>
                                            Last login </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CustomInput
                                            id="last-login"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "text",
                                                placeholder: `${userInfo.getLast()}`,
                                                disabled: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={formClasses.labelHorizontal}>
                                            Pseudo </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CustomInput
                                            id="Pseudo"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: "text",
                                                placeholder: `${userInfo.getPseudo()}`
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justify="center">
                                    <Button color="rose" type="submit"  >Update</Button>
                                    <Button color="rose" onClick={() => onClickSignout(history)}>Sign out</Button>
                                    <Button color="rose" onClick={() => onClickDelete(history)}>Delete account</Button>
                                </GridContainer>
                            </form>
                        </CardBody>


                    </Card>
                </GridItem>
            </GridContainer>
            <AdsenseView slotType="homeBottom" />
        </div>
    );
}
