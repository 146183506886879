import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "components/Accordion/Accordion.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

//Global variables
const TAG = "Ipo-Tracker: PrivacyPolicyView ";
const DEBUG = false;

const useStyles = makeStyles(styles);

export default function PrivacyPolicyView() {
    const classes = useStyles();
    if (DEBUG) console.log(TAG + "PrivacyPolicyView created");
    return (
        <GridContainer>

            <GridItem xs={12}>

                <Card>
                    <CardBody>
                        <p className={classes.cardCategory}>
                            Ingenuity built the IPO Tracker app as
                            an Ad Supported app. This SERVICE is provided by
                            Ingenuity at no cost and is intended for use as
                            is.
                        </p>
                        <p className={classes.cardCategory}>
                            This page is used to inform visitors regarding our
                            policies with the collection, use, and disclosure of Personal
                            Information if anyone decided to use our Service.
                            If you choose to use our Service, then you agree to
                            the collection and use of information in relation to this
                            policy. The Personal Information that we collect is
                            used for providing and improving the Service. We will not use or share your information with
                            anyone except as described in this Privacy Policy.
                        </p>
                        <p className={classes.cardCategory}>
                            The terms used in this Privacy Policy have the same meanings
                            as in our Terms and Conditions, which is accessible at
                            IPO Tracker unless otherwise defined in this Privacy Policy.
                        </p>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title:
                                        <h3 className={classes.cardProductTitle}>Information Collection and Use</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>
                                                For a better experience, while using our Service, we
                                                may require you to provide us with certain personally
                                                identifiable information, including but not limited to user name and user mail. The information that
                                                we request will be retained by us and used as described in this privacy policy.
                                            </p>
                                            <div>
                                                <p className={classes.cardCategory}>
                                                    The app does use third party services that may collect
                                                    information used to identify you.
                                                </p>
                                                <p className={classes.cardCategory}>
                                                    Link to privacy policy of third party service providers used
                                                    by the app
                                                </p>
                                                <ul>
                                                    <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                                                    <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li>
                                                    <li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
                                                    <li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li>
                                                    <li><a href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                                                    <li><a href="https://twitter.com/en/privacy" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Log Data</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>
                                                We want to inform you that whenever you
                                                use our Service, in a case of an error in the app
                                                we collect data and information (through third party
                                                products) on your phone called Log Data. This Log Data may
                                                include information such as your device Internet Protocol
                                                (IP) address, device name, operating system version, the
                                                configuration of the app when utilizing our Service,
                                                the time and date of your use of the Service, and other
                                                statistics.
                                            </p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Cookies</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>
                                                Cookies are files with a small amount of data that are
                                                commonly used as anonymous unique identifiers. These are sent
                                                to your browser from the websites that you visit and are
                                                stored on your device's internal memory.
                                            </p>
                                            <p className={classes.cardCategory}>
                                                This Service does not use these "cookies" explicitly. However,
                                                the app may use third party code and libraries that use
                                                "cookies" to collect information and improve their services.
                                                You have the option to either accept or refuse these cookies
                                                and know when a cookie is being sent to your device. If you
                                                choose to refuse our cookies, you may not be able to use some
                                                portions of this Service.
                                            </p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Service Providers</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>
                                                We may employ third-party companies and
                                                individuals due to the following reasons:
                                            </p>
                                            <ul>
                                                <li>To facilitate our Service</li>
                                                <li>To provide the Service on our behalf</li>
                                                <li>To perform Service-related services</li>
                                                <li>To assist us in analyzing how our Service is used</li>
                                            </ul>
                                            <p className={classes.cardCategory}>
                                                We want to inform users of this Service
                                                that these third parties have access to your Personal
                                                Information. The reason is to perform the tasks assigned to
                                                them on our behalf. However, they are obligated not to
                                                disclose or use the information for any other purpose.
                                            </p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Security</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>
                                                We value your trust in providing us your
                                                Personal Information, thus we are striving to use commercially
                                                acceptable means of protecting it. But remember that no method
                                                of transmission over the internet, or method of electronic
                                                storage is 100% secure and reliable, and we cannot
                                                guarantee its absolute security.
                                                </p>
                                            
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Links to Other Sites</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>
                                                This Service may contain links to other sites. If you click on
                                                a third-party link, you will be directed to that site. Note
                                                that these external sites are not operated by us.
                                                Therefore, we strongly advise you to review the
                                                Privacy Policy of these websites. We have
                                                no control over and assume no responsibility for the content,
                                                privacy policies, or practices of any third-party sites or
                                                services.
                                                </p>

                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Children's Privacy</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>
                                                These Services do not address anyone under the age of 13.
                                                We do not knowingly collect personally
                                                identifiable information from children under 13. In the case
                                                we discover that a child under 13 has provided
                                                us with personal information, we immediately
                                                delete this from our servers. If you are a parent or guardian
                                                and you are aware that your child has provided us with
                                                personal information, please contact us so that
                                                we will be able to do necessary actions.
                                                </p>

                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Changes to This Privacy Policy</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>
                                                We may update our Privacy Policy from
                                                time to time. Thus, you are advised to review this page
                                                periodically for any changes. We will
                                                notify you of any changes by posting the new Privacy Policy on
                                                this page.
                                                </p>

                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

            </GridItem>
        </GridContainer>
    );
}
