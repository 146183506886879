const DEFAULT = "--";

function NewsBuilder(title_, link_, description_, pubDate_, guid_) {
    this.title = title_;
    this.link = link_;
    this.description = description_;
    this.pubDate = pubDate_;
    this.guid = guid_;
}

NewsBuilder.prototype.isUnknown = function (param_) {
    return ((!param_) || (param_ === DEFAULT) || (param_.length < 1));
};

NewsBuilder.prototype.getTitle = function () {
    return this.title ? this.title : DEFAULT;
};

NewsBuilder.prototype.setTitle = function (new_) {
    this.title = new_;
};

NewsBuilder.prototype.getLink = function () {
    return this.link ? this.link : DEFAULT;
};

NewsBuilder.prototype.setLink = function (new_) {
    this.link = new_;
};

NewsBuilder.prototype.getDescription = function () {
    return this.description ? this.description : "";
};

NewsBuilder.prototype.setDescription = function (new_) {
    this.description = new_;
};

NewsBuilder.prototype.getPubDate = function () {
    return this.pubDate ? this.pubDate : DEFAULT;
};

NewsBuilder.prototype.setPubDate = function (new_) {
    this.pubDate = new_;
};

NewsBuilder.prototype.getGuid = function () {
    return this.guid ? this.guid : DEFAULT;
};

NewsBuilder.prototype.setGuid = function (new_) {
    this.guid = new_;
};

NewsBuilder.prototype.getThumbnail = function () {
    return this.thumbnail ? this.thumbnail : null;
};

NewsBuilder.prototype.setThumbnail = function (new_) {
    this.thumbnail = new_;
};

module.exports = NewsBuilder;