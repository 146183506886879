import React from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import SyncIcon from '@material-ui/icons/Sync';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Button from "components/CustomButtons/Button.js";

//Custom stuff
import UserManager from "managers/UserManager";
import WatchlistManager from "managers/WatchlistManager";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: WatchlistView ";
const DEBUG = false;
const watchlistManager = new WatchlistManager();
const userManager = new UserManager();
const showLoadingBar = <LinearProgress />;

const defaultTableHeader = ["", "Name", "Symbol", "Price", "Variation", "Status", "Date"];
const emptyTableHeader = ["No data to be displayed"];
const emptyTableContent = [[""]];

const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};
const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

export default function RegularTables() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();

    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [requestRefresh, setRequestRefresh] = React.useState(true);
    const [tableHeader, setTableHeader] = React.useState(defaultTableHeader);
    const [watchlistData, setWatchlistData] = React.useState([]);

    const syncButton = [
        <Button justIcon simple color="success" >
            <SyncIcon color="success" style={{ minHeight: "30px", minWidth: "40px", margin: "auto" }}
                onClick={e => {
                    e.preventDefault();
                    watchlistManager.clearAllData();
                    setRequestRefresh(true);
                }} />
        </Button>
    ];

    React.useEffect(() => {
        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            setLoadingBar(showLoadingBar);
            let newWatchlistData = await watchlistManager.getWatchlistTabs(setRequestRefresh, history);
            if (newWatchlistData && !newWatchlistData.length < 1) {
                setTableHeader(defaultTableHeader.concat(syncButton));
                await setWatchlistData(newWatchlistData);
            } else {
                setTableHeader(emptyTableHeader);
                setWatchlistData(emptyTableContent);
            }
            setLoadingBar();
        }
        if (userManager.isConnected()) {
            if (requestRefresh) {
                fetchData();
                setRequestRefresh(false);
            }
        }
    }, [history, requestRefresh, syncButton]);

    if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        const login = () => {
            history.push("/auth/login-page");
        };
        const goBack = () => {
            history.goBack();
        };

        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <div>
                <AdsenseView slotType="homeTop" />
                <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <FavoriteIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>WatchList</h4>
                        </CardHeader>
                        <CardBody>
                            {loadingBar}
                            <Table
                                striped
                                hover
                                tableHeaderColor="primary"
                                tableHead={tableHeader}
                                tableData={watchlistData}
                                coloredColls={[6]}
                                colorsColls={["primary"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
                <AdsenseView slotType="homeBottom" />
            </div>

        );
        return userConnectedLayout;
    }
}
