import PageBuilder from "pagesUtilities/PageBuilder";

const TAG = "Ipo-Tracker: MonthsPagesManager ";
const DEBUG = false;
const monthsCount = 12;
function MonthsPagesManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    var date = new Date();
    this.selectedYear = date.getFullYear();
    this.selectedMonth = date.getMonth();
    this.pagesArray = new Map();
    for (var i = 0; i < monthsCount; i++) {
        this.pagesArray.set(i, new PageBuilder(i, this.selectedYear, i === this.selectedMonth ? true : false));
    }
}

MonthsPagesManager.prototype.setHistory = function (history_) {
    this.history = history_;
    for (var i = 0; i < monthsCount; i++) {
        let page_ = this.pagesArray.get(i);
        page_.setHistory(history_);
        this.pagesArray.set(i, page_);
    }
}

MonthsPagesManager.prototype.getPagesStates = function () {
    if (DEBUG) console.log(TAG + "getPagesStates called");
    return this.pagesArray;
}

MonthsPagesManager.prototype.setSelectedPage = function (selectedIndex_) {
    if (DEBUG) console.log(TAG + "setSelectedPage called");
    this.selectedMonth = selectedIndex_;
    for (var i = 0; i < monthsCount; i++) {
        let page_ = this.pagesArray.get(i);
        page_.setPageActive(i === selectedIndex_? true : false);
        this.pagesArray.set(i, page_);
    }
}

MonthsPagesManager.prototype.updatePagesYear = function (previous_) {
    if (DEBUG) console.log(TAG + "updatePagesStates called");
    if (previous_) {
        this.selectedYear--;
    } else {
        this.selectedYear++;
    }
    var date = new Date();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth();
    if (currentYear === this.selectedYear) {
        this.selectedMonth = currentMonth;
    } else {
        this.selectedMonth = 0;
    }
    for (var i = 0; i < monthsCount; i++) {
        this.pagesArray.set(i, new PageBuilder(i, this.selectedYear, i === this.selectedMonth ? true : false));
    }
}

MonthsPagesManager.prototype.getSelectedPriced = function () {
    return this.pagesArray.get(this.selectedMonth).getPricedList();
}

MonthsPagesManager.prototype.getSelectedExpected = function () {
    return this.pagesArray.get(this.selectedMonth).getExpectedList();
}

MonthsPagesManager.prototype.getSelectedFiled = function () {
    return this.pagesArray.get(this.selectedMonth).getFiledList();
}

MonthsPagesManager.prototype.getSelectedWithdrawn = function () {
    return this.pagesArray.get(this.selectedMonth).getWithdrawnList();
}

export default MonthsPagesManager;