import React from "react";
import { useHistory } from "react-router-dom";
import queryString from 'query-string'
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

//Custom stuff
import UserManager from "managers/UserManager";
import StockInfoManager from "managers/StockInfoManager";
import IpoHeaderCard from "utilities/IpoHeaderCard";
import IpoStatusCard from "utilities/IpoStatusCard";
import IpoInfoCard from "utilities/IpoInfoCard";
import CustomChart from "utilities/CustomChart";
import NewsView from "utilities/NewsViewComponent";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: StockView ";
const DEBUG = false;
const userManager = new UserManager();
const showLoadingBar = <LinearProgress />;
const emptyContent = <div></div>;

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

export default function StockView() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();
    const value = queryString.parse(window.location.search);
    const dealId = value.id;
    const [stockName, setStockName] = React.useState("New IPO");
    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [stockInfoView, setStockInfoView] = React.useState(emptyContent);
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    React.useEffect(() => {

        function updateInfoView(stockList_) {
            if (!stockList_ || !stockList_[0]) return;
            let dataView = (
                <div>
                <AdsenseView slotType="homeTop" />
                <GridContainer justify="center">
                    <GridItem xs={12}>
                        <IpoHeaderCard stockBuilder={stockList_[0]} />
                        <IpoStatusCard stocksList={stockList_} />
                        </GridItem>
                        <GridItem md={8}>
                            <IpoInfoCard stockBuilder={stockList_[0]} type="ipoDetails" />
                        </GridItem>
                        <GridItem md={4}>
                            <IpoInfoCard stockBuilder={stockList_[0]} type="experts" />
                        </GridItem>
                        <GridItem xs={12}>
                            <CustomChart title="Advanced Chart" type="advancedChart" builder={stockList_[0]} />
                        </GridItem>                   
                        <GridItem md={4}>
                        <IpoInfoCard stockBuilder={stockList_[0]} type="buisness" />
                    </GridItem>
                        <GridItem md={4}>
                        <IpoInfoCard stockBuilder={stockList_[0]} type="financial" />
                    </GridItem>
                        <GridItem md={4}>
                        <IpoInfoCard stockBuilder={stockList_[0]} type="contact" />
                    </GridItem>
                    
                    </GridContainer>
                    <AdsenseView slotType="homeMiddle" />
                    <br />
                    <h3>Related News</h3>
                    <NewsView searchName={stockList_[0].getName()} maxItems={30} />
                    <AdsenseView slotType="homeBottom" />
                </div>
            );

            setStockInfoView(dataView);
        }

        function updateMetaInfo(stockList_) {
            if (!stockList_ || !stockList_[0] || !stockList_[0].getName()) return;
            setStockName(stockList_[0].getName());
        }

        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            setLoadingBar(showLoadingBar);
            const stockInfoManager = new StockInfoManager();
            let newData = await stockInfoManager.getInfoByDealId(dealId);
            if (newData) {
                await updateMetaInfo(newData);
                await updateInfoView(newData);
            } else {
                await updateInfoView(null);
            }
            setLoadingBar();
        }

        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            } else {
                if (userManager.isConnected()) {
                    fetchData();
                }
            }
        });
    }, [classes, dealId]);

    if (adBlockDetected) {
        return (<SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="An Blocker is detected !"
            onConfirm={() => window.location.reload()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="I have disabled AdBlock"
        >
            This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
        </SweetAlert>);
    } else if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        const login = () => {
            history.push("/auth/login-page");
        };
        const goBack = () => {
            history.goBack();
        };

        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <div>
                <MetaTags>
                    <title>{stockName + " IPO"} </title>
                    <meta name="description" content={"When will " + stockName + " become public. Find " + stockName + " IPO date , valuation, price , exchange and more details. Keep track of breaking news and updates."} />
                    <meta property="og:title" content={stockName} />
                </MetaTags>
                {loadingBar}
                {stockInfoView}
            </div>
        );
        return userConnectedLayout;
    }
}
