const DEFAULT = "--";

function AnticipatedBuilder(name_, status_, description_, logo_, logoDownloadUrl_, url_, dbDealId_) {
    this.name = name_;
    this.status = status_;
    this.description = description_;
    this.logo = logo_;
    this.logoDownloadUrl = logoDownloadUrl_;
    this.url = url_;
    this.dbDealId = dbDealId_;
}

AnticipatedBuilder.prototype.isUnknown = function () {
    return ((!this.name) || (this.name === DEFAULT));
};

AnticipatedBuilder.prototype.getName = function () {
    return this.name ? this.name : DEFAULT;
};

AnticipatedBuilder.prototype.getStatus = function () {
    return this.status ? this.status : DEFAULT;
};

AnticipatedBuilder.prototype.getDescription = function () {
    if (!this.description) return DEFAULT;
    let formattedDescription = this.description.replace(/\\n/g, "\n");
    return formattedDescription ? formattedDescription : DEFAULT;
};

AnticipatedBuilder.prototype.getLogo = function () {
    return this.logo ? this.logo : null;
};

AnticipatedBuilder.prototype.getUrl = function () {
    return this.url ? this.url : DEFAULT;
};

AnticipatedBuilder.prototype.setName = function (new_) {
    this.name = new_;
};

AnticipatedBuilder.prototype.setStatus = function (new_) {
    this.status = new_;
};

AnticipatedBuilder.prototype.setDescription = function (new_) {
    this.description = new_;
};

AnticipatedBuilder.prototype.setLogo = function (new_) {
    this.logo = new_;
};

AnticipatedBuilder.prototype.setUrl = function (new_) {
    this.url = new_;
};

AnticipatedBuilder.prototype.getLogoDownloadUrl = function () {
    return this.logoDownloadUrl ? this.logoDownloadUrl : null;
};

AnticipatedBuilder.prototype.setLogoDownloadUrl = function (new_) {
    this.logoDownloadUrl = new_;
};

AnticipatedBuilder.prototype.getDealId = function () {
    return this.dbDealId ? this.dbDealId : null;
};

AnticipatedBuilder.prototype.setDealId = function (new_) {
    this.dbDealId = new_;
};

module.exports = AnticipatedBuilder;