import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import "firebase/remote-config";
import FireBaseUserManager from "managers/FireBaseUserManager";
import StockBuilder from 'builders/StockBuilder';
import AnticipatedBuilder from 'builders/AnticipatedBuilder';
import EtfBuilder from 'builders/EtfBuilder';
import BrokerBuilder from 'builders/BrokerBuilder';
import QuoteBuilder from 'builders/QuoteBuilder';

const TAG = "Ipo-Tracker: FireBaseManager ";
const DEBUG = false;
const ALLOW_CRAWLER_ACCOUNT = true;

var firebaseConfig = {
    apiKey: "AIzaSyCKiNz6DOazpbKtRyEWICU3gkOatQePgXs",
    authDomain: "ipo-tracker-30979.firebaseapp.com",
    databaseURL: "https://ipo-tracker-30979.firebaseio.com",
    projectId: "ipo-tracker-30979",
    storageBucket: "ipo-tracker-30979.appspot.com",
    messagingSenderId: "1019985359495",
    appId: "1:1019985359495:web:33a26ba796b77b13b87e22",
    measurementId: "G-HXQYN7KR97"
};

function FireBaseManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.isExceeded = false;
}

FireBaseManager.prototype.isQuotaExceeded = function () {
    return this.isExceeded;
}

FireBaseManager.prototype.initialize = async function () {
    if (DEBUG) console.log(TAG + "Initialize firebase");
    await firebase.initializeApp(firebaseConfig);
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings = {
        minimumFetchIntervalMillis: 23*60*60*1000,
    };
    remoteConfig.defaultConfig = ({
        'live_tv_url': '',
        'cors_proxy_url': 'https://cors-anywhere.herokuapp.com/'
    });
    await remoteConfig.ensureInitialized();
    await remoteConfig.fetchAndActivate()
        .then(() => {
            if (DEBUG) console.log(TAG + "Remote Config is ready");
        })
        .catch((err) => {
            if (DEBUG) console.log(TAG + "Remote Config error " + err);
        });

    return new Promise(async (resolve, reject) => {
        if (firebase.auth().currentUser) {
            if (DEBUG) console.log(TAG + "Initialize firebase: user already connected ");
            resolve(true);
        } else {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    if (DEBUG) console.log(TAG + "Initialize firebase: user connected " + user.displayName);
                    resolve(true);
                } else {
                    if (DEBUG) console.log(TAG + "Initialize firebase: user disconnected");
                    let fireBaseUserManager = new FireBaseUserManager();
                    if (ALLOW_CRAWLER_ACCOUNT && fireBaseUserManager.checkIfAllowedCrawler()) {
                        if (DEBUG) console.log(TAG + "Try login crawler");
                        await fireBaseUserManager.loginCrawlerAccount();
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            });
        }
    });
}

FireBaseManager.prototype.getStocksByDealId = async function (dealId_) {
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return null;
    }
    if (!dealId_) {
        if (DEBUG) console.log(TAG + "getStocksByDealId Unknown deal id");
        return null;
    }
    let stockList = [];
    await this.getStocksByDealIdAndStatus("priced", dealId_).then(async (foundStock) => {
        if (foundStock) {
            stockList.push(foundStock);
        }
    });

    await this.getStocksByDealIdAndStatus("expected", dealId_).then(async (foundStock) => {
        if (foundStock) {
            stockList.push(foundStock);
        }
    });

    await this.getStocksByDealIdAndStatus("filed", dealId_).then(async (foundStock) => {
        if (foundStock) {
            if (DEBUG) console.log(TAG + "getStocksByDealId found filed");
            stockList.push(foundStock);
        }
    });
    await this.getStocksByDealIdAndStatus("withdrawn", dealId_).then(async (foundStock) => {
        if (foundStock) {
            stockList.push(foundStock);
        }
    });
    let sortedStockList = await this.sortStocksByDate(stockList, true);
    return sortedStockList;
}

FireBaseManager.prototype.getStocksByName = async function (name_) {
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return null;
    }
    if (!name_) {
        if (DEBUG) console.log(TAG + "getStocksByName Unknown name");
        return null;
    }
    let stockList = [];
    await this.getStocksByNameAndStatus("priced", name_).then(async (foundStock) => {
        if (foundStock) {
            stockList.push(foundStock);
        }
    });

    await this.getStocksByNameAndStatus("expected", name_).then(async (foundStock) => {
        if (foundStock) {
            stockList.push(foundStock);
        }
    });

    await this.getStocksByNameAndStatus("filed", name_).then(async (foundStock) => {
        if (foundStock) {
            stockList.push(foundStock);
        }
    });
    await this.getStocksByNameAndStatus("withdrawn", name_).then(async (foundStock) => {
        if (foundStock) {
            stockList.push(foundStock);
        }
    });
    let sortedStockList = await this.sortStocksByDate(stockList, true);
    return sortedStockList;
}

FireBaseManager.prototype.getStocksByDealIdAndStatus = async function (status_, dealId_) {
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    const db = firebase.firestore();
    try {
        let stocksArray = [];
        const stockList = await db.collection('stocks/' + status_ + '/all').where('dealId', '==', dealId_).get();
        await stockList.forEach((doc) => {
            stocksArray.push(parseStockFromDocument(doc));
        });
        return stocksArray ? stocksArray[0] : null;
    } catch (e) {
        console.error(TAG + "error: " + e);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getStocksByNameAndStatus = async function (status_, name_) {
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    const db = firebase.firestore();
    try {
        let stocksArray = [];
        const stockList = await db.collection('stocks/' + status_ + '/all').where('name', '==', name_).get();
        await stockList.forEach((doc) => {
            if (DEBUG) console.log(TAG + "Found stock Name: " + doc.get("name"));
            stocksArray.push(parseStockFromDocument(doc));
        });
        return stocksArray ? stocksArray[0] : null;
    } catch (e) {
        console.error(TAG + "error: " + e);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getStocksByStatusAndDate = async function (status_, startDate_, endDate_) {
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    const db = firebase.firestore();
    try {
        let stocksArray = [];
        const stockList = await db.collection('stocks/' + status_ + '/all')
            .where('date', '>=', startDate_)
            .where('date', '<=', endDate_)
            .get();
        await stockList.forEach((doc) => {
            stocksArray.push(parseStockFromDocument(doc));
        });
        let filteredStocksArray = removeDuplicatedStocks(stocksArray);
        return filteredStocksArray;
    } catch (e) {
        console.error(TAG + "error: " + e);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getQuoteBySymbol = async function (symbol_) {
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    if (!firebase.auth().currentUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return null;
    }
    const db = firebase.firestore();
    try {
        const doc = await db.collection('stocks/quotes/all').doc(symbol_).get();
        if (doc && doc.exists) {
            let foundQuote = new QuoteBuilder(
                doc.get("name"),
                symbol_,
                doc.get("quotes"),
                doc.get("quotesvariation"));
            return foundQuote;
        } else {
            return null;
        }
    } catch (e) {
        console.error(TAG + "error: " + e);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getTopPerformers = async function (subCategory_) {
    if (DEBUG) console.log(TAG + "getTopPerformers called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    if (!firebase.auth().currentUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return null;
    }
    const db = firebase.firestore();
    try {
        let stocksArray = [];
        const stockList = await db.collection('performers/' + subCategory_ + '/all').get();
        await stockList.forEach((doc) => {
            const foundStock_ = new StockBuilder();
            foundStock_.CreatePerformer(doc.id, doc.get("name"), doc.get("symbol"), doc.get("date"), doc.get("status"), doc.get("logoUrl"), doc.get("lastPrice"), doc.get("lastVariation"));
            stocksArray.push(foundStock_);
        });
        return stocksArray;
    } catch (e) {
        console.error(TAG + "error: " + e);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getTrending = async function () {
    if (DEBUG) console.log(TAG + "getTrending called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }

    const db = firebase.firestore();
    try {
        let stocksArray = [];
        const stockList = await db.collection('trending/2020/all')
            .orderBy('usersCount', 'desc')
            .limit(10)
            .get();
        await stockList.forEach((doc) => {
            const foundStock_ = new StockBuilder();
            foundStock_.CreateTrending(doc.get("name"), doc.get("status"), doc.get("dealId"));
            stocksArray.push(foundStock_);
        });
        return stocksArray;
    } catch (e) {
        console.error(TAG + "error: " + e.code);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getAnticipated = async function () {
    if (DEBUG) console.log(TAG + "getAnticipated called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    if (!firebase.auth().currentUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return null;
    }
    const db = firebase.firestore();
    try {
        let anticipatedArray = [];
        const anticipatedList = await db.collection('pinned/2020/all')
            .orderBy('importance')
            .get();
        await anticipatedList.forEach((doc) => {
            const foundAnticipated_ = new AnticipatedBuilder(
                doc.get("name"),
                doc.get("status"),
                doc.get("description"),
                doc.get("logo"),
                doc.get("logoDownloadUrl"),
                doc.get("url"),
                doc.get("dealId")
            );
            anticipatedArray.push(foundAnticipated_);
        });
        return anticipatedArray;
    } catch (e) {
        console.error(TAG + "error: " + e);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getEtfs = async function () {
    if (DEBUG) console.log(TAG + "getEtfs called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    if (!firebase.auth().currentUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return null;
    }
    const db = firebase.firestore();
    try {
        let etfsArray = [];
        const etfList = await db.collection('etfs/2020/all')
            .orderBy('importance')
            .get();
        await etfList.forEach((doc) => {
            const foundEtf_ = new EtfBuilder(
                doc.get("name"),
                doc.get("symbol"),
                doc.get("insight"),
                doc.get("description"),
                doc.get("sectors"),
                doc.get("countries"),
                doc.get("holdings"),
                doc.get("lastUpdate"),
                doc.get("daysCount"),
                doc.get("quotes")
            );
            etfsArray.push(foundEtf_);
        });
        return etfsArray;
    } catch (e) {
        console.error(TAG + "error: " + e);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getBrokers = async function () {
    if (DEBUG) console.log(TAG + "getBrokers called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    if (!firebase.auth().currentUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return null;
    }
    const db = firebase.firestore();
    try {
        let brokersArray = [];
        const brokersList = await db.collection('brokers/2020/all')
            .orderBy('importance')
            .get();
        await brokersList.forEach((doc) => {
            const foundBroker_ = new BrokerBuilder(
                doc.get("name"),
                doc.get("description"),
                doc.get("eligibility"),
                doc.get("logo"),
                doc.get("url"),
                doc.get("reviewLink")
            );
            foundBroker_.setReview(
                doc.get("rating_overall"),
                doc.get("rating_Education"),
                doc.get("rating_commissions_fees"),
                doc.get("rating_customer_service"),
                doc.get("rating_ease_use"),
                doc.get("rating_mobile_trading"),
                doc.get("rating_offering_investments"),
                doc.get("rating_platforms_tools"),
                doc.get("rating_research")
            );

            brokersArray.push(foundBroker_);
        });
        return brokersArray;
    } catch (e) {
        console.error(TAG + "error: " + e);
        if (e.code.includes("exhausted")) this.isExceeded = true;
        return null;
    }
}

FireBaseManager.prototype.getDownloadUrl = async function (storageUrl_) {
    if (!storageUrl_ || storageUrl_.length < 1) return null;
    return firebase.storage().refFromURL(storageUrl_).getDownloadURL()
        .then(async (logoUrl_) => {
            return logoUrl_;
        }).catch(e => {
            return null;
        });
}

FireBaseManager.prototype.getNewsDownloadUrl = async function (newsStorageUrl_) {
    if (!newsStorageUrl_ || newsStorageUrl_.length < 1) return null;
    return firebase.storage().ref(newsStorageUrl_).getDownloadURL()
        .then(async (newsFeedDownloadUrl_) => {
            if (DEBUG) console.log(TAG + "getNewsDownloadUrl found url: " + newsFeedDownloadUrl_);
            return newsFeedDownloadUrl_;
        }).catch(e => {
            if (DEBUG) console.log(TAG + "getNewsDownloadUrl error: " + e);
            return null;
        });
}

FireBaseManager.prototype.sortStocksByDate = async function (stocksList_ , descending_) {
    if (!stocksList_) return null;
    try {
        let sortedArray = stocksList_.sort(function compareStocksDate(stock1_, stock2_) {
            if (stock1_.isUnknown(stock1_.getDate()) || stock2_.isUnknown(stock2_.getDate())) return 0;
            let s1 = stock1_;
            let s2 = stock2_;
            if (descending_) {
                s1 = stock2_;
                s2 = stock1_;
            }
            
            var d1 = Date.parse(s1.getDate());
            var d2 = Date.parse(s2.getDate());
            if (d1 < d2) {
                return -1;
            } else {
                return 1;
            }
        });
        return sortedArray;
    } catch (err) {
        if (DEBUG) console.error(TAG + "sortStocksByDate Error: " + err);
        return stocksList_;
    }
}

FireBaseManager.prototype.updateStockSearchLinks = async function (stock_ , newLinksArray_) {
    if (DEBUG) console.log(TAG + "updating stock search links");
    if (!stock_) return;
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    if (!firebase.auth().currentUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return null;
    }
    if (DEBUG) console.log(TAG + "saving new links: " + JSON.stringify(newLinksArray_));

    const db = firebase.firestore();    
    const docRef = db.collection('stocks/' + stock_.getStatus() + '/all').doc(stock_.getName().replace(/\//g, " "));
    try {
        await docRef.update(newLinksArray_).then((res) => {
            if (DEBUG) console.log(TAG + "Search links updated to : stocks/" + stock_.getStatus() + "/all/" + stock_.getName().replace(/\//g, " "));
            if (DEBUG) console.log(TAG + "Result : " + JSON.stringify(res));
        }).catch(e => {
            console.error(TAG + "Catched exeption: " + e);
        });
    } catch (e) {
        console.error(TAG + "Catched exeption: " + e);
    }
};

function removeDuplicatedStocks(stocksList_) {
    if (DEBUG) console.log(TAG + "removeDuplicatedStocks called");
    if (!stocksList_) return null;
    try {
        let stocksHashMap = {};
        let filteredArray = [];

        for (let i = 0; i < stocksList_.length; i++) {
            let stock_ = stocksList_[i];
            if (!stocksHashMap[stock_.getDealId()]) {
                stocksHashMap[stock_.getDealId()] = true;
                filteredArray.push(stock_);
            } else {
                if (DEBUG) console.log(TAG + "removeDuplicatedStocks found duplicate " + stock_.getName());
            }
        }
        return filteredArray;
    } catch (err) {
         if (DEBUG) console.log(TAG + "removeDuplicatedStocks catched exception " + err);
        return stocksList_;
    }
}

function parseStockFromDocument(doc_) {
    let foundStock_ = new StockBuilder(doc_.get("name"),
        doc_.get("symbol"),
        doc_.get("date"),
        doc_.get("exchange"),
        doc_.get("status"),
        doc_.get("price"),
        doc_.get("shares"),
        doc_.get("value"),
        doc_.get("dealId"));
    foundStock_.setExperts(doc_.get("experts"));
    foundStock_.setDescription(doc_.get("description"));
    foundStock_.setAddress(doc_.get("address"));
    foundStock_.setCountry(doc_.get("country"));
    foundStock_.setCity(doc_.get("city"));
    foundStock_.setState(doc_.get("state"));
    foundStock_.setLogo(doc_.get("logoUrl"));
    foundStock_.setWebsite(doc_.get("website"));
    foundStock_.setIndustry(doc_.get("industry"));
    foundStock_.setSector(doc_.get("sector"));
    foundStock_.setOverwritePrice(doc_.get("overwritePrice"));
    foundStock_.setSector(doc_.get("sector"));
    foundStock_.setLastPrice(doc_.get("lastPrice"));
    foundStock_.setVariation(doc_.get("lastVariation"));
    foundStock_.setLastUpdate(doc_.get("lastUpdate"));
    foundStock_.setCeo(doc_.get("ceo"));
    foundStock_.setCik(doc_.get("cik"));
    foundStock_.setEmployee(doc_.get("employee"));
    foundStock_.setFiscalYearEnd(doc_.get("fiscalYearEnd"));
    foundStock_.setLockupExpiration(doc_.get("lockupExpiration"));
    foundStock_.setLockupPeriod(doc_.get("lockupPeriod"));
    foundStock_.setNetIncome(doc_.get("netIncome"));
    foundStock_.setPhone(doc_.get("phone"));
    foundStock_.setQuietPeriodExpiration(doc_.get("quietPeriodExpiration"));
    foundStock_.setRevenue(doc_.get("revenue"));
    foundStock_.setShareOverAlloted(doc_.get("shareOverAlloted"));
    foundStock_.setShareholdersSharesOffered(doc_.get("shareholdersSharesOffered"));
    foundStock_.setSharesOutstanding(doc_.get("sharesOutstanding"));
    foundStock_.setStateOfInc(doc_.get("stateOfInc"));
    foundStock_.setStockholdersEquity(doc_.get("stockholdersEquity"));
    foundStock_.setTotalAssets(doc_.get("totalAssets"));
    foundStock_.setTotalExpense(doc_.get("totalExpense"));
    foundStock_.setTotalLiabilities(doc_.get("totalLiabilities"));
    foundStock_.setInvestingLink(doc_.get("investingLink"));
    foundStock_.setRobinhoodLink(doc_.get("robinhoodLink"));
    foundStock_.setWebullLink(doc_.get("webullLink"));
    foundStock_.setEtoroLink(doc_.get("etoroLink"));
    foundStock_.setAmeritradeLink(doc_.get("ameritradeLink"));
    foundStock_.setFidelityLink(doc_.get("fidelityLink"));
    return foundStock_;
}

export default FireBaseManager;