import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui icons
import LoyaltyIcon from '@material-ui/icons/Loyalty';

// Core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

//Global variables
const TAG = "Ipo-Tracker: AdsenseView ";
const DEBUG = false;
const googleAdId = 'ca-pub-9325629159532545';
const HomeAdTop = '8816841368';
const HomeAdMiddle = '4853092030';
const HomeAdBottom = '6392491021';
const NewsFeedAd = '4853092030';

class AdsenseView extends Component {
    googleInit = null;

    componentDidMount() {
        const { timeout } = this.props;
        if (DEBUG) console.log(TAG + "componentDidMount timeout: " + timeout);
        this.googleInit = setTimeout(() => {
            if (DEBUG) console.log(TAG + "componentDidMount timeout ended");
            if (typeof window !== 'undefined')
                (window.adsbygoogle = window.adsbygoogle || []).push({});
        }, timeout);
    }

    componentWillUnmount() {
        if (this.googleInit) clearTimeout(this.googleInit);
    }

    render() {
        const { slotType } = this.props;

        function getSlotFromType(slotType_) {
            switch (slotType_) {
                case "homeTop":
                    return HomeAdTop;
                case "homeMiddle":
                    return HomeAdMiddle;
                case "homeBottom":
                    return HomeAdBottom;
                case "newsFeed":
                    return NewsFeedAd;
                default:
                    return HomeAdBottom;
            }
        }

        let TopPadding = null;
        if (slotType === "homeTop") {
            TopPadding = (<div style={{ paddingTop: "5px"}}/>);
        }

        return (
            <div>
                {TopPadding}
                <Card>
                    <CardHeader color="primary" text>
                        <CardText color="primary">
                            <LoyaltyIcon />
                        </CardText>
                    </CardHeader>
                    <CardBody>
                        <ins
                            className="adsbygoogle"
                            style={{ display: 'block', textAlign: 'center'/*, backgroundColor: 'black'*/ }}
                            data-ad-client={googleAdId}
                            data-ad-slot={getSlotFromType(slotType)}
                            data-ad-layout="in-article"
                            data-ad-format="fluid"

                        ></ins>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

AdsenseView.propTypes = {
    slotType: PropTypes.string,
    timeout: PropTypes.number,
};

AdsenseView.defaultProps = {
    slotType: "homeBottom",
    timeout: 200,
};

export default AdsenseView;