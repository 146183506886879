import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

//Global variables
//const TAG = "Ipo-Tracker: IpoInfoCard ";
//const DEBUG = false;
const InfoTypes = ["ipoDetails", "experts", "buisness", "contact", "financial"];
const useStyles = makeStyles(styles);

export default function IpoInfo(props) {
    const {
        stockBuilder,
        type
    } = props;

    const classes = useStyles();
    const ipoInfoCardHeight = "150px";
    const infoCardHeight = "185px";
    const [expertsListView, setexpertsListView] = React.useState(<p> Not available</p>);

    function getWebsiteUrl(stockBuilder_) {
        if (stockBuilder_.isUnknown(stockBuilder_.getWebsite())) return null;
        let websiteUrl_ = stockBuilder_.getWebsite();
        if (!stockBuilder_.getWebsite().includes("www")) {
            websiteUrl_ = "www." + websiteUrl_;
        }
        if (!stockBuilder_.getWebsite().includes("http")) {
            websiteUrl_ = "https://" + websiteUrl_;
        }
        return websiteUrl_;
    }

    function getColorFromType(type_) {
        switch (type_) {
            case InfoTypes[0]: return "primary";
            case InfoTypes[1]: return "primary";
            case InfoTypes[2]: return "rose";
            case InfoTypes[3]: return "rose";
            case InfoTypes[4]: return "rose";
            default:
                return "Unknown";
        }
    }

    function getTitleFromType(type_) {
        switch (type_) {
            case InfoTypes[0]: return "IPO Details";
            case InfoTypes[1]: return "IPO Experts";
            case InfoTypes[2]: return "Buisness Info";
            case InfoTypes[3]: return "Contact Info";
            case InfoTypes[4]: return "Financial Info";
            default:
                return "Unknown";
        }
    }

    function getBodyFromType(type_) {
        switch (type_) {
            case InfoTypes[0]: return createIpoDetails();
            case InfoTypes[1]: return createIpoExperts();
            case InfoTypes[2]: return createBuisnessInfo();
            case InfoTypes[3]: return createContactInfo();
            case InfoTypes[4]: return createFinancialInfo();
            default:
                return "Unknown";
        }
    }

    function createIpoDetails() {
        return (
            <GridContainer xs={12} style={{ marginLeft: "auto", marginRight: "auto", overflow: "auto", minHeight: ipoInfoCardHeight, maxHeight: ipoInfoCardHeight }}>
                <GridItem>
                    <table >
                        <tr>
                            <td className={classes.stats} ><b>Proposed symbol</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getSymbol()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Filed name</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getName()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Exchange</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getExchange()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Share price</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getPrice()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Shares offered</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getShares()}</td>
                        </tr>
                    </table >
                </GridItem>
                <GridItem>
                    <table >
                        <tr>
                            <td className={classes.stats} ><b>Offer amount</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getValue()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Shares over alloted</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getShareOverAlloted()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Shareholder shares offered</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getShareholdersSharesOffered()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Shares outstanding</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getSharesOutstanding()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>CIK</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getCik()}</td>
                        </tr>
                    </table>
                </GridItem>
                <GridItem>
                    <table >
                        <tr>
                            <td className={classes.stats} ><b>Lockup period (days)</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getLockupPeriod()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Lockup expiration</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getLockupExpiration()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Quiet period expiration</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getQuietPeriodExpiration()}</td>
                        </tr>
                    </table>
                </GridItem>
                <br />
            </GridContainer>
            );
    }

    function createIpoExperts() {
        return (
            <GridContainer xs={12} style={{ marginLeft: "auto", marginRight: "auto", overflow: "auto", minHeight: ipoInfoCardHeight, maxHeight: ipoInfoCardHeight }}>
                {expertsListView}
            </GridContainer>
            );
    }

    function createBuisnessInfo() {
        return (
            <GridContainer xs={12} style={{ marginLeft: "auto", marginRight: "auto", overflow: "auto", minHeight: infoCardHeight, maxHeight: infoCardHeight }}>
                <GridItem>
                    <table >
                        <tr>
                            <td className={classes.stats} ><b>Sector</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getSector()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Industry</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getIndustry()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Country</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getCountry()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>City</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getCity()}</td>
                        </tr>
                   
                        <tr>
                            <td className={classes.stats} ><b>State of Inc</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getStateOfInc()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Employees</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getEmployee()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>CEO</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getCeo()}</td>
                        </tr>
                    </table >
                </GridItem>
            </GridContainer>
            );
    }

    function createContactInfo() {
        return (
            <GridContainer xs={12} style={{ marginLeft: "auto", marginRight: "auto", overflow: "auto", minHeight: infoCardHeight, maxHeight: infoCardHeight }}>
                <table >
                    <tr>
                        <td className={classes.stats} ><b>Website</b></td>
                        <td>:&nbsp;&nbsp;
                            <a href={getWebsiteUrl(stockBuilder)} target="_blank" rel="noopener noreferrer">
                                {stockBuilder.getWebsite()}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.stats} ><b>Phone</b></td>
                        <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getPhone()}</td>
                    </tr>
                    <tr>
                        <td className={classes.stats} ><b>Address</b></td>
                        <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getAddress()}</td>
                    </tr>
                </table >
            </GridContainer>
            );
    }

    function createFinancialInfo() {
        return (
            <GridContainer xs={12} style={{ marginLeft: "auto", marginRight: "auto", overflow: "auto", minHeight: infoCardHeight , maxHeight: infoCardHeight }}>
                <GridItem>
                    <table >
                        <tr>
                            <td className={classes.stats} ><b>Revenue</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getRevenue()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Net Income</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getNetIncome()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Total Assets</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getTotalAssets()}</td>
                        </tr>
                    
                        <tr>
                            <td className={classes.stats} ><b>Stockholders' Equity</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getStockholdersEquity()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Total Expenses</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getTotalExpense()}</td>
                        </tr>
                        <tr>
                            <td className={classes.stats} ><b>Fiscal Year End</b></td>
                            <td className={classes.cardTitle}>:&nbsp;&nbsp;{stockBuilder.getFiscalYearEnd()}</td>
                        </tr>
                    </table >
                </GridItem>
            </GridContainer>
            );
    }

    React.useEffect(() => {

        async function updateExpertsView() {
            let expertsList_ = stockBuilder.getExperts();
            if (!expertsList_ || expertsList_.length < 1) return;
            let expertsData = JSON.parse(expertsList_);
            let expertsListView_ = []
            for (var expert in expertsData) {
                expertsListView_.push(<p className={classes.stats} > <b>{expert}</b> ({expertsData[expert]}) , &nbsp;</p>);
            }
            setexpertsListView(expertsListView_);
        }

        if (!stockBuilder) return;
        if (type === InfoTypes[1]) {
            updateExpertsView();
        }
    }, [stockBuilder, type, classes])

    if (!stockBuilder || !type) return (<div />);
    return (
            <Card>
            <CardHeader color={getColorFromType(type)} text>
                <CardText color={getColorFromType(type)}>
                    <h4 className={classes.cardTitleWhite}>{getTitleFromType(type)}</h4>
                    </CardText>
                </CardHeader>
                <CardBody>
                    {getBodyFromType(type)}
                </CardBody>
            </Card>
    );
}

IpoInfo.defaultProps = {
    stockBuilder: null,
    type: null
};

IpoInfo.propTypes = {
    type: PropTypes.oneOf(InfoTypes)
};