const DEFAULT = "--";

function EtfBuilder(name_, symbol_, insight_, description_, sectors_, countries_, holdings_, lastUpdate_ , daysCount_, quotes_) {
    this.name = name_;
    this.symbol = symbol_;
    this.insight = insight_;
    this.description = description_;
    this.sectors = sectors_;
    this.countries = countries_;
    this.holdings = holdings_;
    this.lastUpdate = lastUpdate_;
    this.daysCount = daysCount_;
    this.quotes = quotes_;
}

EtfBuilder.prototype.isUnknown = function () {
    return ((!this.name) || (this.name === DEFAULT));
};

EtfBuilder.prototype.getName = function () {
    return this.name ? this.name : DEFAULT;
};

EtfBuilder.prototype.getSymbol = function () {
    return this.symbol ? this.symbol : DEFAULT;
};

EtfBuilder.prototype.getInsight = function () {
    return this.insight ? this.insight : DEFAULT;
};

EtfBuilder.prototype.getDescription = function () {
    return this.description ? this.description : DEFAULT;
};

EtfBuilder.prototype.getSectors = function () {
    return this.sectors ? this.sectors : null;
};

EtfBuilder.prototype.getCountries = function () {
    return this.countries ? this.countries : null;
};

EtfBuilder.prototype.getHoldings = function () {
    return this.holdings ? this.holdings : null;
};

EtfBuilder.prototype.getLastUpdate = function () {
    return this.lastUpdate ? this.lastUpdate : DEFAULT;
};

EtfBuilder.prototype.getDaysCount = function () {
    return this.daysCount ? this.daysCount : DEFAULT;
};

EtfBuilder.prototype.getQuotes = function () {
    return this.quotes ? this.quotes : null;
};

EtfBuilder.prototype.setName = function (new_) {
    this.name = new_;
};

EtfBuilder.prototype.setSymbol = function (new_) {
    this.symbol = new_;
};

EtfBuilder.prototype.setInsight = function (new_) {
    this.insight = new_;
};

EtfBuilder.prototype.setDescription = function (new_) {
    this.description = new_;
};

EtfBuilder.prototype.setSectors = function (new_) {
    this.sectors = new_;
};

EtfBuilder.prototype.setCountries = function (new_) {
    this.countries = new_;
};

EtfBuilder.prototype.setHoldings = function (new_) {
    this.holdings = new_;
};

EtfBuilder.prototype.setLastUpdate = function (new_) {
    this.lastUpdate = new_;
};

EtfBuilder.prototype.setDaysCount = function (new_) {
    this.daysCount = new_;
};

EtfBuilder.prototype.setQuotes = function (new_) {
    this.quotes = new_;
};

module.exports = EtfBuilder;