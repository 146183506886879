import React from "react";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import NavPills from "components/NavPills/NavPills.js";
import CardAvatar from "components/Card/CardAvatar.js";

//Custom stuff
import UserManager from "managers/UserManager";
import AnticipatedManager from "managers/AnticipatedManager";
import StockLogo from "utilities/StockLogo";
import NewsView from "utilities/NewsViewComponent";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: TopPerformersView ";
const DEBUG = false;
const anticipatedManager = new AnticipatedManager();
const userManager = new UserManager();
const showLoadingBar = <LinearProgress />;
const emptyContent = <div></div>;
const styles = {
    cardTitle
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}

function openStockTab(history_, stockDealId_) {
    if (DEBUG) console.log(TAG + "openStockTab called with dealId " + stockDealId_);
    history_.push("/u/ipo-info?id=" + stockDealId_);
}

export default function RegularTables() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();

    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [anticipatedView, setAnticipatedView] = React.useState(emptyContent);
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    React.useEffect(() => {

        function createAnticipatedTab(anticipatedStock_) {
            return (anticipatedStock_ ?
                {
                    tabButton: anticipatedStock_.getName(),
                    tabContent: (
                        <div>
                            <br />
                            <br />
                            <Card profile>
                                <CardAvatar profile>
                                    <StockLogo stock={anticipatedStock_} padding="10px" width="400px" height="800px" />
                                </CardAvatar>
                                <CardBody profile>
                                    <h4 className={classes.cardTitle}>{anticipatedStock_.getStatus()}</h4>
                                    <p className={classes.description} style={{ whiteSpace: 'pre-wrap' }}>{anticipatedStock_.getDescription()}</p>
                                    <a href={"#link" + anticipatedStock_.getName()} onClick={e => {
                                        e.preventDefault();
                                        openInNewTab(anticipatedStock_.getUrl());
                                    }}>
                                        <h4 className={classes.cardTitle} ><span style={{ color: "rose" }}> {anticipatedStock_.getUrl()} </span></h4>
                                    </a>
                                    {anticipatedStock_.getDealId() && <Button color="rose" round onClick={e => {
                                        e.preventDefault();
                                        openStockTab(history,anticipatedStock_.getDealId());
                                    }}> Find More </Button>}
                                </CardBody>
                            </Card>
                            <br />
                            <NewsView searchName={anticipatedStock_.getName()} maxItems={20}/>
                        </div>
                    )
                }
                : {});
        }

        function updateAnticipatedView(anticipatedList_) {
            let anticipatedView_ = emptyContent;
            if (anticipatedList_ && anticipatedList_.length >= 1) {
                let anticipatedTabs_ = [];
                for (let i = 0; i < anticipatedList_.length; i++) {
                    let anticipatedItem_ = anticipatedList_[i];
                    anticipatedTabs_.push(createAnticipatedTab(anticipatedItem_));
                }
                anticipatedView_ = (
                    <GridItem xs={12}>
                        <NavPills
                            color="success"
                            alignCenter
                            tabs={anticipatedTabs_}
                        />
                    </GridItem>
                );

            }
            setAnticipatedView(anticipatedView_);
        }

        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            setLoadingBar(showLoadingBar);
            let newAnticipatedData = await anticipatedManager.getAnticipated();
            if (newAnticipatedData && !newAnticipatedData.length < 1) {
                await updateAnticipatedView(newAnticipatedData);
            } else {
                await updateAnticipatedView(null);
            }
            setLoadingBar();
        }

        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            } else {
                if (userManager.isConnected()) {
                    fetchData();
                }
            }
        });
    }, [classes, history]);

    if (adBlockDetected) {
        return (<SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="An Blocker is detected !"
            onConfirm={() => window.location.reload()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="I have disabled AdBlock"
        >
            This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
        </SweetAlert>);
    } else if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        const login = () => {
            history.push("/auth/login-page");
        };
        const goBack = () => {
            history.goBack();
        };

        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <div>
                <MetaTags>
                    <title>Top anticipated</title>
                    <meta name="description" content="Discover the hottest and most anticipated initial public offerings, rumors and breaking news. 
Keep track when companies like SpaceX , Robinhood , Rivian, Epic Games, Open Ai, Starlink and Coinbase will go public." />
                    <meta property="og:title" content="Top anticipated IPO" />
                </MetaTags>
                <AdsenseView slotType="homeTop" />
                <GridContainer justify="center">
                    {loadingBar}
                    {anticipatedView}
                </GridContainer>
                <AdsenseView slotType="homeBottom" />
            </div>
        );
        return userConnectedLayout;
    }
}
