import FireBaseUserManager from "managers/FireBaseUserManager";

const TAG = "Ipo-Tracker: UserManager ";
const DEBUG = false;

function UserManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.fireBaseUserManager = new FireBaseUserManager();
    this.lastAuthenticationState = null;
}

UserManager.prototype.isConnected = function () {
    if (DEBUG) console.log(TAG + "isConnected called");
    if (!this.fireBaseUserManager.getUserInfo()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return false;
    }
    return true;
}

UserManager.prototype.getFirebaseUser = function () {
    return this.fireBaseUserManager.getUserInfo();
}

UserManager.prototype.getUserInfo = async function () {
    if (DEBUG) console.log(TAG + "getUserInfo called");
    checkAuthenticationChanged(this);
    if (!this.fireBaseUserManager.getUserInfo()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.userInfo) {
        await this.fireBaseUserManager.getUserExtraInfo().then(async (userExtraInfo) => {
            if (!userExtraInfo) {
                console.log(TAG + "Unable to get user Extra info");
                return null;
            }
            this.userInfo = userExtraInfo;
        }).catch(e => {
            console.error(TAG + "getUserInfo Catched exeption: " + e);
        });
    }
    return this.userInfo;
}

UserManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.userInfo = null;
}

UserManager.prototype.authenticateGoogle = async function () {
    if (this.fireBaseUserManager.checkIfAllowedCrawler()) return "Crawler detected";
    return await this.fireBaseUserManager.authenticateGoogle();
}

UserManager.prototype.authenticateFacebook = async function () {
    if (this.fireBaseUserManager.checkIfAllowedCrawler()) return "Crawler detected";
    return await this.fireBaseUserManager.authenticateFacebook();
}

UserManager.prototype.authenticateTwitter = async function () {
    if (this.fireBaseUserManager.checkIfAllowedCrawler()) return "Crawler detected";
    return await this.fireBaseUserManager.authenticateTwitter();
}

UserManager.prototype.updatePseudo = async function (newPseudo_) {
    if (this.fireBaseUserManager.checkIfAllowedCrawler()) return;
    await this.fireBaseUserManager.updatePseudo(newPseudo_);
    this.clearAllData();
}

UserManager.prototype.signOut = async function () {
    if (this.fireBaseUserManager.checkIfAllowedCrawler()) return;
    await this.fireBaseUserManager.signOut();
    this.clearAllData();
}

UserManager.prototype.deleteUser = async function () {
    if (this.fireBaseUserManager.checkIfAllowedCrawler()) return;
    await this.fireBaseUserManager.deleteUser();
    this.clearAllData();
}

UserManager.prototype.addToWatchlist = async function (stock_) {
    if (this.fireBaseUserManager.checkIfAllowedCrawler()) return;
    if (DEBUG) console.log(TAG + "addToWatchlist called");
    await this.fireBaseUserManager.addToWatchlist(stock_);
}

UserManager.prototype.removeFromWatchlist = async function (stock_) {
    if (this.fireBaseUserManager.checkIfAllowedCrawler()) return;
    if (DEBUG) console.log(TAG + "removeFromWatchlist called");
    await this.fireBaseUserManager.removeFromWatchlist(stock_);
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userInfo) return;
    let currentUserUid = context_.getFirebaseUser().uid;
    let lastUserUid = context_.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}

export default UserManager;