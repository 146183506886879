import React from "react";
import FireBaseManager from "managers/FireBaseManager";
import StockLogo from "utilities/StockLogo";

const TAG = "Ipo-Tracker: CalendarManager ";
const DEBUG = false;
const MAX_ITEMS_COUNT = 15;

function CalendarManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.pricedList = null;
    this.expectedList = null;
    this.pricedTabList = null;
    this.expectedTabList = null;
    this.todayList = null;
}

CalendarManager.prototype.isQuotaExceeded = function () {
    return this.firebaseManager.isQuotaExceeded();
}

CalendarManager.prototype.getLatestList = async function () {
    if (DEBUG) console.log(TAG + "getLatestList called");
    if (!this.pricedTabList) {
        this.pricedTabList = [];
        await this.firebaseManager.getStocksByStatusAndDate(
            "priced",
            getLastMonthDate(),
            getCurrentDate()).then(async (stockList) => {
                if (!stockList || stockList.length < 1) {
                    console.log(TAG + "Unable to get latest stock list");
                    return;
                }

                stockList = await this.firebaseManager.sortStocksByDate(stockList, true);

                this.pricedList = stockList;
                for (let i = 0; i < stockList.length && i < MAX_ITEMS_COUNT; i++) {
                    let stock_ = stockList[i];
                    this.pricedTabList.push(createStockLine(stock_));
                }

            }).catch(e => {
                console.error(TAG + "getLatestList Catched exeption: " + e);
            });
    }
    return this.pricedTabList;
}

CalendarManager.prototype.getUpcomingList = async function () {
    if (DEBUG) console.log(TAG + "getUpcomingList called");
    if (!this.expectedTabList) {
        this.expectedTabList = [];
        await this.firebaseManager.getStocksByStatusAndDate(
            "expected",
            getCurrentDate(),
            getNextMonthDate()).then(async (stockList) => {
                if (!stockList || stockList.length < 1) {
                    console.log(TAG + "Unable to get upcoming stock list");
                    return;
                }
                stockList = await this.firebaseManager.sortStocksByDate(stockList,false);
                this.expectedList = stockList;
                for (let i = 0; i < stockList.length && i < MAX_ITEMS_COUNT; i++) {
                    let stock_ = stockList[i];
                    this.expectedTabList.push(createStockLine(stock_));
                }

            }).catch(e => {
                console.error(TAG + "getUpcomingList Catched exeption: " + e);
            });
    }
    return this.expectedTabList;
}

CalendarManager.prototype.getTodayList = async function () {
    if (DEBUG) console.log(TAG + "getTodayList called");
    if (!this.todayList) {
        this.todayList = [];
        await this.firebaseManager.getStocksByStatusAndDate(
            "expected",
            getCurrentDate(),
            getCurrentDate()).then(async (stockList) => {
                if (!stockList || stockList.length < 1) {
                    if (DEBUG) console.log(TAG + "Unable to get today stock list");
                    return;
                }
                stockList = await this.firebaseManager.sortStocksByDate(stockList, false);
                this.todayList = stockList;
            }).catch(e => {
                console.error(TAG + "getTodayList Catched exeption: " + e);
            });
    }
    return this.todayList;
}

CalendarManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.pricedTabList = null;
    this.expectedTabList = null;
    this.todayList = null;
}

function createStockLine(stock_) {
    if (!stock_) return [];
    let variationText = stock_.isUnknown(stock_.getLastPrice()) ? "Ipo price" : stock_.getVariation();
    let textColor = "";
    if (variationText.includes("-")) {
        textColor = "red";
    } else if (variationText.includes("+")) {
        textColor = "green";
    }
    let variationLine = (<span style={{ color: textColor }}> {variationText} </span>);

    return [
        <StockLogo stock={stock_} round={false} width="40" height="20" minWidth="20px" minHeight="20px" />,
        <p> <a href={getStockInfoLink(stock_)} style={{color:"black"}}> {stock_.getSymbol()} </a> </p>,
        <p> <a href={getStockInfoLink(stock_)} style={{color: "black"}}> {stock_.getName()} </a> </p>,
        stock_.isUnknown(stock_.getLastPrice()) ? stock_.getPrice() : stock_.getLastPrice(),
        stock_.isUnknown(stock_.getPrice()) ? "--" : variationLine,
        stock_.getDate()
    ];
}

function getStockInfoLink(stockBuilder_) {
    if (!stockBuilder_ || stockBuilder_.isUnknown(stockBuilder_.getDealId())) return;
    return "/u/ipo-info?id=" + stockBuilder_.getDealId();
}

function getLastMonthDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    //return Last month
    mm = parseInt(mm) - 1;
    if (mm < 1) {
        mm = mm + 12;
        yyyy = yyyy - 1;
    }
    mm = String(mm).padStart(2, '0');

    var startDate_ = yyyy + '-' + mm + '-' + dd;
    if (DEBUG) console.log(TAG + "getLastMonthDate: " + startDate_);
    return startDate_;
}

function getNextMonthDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    //return Next month
    mm = parseInt(mm) + 1;
    if (mm > 12) {
        mm = mm - 12;
        yyyy = yyyy + 1;
    }
    mm = String(mm).padStart(2, '0');

    var endDate_ = yyyy + '-' + mm + '-' + dd;
    if (DEBUG) console.log(TAG + "getNextMonthDate: " + endDate_);
    return endDate_;
}

function getCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    var currentDate_ = yyyy + '-' + mm + '-' + dd;
    if (DEBUG) console.log(TAG + "getCurrentDate: " + currentDate_);
    return currentDate_;
}

export default CalendarManager;