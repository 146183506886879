import firebase from 'firebase/app';
import "firebase/remote-config";

const TAG = "Ipo-Tracker: RemoteConfigManager ";
const DEBUG = false;

function RemoteConfigManager() {
    if (DEBUG) console.log(TAG + "Instance created");
}

RemoteConfigManager.prototype.getLiveTvUrl = function () {
    if (DEBUG) console.log(TAG + "getLiveTvUrl called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return null;
    }
    return firebase.remoteConfig().getString('live_tv_url');
}

RemoteConfigManager.prototype.getCorsProxyUrl = function () {
    if (DEBUG) console.log(TAG + "getCorsProxyUrl called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return null;
    }
    return firebase.remoteConfig().getString('cors_proxy_url');
}

export default RemoteConfigManager;