import React from "react";
import FireBaseManager from "managers/FireBaseManager";
import UserManager from "managers/UserManager";
import StockLogo from "utilities/StockLogo";
import StockBuilder from 'builders/StockBuilder';
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";

const TAG = "Ipo-Tracker: WatchlistManager ";
const DEBUG = false;
const KEY_WATCHLIST_CACHE = "ipoTracker_watchlist";

function WatchlistManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.userManager = new UserManager();
    this.lastAuthenticationState = null;
    this.operationInProgress = false;
    this.cachedWatchlist = localStorage.getItem(KEY_WATCHLIST_CACHE);
    if (this.cachedWatchlist) {
        let parsedList = JSON.parse(this.cachedWatchlist);
        if (parsedList) {
            this.watchlistStocks = [];
            for (let i = 0; i < parsedList.length; i++) {
                let foundStock_ = new StockBuilder();
                foundStock_.Deserialize(parsedList[i]);
                this.watchlistStocks.push(foundStock_);
            }
            if (DEBUG) console.log(TAG + "Using Cached WatchList");
        }
    }
}

WatchlistManager.prototype.getWatchlistTabs = async function (refreshCallback_, history_) {
    if (DEBUG) console.log(TAG + "getWatchlistTabs called");
    let watchlistTabs = [];
    await this.getWatchlistStocks().then(async (foundStockList) => {
        if (!foundStockList) {
            console.log(TAG + "Unable to find watchlist ");
            return null;
        }
        for (var stock_ of foundStockList) {
            watchlistTabs.push(createStockLine(stock_, history_, this, refreshCallback_));
        }
    });
    return watchlistTabs;
}

WatchlistManager.prototype.getWatchlistStocks = async function () {
    if (DEBUG) console.log(TAG + "getWatchlistStocks called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    checkIfCacheChanged(this);
    if (!this.watchlistStocks) {
        this.watchlistStocks = [];
        let userInfo = await this.userManager.getUserInfo();
        if (!userInfo || !userInfo.getWatchlist()) {
            console.log(TAG + "Unable to get user watchlist");
            return this.watchlistStocks;
        }
        let watchListNames = userInfo.getWatchlist();
        for (let i = 0; i < watchListNames.length; i++) {
            try {
                let watchlistStockName_ = watchListNames[i];
                if (DEBUG) console.log(TAG + "found stock: " + watchlistStockName_);
                await this.firebaseManager.getStocksByName(watchlistStockName_).then(async (foundStockList) => {
                    if (!foundStockList || !foundStockList[0]) {
                        console.log(TAG + "Unable to find stock " + watchlistStockName_);
                        return null;
                    }
                    this.watchlistStocks.push(foundStockList[0]);
                });
            } catch (error) {
                console.log(TAG + "Unable to find watchlist stock ");
            }
        }
        if (DEBUG) console.log(TAG + "Save watchlist to cache");
        localStorage.setItem(KEY_WATCHLIST_CACHE, JSON.stringify(this.watchlistStocks));
    }
    return this.watchlistStocks;
}

WatchlistManager.prototype.addToWatchlist = async function (stock_) {
    if (DEBUG) console.log(TAG + "addToWatchlist called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    this.operationInProgress = true;
    await this.userManager.addToWatchlist(stock_);

    if (DEBUG) console.log(TAG + "Update watchlist in cache");
    this.watchlistStocks.push(stock_);
    await localStorage.setItem(KEY_WATCHLIST_CACHE, JSON.stringify(this.watchlistStocks));
    this.cachedWatchlist = await localStorage.getItem(KEY_WATCHLIST_CACHE);
    this.operationInProgress = false;
}

WatchlistManager.prototype.removeFromWatchlist = async function (stock_) {
    if (DEBUG) console.log(TAG + "removeFromWatchlist called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    this.operationInProgress = true;
    await this.userManager.removeFromWatchlist(stock_);

    if (DEBUG) console.log(TAG + "Update watchlist in cache");
    if (this.watchlistStocks && this.watchlistStocks.length > 0) {
        for (let i = 0; i < this.watchlistStocks.length; i++) {
            let stockIterator_ = this.watchlistStocks[i];
            if (stockIterator_.getDealId() === stock_.getDealId()) {
                this.watchlistStocks.splice(i, 1);
                await localStorage.setItem(KEY_WATCHLIST_CACHE, JSON.stringify(this.watchlistStocks));
                this.cachedWatchlist = await localStorage.getItem(KEY_WATCHLIST_CACHE);
                break;
            }
        }
    }
    this.operationInProgress = false;
}

WatchlistManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.watchlistStocks = null;
    if (DEBUG) console.log(TAG + "delete watchlist from cache");
    localStorage.removeItem(KEY_WATCHLIST_CACHE);
    this.userManager = new UserManager();
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.userManager.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userManager.userInfo || !context_.userManager.getFirebaseUser()) return;
    let currentUserUid = context_.userManager.getFirebaseUser().uid;
    let lastUserUid = context_.userManager.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}

function createStockLine(stock_, history_, context_, refreshCallback_) {
    if (!stock_) return [];
    let variationText = stock_.isUnknown(stock_.getLastPrice()) ? "Ipo price" : stock_.getVariation();
    let textColor = "";
    if (variationText.includes("-")) {
        textColor = "red";
    } else if (variationText.includes("+")) {
        textColor = "green";
    }
    let variationLine = (<span style={{ color: textColor }}> {variationText} </span>);
    return [
        <StockLogo stock={stock_} round={false} width="40" height="20" minWidth="20px" minHeight="20px" />,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_); }} style={{ color: "black" }}> {stock_.getName()} </a> </p>,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_); }} style={{ color: "black" }}> {stock_.getSymbol()} </a> </p>,
        stock_.isUnknown(stock_.getLastPrice()) ? stock_.getPrice() : stock_.getLastPrice(),
        stock_.isUnknown(stock_.getPrice()) ? "--" : variationLine,
        stock_.getStatus().toUpperCase(),
        stock_.getDate(),
        <Button justIcon simple color="danger">
            <Close style={{ minHeight: "10px", minWidth: "10px", margin: "auto" }}
                onClick={async(e) => {
                    e.preventDefault();
                    if (!context_.operationInProgress) {
                        await context_.removeFromWatchlist(stock_);
                        if (refreshCallback_) refreshCallback_(true);
                    } else {
                        console.log(TAG + "Skip delete, another operation is in progress");
                    }
                }}/>
        </Button>
    ];
}

function navigateToStockInfo(stockBuilder_, history_) {
    if (!stockBuilder_ || !history_) return;
    if (stockBuilder_.isUnknown(stockBuilder_.getDealId())) return;
    history_.push("/u/ipo-info?id=" + stockBuilder_.getDealId());
}

function checkIfCacheChanged(context_) {
    if (DEBUG) console.log("checkIfCacheChanged called ");
    if (!context_ || !context_.cachedWatchlist) {
        if (DEBUG) console.log("checkIfCacheChanged empty cache ");
        return;
    }
    let newCachedlist = localStorage.getItem(KEY_WATCHLIST_CACHE);
    if (context_.cachedWatchlist !== newCachedlist) {
        if (DEBUG) console.log("checkIfCacheChanged cache has changed");
        context_.cachedWatchlist = newCachedlist;
        if (context_.cachedWatchlist) {
            let parsedList = JSON.parse(context_.cachedWatchlist);
            if (parsedList) {
                context_.watchlistStocks = [];
                for (let i = 0; i < parsedList.length; i++) {
                    let foundStock_ = new StockBuilder();
                    foundStock_.Deserialize(parsedList[i]);
                    context_.watchlistStocks.push(foundStock_);
                }
            }
        }
    } else {
        if (DEBUG) console.log("checkIfCacheChanged Same cache ");
    }
}

export default WatchlistManager;