import FireBaseManager from "managers/FireBaseManager";
import UserManager from "managers/UserManager";

const TAG = "Ipo-Tracker: AnticipatedManager ";
const DEBUG = false;

function AnticipatedManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.userManager = new UserManager();
    this.lastAuthenticationState = null;
}

AnticipatedManager.prototype.getAnticipated = async function () {
    if (DEBUG) console.log(TAG + "getAnticipated called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.anticipatedList) {
        this.anticipatedList = [];
        await this.firebaseManager.getAnticipated().then(async (anticipatedList) => {
            if (!anticipatedList) {
                console.log(TAG + "Unable to get anticipated list");
                return null;
            }
            this.anticipatedList = anticipatedList;
        }).catch(e => {
            console.error(TAG + "getTrending Catched exeption: " + e);
        });
    }
    return this.anticipatedList;
}

AnticipatedManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.anticipatedList = null;
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.userManager.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userManager.userInfo) return;
    let currentUserUid = context_.userManager.getFirebaseUser().uid;
    let lastUserUid = context_.userManager.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}

export default AnticipatedManager;