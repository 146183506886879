import React from "react";
import FireBaseManager from "managers/FireBaseManager";
import StockLogo from "utilities/StockLogo";

const TAG = "Ipo-Tracker: PageBuilder ";
const DEBUG = false;
var monthsNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


function PageBuilder(index_ , year_ , active_) {
    if (DEBUG) console.log(TAG + "PageBuilder instance created");
    this.firebaseManager = new FireBaseManager();
    this.title = getTitleFromIndex(index_, year_);
    this.index = index_;
    this.year = year_;
    this.active = active_;
}

PageBuilder.prototype.setHistory = function (history_) {
    this.history = history_;
}

PageBuilder.prototype.getPageTitle = function () {
    if (DEBUG) console.log(TAG + "getPageTitle called");
    return this.title;
}

PageBuilder.prototype.setPageTitle = function (new_) {
    if (DEBUG) console.log(TAG + "setPageTitle called");
    this.title = new_;
}

PageBuilder.prototype.getPageActive = function () {
    if (DEBUG) console.log(TAG + "getPageActive called");
    return this.active
}

PageBuilder.prototype.setPageActive = function (new_) {
    if (DEBUG) console.log(TAG + "setPageActive called");
    this.active = new_;
}

PageBuilder.prototype.getPricedList = async function () {
    if (DEBUG) console.log(TAG + "getPricedList called");
    if (!this.pricedList) {
        this.pricedList = [];
        await this.firebaseManager.getStocksByStatusAndDate(
            "priced",
            getStartDate(this.index, this.year),
            getEndDate(this.index, this.year)).then(async (stockList) => {
                if (stockList == null) {
                    console.log(TAG + "Unable to get priced stock list");
                    return;
                }
                this.originalPricedList = stockList;
                for (var stock_ of stockList) {
                    this.pricedList.push(createStockLine(stock_, this.history));
                }

            }).catch(e => {
                console.error(TAG + "getPricedList Catched exeption: " + e);
            });
    }
    return this.pricedList;
}

PageBuilder.prototype.getExpectedList = async function () {
    if (DEBUG) console.log(TAG + "getExpectedList called");
    if (!this.expectedList) {
        this.expectedList = [];
        await this.firebaseManager.getStocksByStatusAndDate(
            "expected",
            getStartDate(this.index, this.year),
            getEndDate(this.index, this.year)).then(async (stockList) => {
                if (stockList == null) {
                    console.log(TAG + "Unable to get expected stock list");
                    return;
                }
                for (var stock_ of stockList) {
                    this.expectedList.push(createStockLine(stock_,this.history));
                }

            }).catch(e => {
                console.error(TAG + "getPricedList Catched exeption: " + e);
            });
    }
    return this.expectedList;
}

PageBuilder.prototype.getFiledList = async function () {
    if (DEBUG) console.log(TAG + "getFiledList called");
    if (!this.filedList) {
        this.filedList = [];
        await this.firebaseManager.getStocksByStatusAndDate(
            "filed",
            getStartDate(this.index, this.year),
            getEndDate(this.index, this.year)).then(async (stockList) => {
                if (stockList == null) {
                    console.log(TAG + "Unable to get priced stock list");
                    return;
                }
                for (var stock_ of stockList) {
                    this.filedList.push(createStockLine(stock_, this.history));
                }

            }).catch(e => {
                console.error(TAG + "getPricedList Catched exeption: " + e);
            });
    }
    return this.filedList;
}

PageBuilder.prototype.getWithdrawnList = async function () {
    if (DEBUG) console.log(TAG + "getWithdrawnList called");
    if (!this.withdrawnList) {
        this.withdrawnList = [];
        await this.firebaseManager.getStocksByStatusAndDate(
            "withdrawn",
            getStartDate(this.index, this.year),
            getEndDate(this.index, this.year)).then(async (stockList) => {
                if (stockList == null) {
                    console.log(TAG + "Unable to get priced stock list");
                    return;
                }
                for (var stock_ of stockList) {
                    this.withdrawnList.push(createStockLine(stock_, this.history));
                }

            }).catch(e => {
                console.error(TAG + "getPricedList Catched exeption: " + e);
            });
    }
    return this.withdrawnList;
}

function createStockLine(stock_ , history_) {
    if (!stock_) return [];
    let variationText = stock_.isUnknown(stock_.getLastPrice()) ? "Ipo price" : stock_.getVariation();
    let textColor = "";
    if (variationText.includes("-")) {
        textColor = "red";
    } else if (variationText.includes("+")) {
        textColor = "green";
    }
    let variationLine = (<span style={{ color: textColor }}> {variationText} </span>);

    return [
        <StockLogo stock={stock_} round={false} width="40" height="20" minHeight="20px" minWidth="20px"/>,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_);}} style={{ color: "black" }}> {stock_.getName()} </a> </p>,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_); }} style={{ color: "black" }}> {stock_.getSymbol()} </a > </p >,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_); }} style={{ color: "black" }}> {stock_.getExchange()} </a > </p >,
        stock_.isUnknown(stock_.getLastPrice()) ? stock_.getPrice() : stock_.getLastPrice(),
        stock_.isUnknown(stock_.getPrice()) ? "--" :variationLine,
        stock_.getShares(),
        stock_.getValue(),
        stock_.getDate()
    ];
}

function navigateToStockInfo(stockBuilder_, history_) {
    if (!stockBuilder_ || !history_) return;
    if (stockBuilder_.isUnknown(stockBuilder_.getDealId())) return;
    
    history_.push("/u/ipo-info?id=" + stockBuilder_.getDealId());
}

function getTitleFromIndex(index_, year_) {
    return monthsNames[index_] + " " + year_;
}

function getStartDate(month_ , year_) {
    var dd = "01";
    var mm = String(month_ + 1).padStart(2, '0');
    var yyyy = year_;
    var startDate_ = yyyy + '-' + mm + '-' + dd;
    if (DEBUG) console.log(TAG + "Start date: " + startDate_);
    return startDate_;
}

function getEndDate(month_, year_) {
    var dd = "31";
    var mm = String(month_ + 1).padStart(2, '0');
    var yyyy = year_;
    var endDate_ = yyyy + '-' + mm + '-' + dd;   
    if (DEBUG) console.log(TAG + "End date: " + endDate_);
    return endDate_;
}

export default PageBuilder;