import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

//Global variables
const TAG = "Ipo-Tracker: DisclaimerView ";
const DEBUG = false;

const useStyles = makeStyles(styles);

export default function DisclaimerView() {
    const classes = useStyles();
    if (DEBUG) console.log(TAG + "DisclaimerView created");
    return (
        <GridContainer>

            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <h3 className={classes.cardProductTitle}>Risk Warning</h3>
                        <h3 className={classes.cardCategory}> <strong> Ingenuity Apps  would like to remind you that the data contained in this Application is not necessarily real-time nor accurate. All data are not provided by exchanges but rather by market makers, and so information such prices and dates may not be accurate and may differ from the actual market, meaning information is indicative and not appropriate for trading purposes. Therefore Ingenuity Apps doesn't bear any responsibility for any trading losses you might incur as a result of using this data.</strong>  </h3>
                        <p className={classes.cardCategory}>Ingenuity Apps, the developer or anyone involved with this Application will not accept any liability for loss or damage as a result of reliance on the information including but not limited to IPO information, dates, prices and recommendations contained within this Application.
                        Please be fully informed regarding the risks and costs associated with trading the financial markets, it is one of the riskiest investment forms possible.</p>
                    </CardBody>
                </Card>

            </GridItem>
        </GridContainer>
    );
}
