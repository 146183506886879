const DEFAULT = "--";

function QuoteBuilder(name_, symbol_, quotes_, quotesVariation_) {
    this.name = name_;
    this.symbol = symbol_;
    this.quotes = quotes_;
    this.quotesVariation = quotesVariation_;
}

QuoteBuilder.prototype.isUnknown = function () {
    return ((!this.name) || (this.name === DEFAULT));
};

QuoteBuilder.prototype.getName = function () {
    return this.name ? this.name : DEFAULT;
};

QuoteBuilder.prototype.getSymbol = function () {
    return this.symbol ? this.symbol : DEFAULT;
};

QuoteBuilder.prototype.getQuotes = function () {
    return this.quotes ? this.quotes : null;
};

QuoteBuilder.prototype.getQuotesVariation = function () {
    return this.quotesVariation ? this.quotesVariation : null;
};

QuoteBuilder.prototype.setName = function (new_) {
    this.name = new_;
};

QuoteBuilder.prototype.setSymbol = function (new_) {
    this.symbol = new_;
};

QuoteBuilder.prototype.setQuotes = function (new_) {
    this.quotes = new_;
};

QuoteBuilder.prototype.setQuotesVariation = function (new_) {
    this.quotesVariation = new_;
};

module.exports = QuoteBuilder;