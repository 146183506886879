import React from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import ErrorIcon from '@material-ui/icons/Warning';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";

// Custom imports
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import UserManager from "managers/UserManager";
import textLogo from "assets/img/text_logo_big.png";

//Global variables
const TAG = "Ipo-Tracker: LoginView ";
const DEBUG = false;
const useStyles = makeStyles(styles);

async function onGoogleClicked(history_, setSnackVisible_, setSnackMessage_) {
    if (DEBUG) console.log(TAG + "onGoogleClicked called");
    let userManager = new UserManager();
    let errorMessage = await userManager.authenticateGoogle();
    if (DEBUG) console.log(TAG + "onGoogleClicked returned status " + errorMessage);
    if (userManager.isConnected()) {
        history_.goBack();
    } else {
        if (errorMessage && errorMessage.length > 1) {
            setSnackMessage_("Unable to login : " + errorMessage);
            setSnackVisible_(true);
        }
    }
}

async function onFacebookClicked(history_, setSnackVisible_, setSnackMessage_) {
    if (DEBUG) console.log(TAG + "onFacebookClicked called");
    let userManager = new UserManager();
    let errorMessage = await userManager.authenticateFacebook();
    if (DEBUG) console.log(TAG + "onFacebookClicked returned status " + errorMessage);
    if (userManager.isConnected()) {
        history_.goBack();
    } else {
        if (errorMessage && errorMessage.length > 1) {
            setSnackMessage_("Unable to login : " + errorMessage);
            setSnackVisible_(true);
        }
    }
}

async function onTwitterClicked(history_, setSnackVisible_, setSnackMessage_) {
    if (DEBUG) console.log(TAG + "onTwitterClicked called");
    let userManager = new UserManager();
    let errorMessage = await userManager.authenticateTwitter();
    if (DEBUG) console.log(TAG + "onTwitterClicked returned status " + errorMessage);
    if (userManager.isConnected()) {
        history_.goBack();
    } else {
        if (errorMessage && errorMessage.length > 1) {
            setSnackMessage_("Unable to login : " + errorMessage);
            setSnackVisible_(true);
        }
    }
}


function onGuestClicked(history_) {
    if (DEBUG) console.log(TAG + "onGuestClicked called");
    history_.push("/u/home");
}

export default function LoginPage() {
    if (DEBUG) console.log(TAG + "LoginPage called");
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [snackVisible, setSnackVisible] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState("Something Went Wrong");
    
    React.useEffect(() => {
        let id = setTimeout(function () {
            if (DEBUG) console.log(TAG + "setCardAnimation");
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <img src={textLogo} width="800px" alt="logo" justify="center" />
                <br />
                <br />
                <br />
            </GridContainer>
            <GridContainer justify="center">
               
                <GridItem xs={12} sm={6} md={4}>
                    <form>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="success"
                            >
                                <h4 className={classes.cardTitle}>Log in / Sign in</h4>
                            </CardHeader>
                            <CardBody style={{ textAlign: "center" }}>
                                <br />
                                <br/>
                                <Button color="facebook" onClick={() => onFacebookClicked(history, setSnackVisible, setSnackMessage)}>
                                    <i
                                        className={
                                            classes.socialButtonsIcons +
                                            " " +
                                            classes.marginRight +
                                            " fab fa-facebook-square"
                                        }
                                    />{" "}
                                    Connect with Facebook
                                </Button>
                                <Button color="twitter" onClick={() => onTwitterClicked(history, setSnackVisible, setSnackMessage)}>
                                    <i
                                        className={
                                            classes.socialButtonsIcons +
                                            " " +
                                            classes.marginRight +
                                            " fab fa-twitter-square"
                                        }
                                    />{" "}
                                    Connect with Twitter
                                </Button>
                                <Button color="google" onClick={() => onGoogleClicked(history, setSnackVisible, setSnackMessage)}>
                                    <i
                                        className={
                                            classes.socialButtonsIcons +
                                            " " +
                                            classes.marginRight +
                                            " fab fa-google-plus-g"
                                        }
                                    />{" "}
                                    Connect with Google
                                </Button>
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button
                                    color="success"
                                    simple size="lg"
                                    onClick={() => onGuestClicked(history)}
                                    block>
                                    Continue as Guest
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
            <Snackbar
                place="tc"
                color="rose"
                icon={ErrorIcon}
                message={snackMessage}
                open={snackVisible}
                closeNotification={() => setSnackVisible(false)}
                close
            />
        </div>
    );
}
