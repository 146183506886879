import FireBaseManager from "managers/FireBaseManager";
import UserManager from "managers/UserManager";

const TAG = "Ipo-Tracker: EtfManager ";
const DEBUG = false;

function EtfManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.userManager = new UserManager();
    this.lastAuthenticationState = null;
}

EtfManager.prototype.getEtfs = async function () {
    if (DEBUG) console.log(TAG + "getEtfs called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.etfList) {
        this.etfList = [];
        await this.firebaseManager.getEtfs().then(async (etfs) => {
            if (!etfs) {
                console.log(TAG + "Unable to get Etfs list");
                return null;
            }
            this.etfList = etfs;
        }).catch(e => {
            console.error(TAG + "getEtfs Catched exeption: " + e);
        });
    }
    return this.etfList;
}

EtfManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.etfList = null;
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.userManager.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userManager.userInfo) return;
    let currentUserUid = context_.userManager.getFirebaseUser().uid;
    let lastUserUid = context_.userManager.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}

export default EtfManager;