import FireBaseManager from "managers/FireBaseManager";
import UserManager from "managers/UserManager";

const TAG = "Ipo-Tracker: StockInfoManager ";
const DEBUG = false;

function StockInfoManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.userManager = new UserManager();
    this.lastAuthenticationState = null;
}

StockInfoManager.prototype.getInfoByDealId = async function (dealId_) {
    if (DEBUG) console.log(TAG + "getInfoByDealId called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.foundStocksList) {
        this.foundStocksList = [];
        await this.firebaseManager.getStocksByDealId(dealId_).then(async (foundStocksList_) => {
            if (!foundStocksList_) {
                console.log(TAG + "Unable to find stock by dealId for " + dealId_);
                return null;
            }
            this.foundStocksList = foundStocksList_;
        }).catch(e => {
            console.error(TAG + "getStocksByDealId Catched exeption: " + e);
        });
    }
    return (this.foundStocksList && this.foundStocksList.length >= 1) ? this.foundStocksList : null;
}

StockInfoManager.prototype.getQuoteBySymbol = async function (symbol_) {
    if (DEBUG) console.log(TAG + "getQuoteBySymbol called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.foundQuote) {
        await this.firebaseManager.getQuoteBySymbol(symbol_).then(async (foundQuote_) => {
            if (!foundQuote_) {
                console.log(TAG + "Unable to find quote by symbol");
                return null;
            }
            this.foundQuote = foundQuote_;
        }).catch(e => {
            console.error(TAG + "getQuoteBySymbol Catched exeption: " + e);
        });
    }
    return this.foundQuote ? this.foundQuote : null;
}

StockInfoManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.foundStocksList = null;
    this.foundQuote = null;
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.userManager.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userManager.userInfo) return;
    let currentUserUid = context_.userManager.getFirebaseUser().uid;
    let lastUserUid = context_.userManager.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}


export default StockInfoManager;