import FireBaseManager from "managers/FireBaseManager";
import UserManager from "managers/UserManager";

const TAG = "Ipo-Tracker: BrokerManager ";
const DEBUG = false;

function BrokerManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.userManager = new UserManager();
    this.lastAuthenticationState = null;
}

BrokerManager.prototype.getBrokers = async function () {
    if (DEBUG) console.log(TAG + "getBrokers called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.brokersList) {
        this.brokersList = [];
        await this.firebaseManager.getBrokers().then(async (brokers) => {
            if (!brokers) {
                console.log(TAG + "Unable to get Brokers list");
                return null;
            }
            this.brokersList = brokers;
        }).catch(e => {
            console.error(TAG + "getBrokers Catched exeption: " + e);
        });
    }
    return this.brokersList;
}

BrokerManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.brokersList = null;
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.userManager.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userManager.userInfo) return;
    let currentUserUid = context_.userManager.getFirebaseUser().uid;
    let lastUserUid = context_.userManager.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}

export default BrokerManager;