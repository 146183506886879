import React from "react";
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";

import GnewsSrc from "assets/img/gnews.png";
import NasdaqSrc from "assets/img/nasdaq.png";
import CnbcSrc from "assets/img/cnbc.png";
import YahooSrc from "assets/img/yahoo.png";
import WsjSrc from "assets/img/wsj.png";
import MarketWatchSrc from "assets/img/marketwatch.png";
import SalphaSrc from "assets/img/salpha.png";
import MFoolSrc from "assets/img/mfool.png";

import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

//Custom stuff
import { NewsProviders } from "managers/NewsManager";
import NewsViewComponent from "utilities/NewsViewComponent";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const useStylesAlert = makeStyles(alertStyles);

function GnewsIcon(props_) {
    return (
        <img src={GnewsSrc} width={100} heigth={50} alt="..." />
    );
}

function NasdaqIcon(props_) {
    return (
        <img src={NasdaqSrc} width={100} heigth={50} alt="..." />
    );
}

function CnbcIcon(props_) {
    return (
        <img src={CnbcSrc} width={100} heigth={50} alt="..." />
    );
}

function YahooIcon(props_) {
    return (
        <img src={YahooSrc} width={100} heigth={50} alt="..." />
    );
}

function SalphaIcon(props_) {
    return (
        <img src={SalphaSrc} width={100} heigth={50} alt="..." />
    );
}

function WsjIcon(props_) {
    return (
        <img src={WsjSrc} width={100} heigth={50} alt="..." />
    );
}

function MarketWatchIcon(props_) {
    return (
        <img src={MarketWatchSrc} width={100} heigth={50} alt="..." />
    );
}

function MFoolIcon(props_) {
    return (
        <img src={MFoolSrc} width={100} heigth={50} alt="..." />
    );
}

export default function NewsView() {
    const alertClasses = useStylesAlert();
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    React.useEffect(() => {
        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            }
        });
    }, []);

    return (
        adBlockDetected ?
        <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="An Blocker is detected !"
            onConfirm={() => window.location.reload()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="I have disabled AdBlock"
        >
            This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
        </SweetAlert>
        :
        <div>
            <MetaTags>
                <title>IPO News</title>
                <meta name="description" content="Discover the latest IPO (initial public offering), SPAC (Special Purpose Acquisition Company) and direct listing News on the united states and worldwide.
                We provide breaking news for NASDAQ and NYSE latest and upcoming IPO from popular sources like CNBS, Bloomberg, Marketwatch, seeking alpha and more" />
                <meta property="og:title" content="IPO News" />
            </MetaTags>
            <AdsenseView slotType="homeTop"/>
            <GridContainer justify="center">
                <GridItem xs={12}>
                    <NavPills
                        color="success"
                        alignCenter
                        tabs={[
                            {
                                tabButton: "MIX",
                                tabIcon: GnewsIcon,
                                tabContent: (
                                    <div>
                                        <h3>Google News</h3>
                                        < br />
                                        <NewsViewComponent newsProvider={NewsProviders.GNEWS} maxItems={30}/>
                                    </div>
                                )
                            },
                            {
                                tabButton: "CNBC",
                                tabIcon: CnbcIcon,
                                tabContent: (
                                    <div>
                                        <h3>CNBC News</h3>
                                        < br />
                                        <NewsViewComponent newsProvider={NewsProviders.CNBC} maxItems={30} />
                                    </div>
                                )
                            },
                            {
                                tabButton: "NASDAQ",
                                tabIcon: NasdaqIcon,
                                tabContent: (
                                    <div>
                                        <h3>Nasdaq News</h3>
                                        < br />
                                        <NewsViewComponent newsProvider={NewsProviders.NASDAQ} maxItems={30}/>                                       
                                    </div>
                                )
                            },
                            {
                                tabButton: "Yahoo Finance",
                                tabIcon: YahooIcon,
                                tabContent: (
                                    <div>
                                        <h3>Yahoo Finance News</h3>
                                        < br />
                                        <NewsViewComponent newsProvider={NewsProviders.YAHOO} maxItems={30}/>
                                    </div>
                                )
                            },
                            {
                                tabButton: "WSJ",
                                tabIcon: WsjIcon,
                                tabContent: (
                                    <div>
                                        <h3>Wall Street Journal News</h3>
                                        < br />
                                        <NewsViewComponent newsProvider={NewsProviders.WSJ} maxItems={30}/>
                                    </div>
                                )
                            },
                            {
                                tabButton: "Market Watch",
                                tabIcon: MarketWatchIcon,
                                tabContent: (
                                    <div>
                                        <h3>Market Watch News</h3>
                                        < br />
                                        <NewsViewComponent newsProvider={NewsProviders.MARKETWATCH} maxItems={30}/>
                                    </div>
                                )
                            },
                            {
                                tabButton: "Seeking Alpha",
                                tabIcon: SalphaIcon,
                                tabContent: (
                                    <div>
                                        <h3>Seeking Alpha News</h3>
                                        < br />
                                        <NewsViewComponent newsProvider={NewsProviders.SALPHA} maxItems={30}/>
                                    </div>
                                )
                            },
                            {
                                tabButton: "Motely Fool",
                                tabIcon: MFoolIcon,
                                tabContent: (
                                    <div>
                                        <h3>Motely Fool News</h3>
                                        < br />
                                        <NewsViewComponent newsProvider={NewsProviders.MFOOL} maxItems={30} />
                                    </div>
                                )
                            }  
                        ]}
                    />
                </GridItem>
            </GridContainer>
            <AdsenseView slotType="homeBottom"/>
        </div>
    );
}
