import React from "react";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import TrendingIcon from "@material-ui/icons/Whatshot";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

//Custom stuff
import UserManager from "managers/UserManager";
import TrendingManager from "managers/TrendingManager";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: TopPerformersView ";
const DEBUG = false;
const trendingManager = new TrendingManager();
const userManager = new UserManager();
const showLoadingBar = <LinearProgress />;
const defaultTableHeader = ["#", "", "Name", "Symbol", "Price", "Variation" , "Status", "Date"];
const emptyTableHeader = ["No data to be displayed"];
const emptyTableContent = [[""]];
const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};
const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

export default function RegularTables() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();

    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [tableHeader, setTableHeader] = React.useState(defaultTableHeader);
    const [trendingData, setTrendingData] = React.useState([]);
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    React.useEffect(() => {
        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            setLoadingBar(showLoadingBar);
            let newTrendingData = await trendingManager.getTrending(history);
            if (newTrendingData && !newTrendingData.length < 1) {
                setTableHeader(defaultTableHeader);
                await setTrendingData(newTrendingData);
            } else {
                setTableHeader(emptyTableHeader);
                setTrendingData(emptyTableContent);
            }
            setLoadingBar();
        }

        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            } else {
                if (userManager.isConnected()) {
                    fetchData();
                }
            }
        });
        
    }, [history]);

    if (adBlockDetected) {
        return (<SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="An Blocker is detected !"
            onConfirm={() => window.location.reload()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="I have disabled AdBlock"
        >
            This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
        </SweetAlert>);
    } else if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        const login = () => {
            history.push("/auth/login-page");
        };
        const goBack = () => {
            history.goBack();
        };
        
        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <div>
                <AdsenseView slotType="homeTop" />
                <GridContainer>
                <MetaTags>
                    <title>Top Trending</title>
                    <meta name="description" content="Discover the hottest and most watched initial public offerings like airbnb, doordash, bumble, snowflake, roblox and more " />
                    <meta property="og:title" content="Top Trending IPO" />
                </MetaTags>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="danger" icon>
                            <CardIcon color="danger">
                                <TrendingIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Top trending</h4>
                        </CardHeader>
                        <CardBody>
                            {loadingBar}
                            <Table
                                striped
                                hover
                                tableHeaderColor="primary"
                                tableHead={tableHeader}
                                tableData={trendingData}
                                coloredColls={[7]}
                                style={{ visibility: 'hidden' }}
                                colorsColls={["primary"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                </GridContainer>
                <AdsenseView slotType="homeBottom" />
            </div>
        );
        return userConnectedLayout;
    }
}
