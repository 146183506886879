const DEFAULT = "--";

function BrokerBuilder(name_, description_, eligibility_, logo_, url_, reviewLink_) {
    this.name = name_;
    this.description = description_;
    this.eligibility = eligibility_;
    this.logo = logo_;
    this.url = url_;
    this.reviewLink = reviewLink_;
}

BrokerBuilder.prototype.setReview = function (overallNote_, educationNote_, commissionsNote_, customerServiceNote_, usageNote_, mobileNote_, offeringNote_, toolsNote_, researchNote_) {
    this.overallNote = overallNote_;
    this.educationNote = educationNote_;
    this.commissionsNote = commissionsNote_;
    this.customerServiceNote = customerServiceNote_;
    this.usageNote = usageNote_;
    this.mobileNote = mobileNote_;
    this.offeringNote = offeringNote_;
    this.toolsNote = toolsNote_;
    this.researchNote = researchNote_;
};

BrokerBuilder.prototype.isUnknown = function () {
    return ((!this.name) || (this.name === DEFAULT));
};

BrokerBuilder.prototype.getName = function () {
    return this.name ? this.name : DEFAULT;
};

BrokerBuilder.prototype.getDescription = function () {
    if (!this.description) return DEFAULT;
    let formattedText = this.description.replace(/\\n/g, "\n");
    return formattedText ? formattedText : DEFAULT;
};

BrokerBuilder.prototype.getEligibility = function () {
    if (!this.eligibility) return DEFAULT;
    let formattedText = this.eligibility.replace(/\\n/g, "\n");
    return formattedText ? formattedText : DEFAULT;
};

BrokerBuilder.prototype.getLogo = function () {
    return this.logo ? this.logo : null;
};

BrokerBuilder.prototype.getUrl = function () {
    return this.url ? this.url : DEFAULT;
};

BrokerBuilder.prototype.getReviewLink = function () {
    return this.reviewLink ? this.reviewLink : DEFAULT;
};

BrokerBuilder.prototype.setName = function (new_) {
    this.name = new_;
};

BrokerBuilder.prototype.setDescription = function (new_) {
    this.description = new_;
};

BrokerBuilder.prototype.setEligibility = function (new_) {
    this.eligibility = new_;
};

BrokerBuilder.prototype.setLogo = function (new_) {
    this.logo = new_;
};

BrokerBuilder.prototype.setUrl = function (new_) {
    this.url = new_;
};

BrokerBuilder.prototype.setReviewLink = function (new_) {
    this.reviewLink = new_;
};

BrokerBuilder.prototype.getOverallNote = function () {
    return this.overallNote ? this.overallNote : DEFAULT;
};

BrokerBuilder.prototype.getEducationNote = function () {
    return this.educationNote ? this.educationNote : DEFAULT;
};

BrokerBuilder.prototype.getCommissionsNote = function () {
    return this.commissionsNote ? this.commissionsNote : DEFAULT;
};

BrokerBuilder.prototype.getCustomerServiceNote = function () {
    return this.customerServiceNote ? this.customerServiceNote : DEFAULT;
};

BrokerBuilder.prototype.getUsageNote = function () {
    return this.usageNote ? this.usageNote : DEFAULT;
};

BrokerBuilder.prototype.getMobileNote = function () {
    return this.mobileNote ? this.mobileNote : DEFAULT;
};

BrokerBuilder.prototype.getOfferingNote = function () {
    return this.offeringNote ? this.offeringNote : DEFAULT;
};

BrokerBuilder.prototype.getToolsNote = function () {
    return this.toolsNote ? this.toolsNote : DEFAULT;
};

BrokerBuilder.prototype.getRresearchNote = function () {
    return this.researchNote ? this.researchNote : DEFAULT;
};

BrokerBuilder.prototype.getLogoDownloadUrl = function () {
    return this.logoDownloadUrl ? this.logoDownloadUrl : null;
};

BrokerBuilder.prototype.setLogoDownloadUrl = function (new_) {
    this.logoDownloadUrl = new_;
};

module.exports = BrokerBuilder;