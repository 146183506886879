const DEFAULT = "--";

function UserBuilder(id_,name_, mail_, pseudo_, inscription_, last_, photo_, watchlist_) {
    this.id = id_;
    this.name = name_;
    this.mail = mail_;
    this.pseudo = pseudo_;
    this.inscription = inscription_;
    this.last = last_;
    this.photo = photo_;
    this.watchlist = watchlist_;
}

UserBuilder.prototype.isUnknown = function () {
    return ((!this.id) || (this.id === DEFAULT));
};

UserBuilder.prototype.getId = function () {
    return this.id ? this.id : DEFAULT;
};

UserBuilder.prototype.setId = function (new_) {
    this.id = new_;
};

UserBuilder.prototype.getName = function () {
    return this.name ? this.name : DEFAULT;
};

UserBuilder.prototype.setName = function (new_) {
    this.name = new_;
};

UserBuilder.prototype.getMail = function () {
    return this.mail ? this.mail : DEFAULT;
};

UserBuilder.prototype.setMail = function (new_) {
    this.mail = new_;
};

UserBuilder.prototype.getPseudo = function () {
    return this.pseudo ? this.pseudo : DEFAULT;
};

UserBuilder.prototype.setPseudo = function (new_) {
    this.pseudo = new_;
};

UserBuilder.prototype.getInscription = function () {
    return this.inscription ? this.inscription : DEFAULT;
};

UserBuilder.prototype.setInscription = function (new_) {
    this.inscription = new_;
};

UserBuilder.prototype.getLast = function () {
    return this.last ? this.last : DEFAULT;
};

UserBuilder.prototype.setLast = function (new_) {
    this.last = new_;
};

UserBuilder.prototype.getPhoto = function () {
    return this.photo ? this.photo : null;
};

UserBuilder.prototype.setPhoto = function (new_) {
    this.photo = new_;
};

UserBuilder.prototype.getWatchlist = function () {
    return this.watchlist ? this.watchlist : null;
};

UserBuilder.prototype.setWatchlist = function (new_) {
    this.watchlist = new_;
};

module.exports = UserBuilder;