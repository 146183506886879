import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';

//Custom icons
import googleIcon from 'assets/img/thirdParties/google_com.png';
import investingIcon from 'assets/img/thirdParties/check_investing.png';
import nasdaqIcon from 'assets/img/thirdParties/nasdaq_com.png';
import robinhoodIcon from 'assets/img/thirdParties/robinhood_com.png';
import webullIcon from 'assets/img/thirdParties/webull_com.png';
import etoroIcon from 'assets/img/thirdParties/etoro_com.png';
import ameritradeIcon from 'assets/img/thirdParties/ameritrade_com.png';
import fidelityIcon from 'assets/img/thirdParties/fidelity_com.png';

import { successColor } from "assets/jss/material-dashboard-pro-react.js";

// custom spinner
import { css } from "@emotion/core";
import Spinner from "react-spinners/HashLoader";

//Custom imports
import SearchLinkManager from "managers/SearchLinkManager";

const TAG = "Ipo-Tracker: SearchChips ";
const DEBUG = false;
const ENABLE_ETORO = false;
const searchLinkManager = new SearchLinkManager();

const override = css`
  display: block;
  margin: 3px;
  paddingTop: 10px;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
}));


function openInNewTab(url_) {
    console.log("openInNewTab called with URL: " + url_);
    if (url_ && url_.length > 1) {
        var win = window.open(url_, '_blank');
        win.focus();
    }
}

export default function SearchChips(props) {
    const {
        stock
    } = props;
    const classes = useStyles();
    const [loadingLinks, setLoadingLinks] = React.useState(true);
    const [investingLink, setInvestingLink] = React.useState(null);
    const [robinhoodLink, setRobinhoodLink] = React.useState(null);
    const [webullLink, setWebullLink] = React.useState(null);
    const [etoroLink, setEtoroLink] = React.useState(null);
    const [ameritradeLink, setAmeritradeLink] = React.useState(null);
    const [fidelityLink, setFidelityLink] = React.useState(null);

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    function createChipItem(iconSrc_, Link_ , isSearch_ , isUnverified_) {
        return (<Chip
            variant="outlined"
            deleteIcon={
                isSearch_ ?
                <SearchIcon style={{ marginBottom: "8px" }} />
                :
                isUnverified_ ?
                    <HelpIcon style={{ marginBottom: "8px" }} />
                    :
                    <VerifiedUserIcon style={{ marginBottom: "8px" }} />
                }
            label={<img src={iconSrc_} width={80} alt="..." />}
            size="small"
            onClick={e => {
                e.preventDefault();
                openInNewTab(Link_);
            }}
            onDelete={handleDelete}
            style={{ margin: "3px" }}/>);
    }

    React.useEffect(() => {
        var newLinksArray = {};

        async function fetchSearchLinks() {
            if (DEBUG) console.log(TAG + "fetchSearchLinks called");
            await fetchInvestingLink();
            await fetchWebullLink();
            await fetchRobinhoodLink();
            await fetchEtoroLink();
            await fetchAmeritradeLink();
            await fetchFidelityLink();
            await saveNewLinks();
            setLoadingLinks(false);
        }

        async function fetchInvestingLink() {
            if (stock.isUnknown(stock.getInvestingLink())) {
                if (DEBUG) console.log(TAG + "fetchInvestingLink search new link"); 
                await searchLinkManager.getInvestingLink(stock.getName()).then(async (response) => {
                    if (response == null) {
                        console.error(TAG + "getInvestingLink Response is empty");
                        return;
                    }
                    if (DEBUG) console.log(TAG + "getInvestingLink Found Link: " + response);
                    if (response != null) {
                        setInvestingLink(response);
                        newLinksArray["investingLink"] = response;
                    }
                });
            } else {
                if (DEBUG) console.log(TAG + "fetchInvestingLink already have investing link");
                setInvestingLink(stock.getInvestingLink());
            }
        }

        async function fetchRobinhoodLink() {
            if (stock.isUnknown(stock.getRobinhoodLink())) {
                if (DEBUG) console.log(TAG + "fetchRobinhoodLink search new link");
                if (stock.isUnknown(stock.getSymbol())) return;
                await searchLinkManager.getRobinhoodLink(stock.getSymbol()).then(async (response) => {
                    if (response == null) {
                        console.error(TAG + "fetchRobinhoodLink Response is NULL");
                        return;
                    }
                    if (DEBUG) console.log(TAG + "fetchRobinhoodLink Found Link: " + response);
                    if (response != null) {
                        setRobinhoodLink(response);
                        newLinksArray["robinhoodLink"] = response;
                    }
                });
            } else {
                setRobinhoodLink(stock.getRobinhoodLink());
            }
        }

        async function fetchWebullLink() {
            if (stock.isUnknown(stock.getWebullLink())) {
                if (DEBUG) console.log(TAG + "fetchWebullLink search new link");
                if (stock.isUnknown(stock.getSymbol()) || stock.isUnknown(stock.getExchange())) return;
                await searchLinkManager.getWebullLink(stock.getSymbol(), stock.getExchange()).then(async (response) => {
                    if (response == null) {
                        console.error(TAG + "fetchWebullLink Response is NULL");
                        return;
                    }
                    if (DEBUG) console.log(TAG + "fetchWebullLink Found Link: " + response);
                    if (response != null) {
                        setWebullLink(response);
                        newLinksArray["webullLink"] = response;
                    }
                });
            } else {
                setWebullLink(stock.getWebullLink());
            }
        }

        async function fetchEtoroLink() {
            if (stock.isUnknown(stock.getEtoroLink())) {
                    if (DEBUG) console.log(TAG + "fetchEtoroLink search new link");
                    if (stock.isUnknown(stock.getSymbol())) return;
                    await searchLinkManager.getEtoroLink(stock.getSymbol(), !ENABLE_ETORO).then(async (response) => {
                        if (response == null) {
                            console.error(TAG + "fetchEtoroLink Response is NULL");
                            return;
                        }
                        if (DEBUG) console.log(TAG + "fetchEtoroLink Found Link: " + response);
                        if (response != null) {
                            setEtoroLink(response);
                            if (ENABLE_ETORO) newLinksArray["etoroLink"] = response;
                        }
                    });
            } else {
                setEtoroLink(stock.getEtoroLink());
            }
        }

        async function fetchAmeritradeLink() {
            if (stock.isUnknown(stock.getAmeritradeLink())) {
                if (DEBUG) console.log(TAG + "fetchAmeritradeLink search new link");
                if (stock.isUnknown(stock.getSymbol())) return;
                await searchLinkManager.getAmeritradeLink(stock.getSymbol()).then(async (response) => {
                    if (response == null) {
                        console.error(TAG + "fetchAmeritradeLink Response is NULL");
                        return;
                    }
                    if (DEBUG) console.log(TAG + "fetchAmeritradeLink Found Link: " + response);
                    if (response != null) {
                        setAmeritradeLink(response);
                        newLinksArray["ameritradeLink"] = response;
                    }
                });
            } else {
                setAmeritradeLink(stock.getAmeritradeLink());
            }
        }

        async function fetchFidelityLink() {
            if (stock.isUnknown(stock.getFidelityLink())) {
                if (DEBUG) console.log(TAG + "fetchFidelityLink search new link");
                if (stock.isUnknown(stock.getSymbol())) return;
                await searchLinkManager.getFidelityLink(stock.getSymbol()).then(async (response) => {
                    if (response == null) {
                        console.error(TAG + "fetchFidelityLink Response is NULL");
                        return;
                    }
                    if (DEBUG) console.log(TAG + "fetchFidelityLink Found Link: " + response);
                    if (response != null) {
                        setFidelityLink(response);
                        newLinksArray["fidelityLink"] = response;
                    }
                });
            } else {
                setFidelityLink(stock.getFidelityLink());
            }
        }

        async function saveNewLinks() {
            if (DEBUG) console.log(TAG + "saveNewLinks: " + JSON.stringify(newLinksArray));
            if (newLinksArray && Object.keys(newLinksArray).length > 0) {
                if (DEBUG) console.log(TAG + "saveNewLinks called");
                await searchLinkManager.updateStockSearchLinks(stock, newLinksArray);
            }
        }

        fetchSearchLinks();
        
    }, [stock])

    if (!stock) return (<div />);

    let loadingAnimation = (<div />);
    if (loadingLinks) {
        loadingAnimation = (<Spinner size={20} css={override} color={successColor[0]} />);
    }
    let googleSearchChip = createChipItem(googleIcon, stock.getGoogleLink(), true , false);
    let nasdaqSearchChip = createChipItem(nasdaqIcon, stock.getNasdaqLink(), true, false);
    let investingSearchChip = (<div />);
    if (investingLink) {
        let investingLink_ = investingLink;
        //Workaround to avoid home redirection
        if (investingLink_) investingLink_ = investingLink_.replace("www.", "m.");
        investingSearchChip = createChipItem(investingIcon, investingLink_, true, false);
    }
    let robinhoodSearchChip = (<div />);
    if (robinhoodLink) {
        robinhoodSearchChip = createChipItem(robinhoodIcon, robinhoodLink, false, false);
    }
    let webullSearchChip = (<div />);
    if (webullLink) {
        webullSearchChip = createChipItem(webullIcon, webullLink, false, false);
    }
    let ameritradeSearchChip = (<div />);
    if (ameritradeLink) {
        ameritradeSearchChip = createChipItem(ameritradeIcon, ameritradeLink, false, false);
    }
    let fidelitySearchChip = (<div />);
    if (fidelityLink) {
        fidelitySearchChip = createChipItem(fidelityIcon, fidelityLink, false, false);
    }
    let etoroSearchChip = (<div />);
    if (etoroLink && investingLink && robinhoodLink && webullLink && ameritradeLink && fidelityLink) {
        etoroSearchChip = createChipItem(etoroIcon, etoroLink, false, true);
    }
    return (
        <div className={classes.root}>
            {googleSearchChip}
            {nasdaqSearchChip}
            {investingSearchChip}
            {webullSearchChip}
            {robinhoodSearchChip}
            {etoroSearchChip}
            {ameritradeSearchChip}
            {fidelitySearchChip}

            {loadingAnimation}
        </div>
    );
}

SearchChips.defaultProps = {
    stock: null
};
