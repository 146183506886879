import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "components/Accordion/Accordion.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

//Global variables
const TAG = "Ipo-Tracker: TermsConditions ";
const DEBUG = false;

const useStyles = makeStyles(styles);

export default function TermsConditionsView() {
    const classes = useStyles();
    if (DEBUG) console.log(TAG + "TermsConditionsView created");
    return (
            <GridContainer>

            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <h6 className={classes.cardCategory}>The following describes the terms and conditions (the "Terms and Conditions") upon which the developper (the "Company" or "Ingenuity" or "We") offers access to this application (the "Application") to you the customer, irrespective of whether or not you are an Account (as defined below) holder ("You") and the use of its services provided via the application (the "Services").  We may provide updates for the Application from time to time including (but not limited to) enhanced functionality, bug fixes, patches, plug-ins and new versions of the Application (the "Updates"). By installing the Application, you give permission for the automatic download and installation of Updates and agree to download and install Updates manually to the extent necessary.</h6>

                        <p className={classes.cardCategory}>PLEASE READ THE PRIVACY POLICY, RISK WARNING AND ALL OF THE FOLLOWING TERMS AND CONDITIONS  BEFORE USING THIS APPLICATION. BY CONTINUING TO ACCESS OR USE THIS APPLICATION, THE UPDATES OR ANY SERVICES , YOU SIGNIFY YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS. We reserve the right to amend, remove, or add to these Terms and Conditions at any time.  Accordingly, please review these Terms and Conditions whenever accessing or using this Application. Please check the "Last modified" heading at the bottom of this document to see when the Terms and Conditions were last updated. Your use of the Application or any Services, following any change means that you accept the updated Terms and Conditions. If at any time, you do not wish to accept the Terms and Conditions, you may not use the Application. Any terms and conditions proposed by you which are in addition to or which conflict with these Terms and Conditions are expressly rejected by the Company and shall be of no force or effect.
                            You understand and agree that Ingenuity may discontinue or change the Application at any time, without notice. You also understand and agree that Ingenuity may discontinue or restrict your use of this Application for any reason without notice. </p>
                    </CardBody>
                </Card>

                <Card>
                        <CardBody>
                        <Accordion
                            active={0}
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>Your Account</h3>,
                                        content:
                                            <div>
                                                <p className={classes.cardCategory}>
                                                    As part of the process necessary to set up an account on the Application (an "Account") and obtain access to certain parts of the Services, you will be required to either provide your full name, email address, password, and phone number (the "Registration Credentials") or register via one of the Third Party Accounts (as defined below). You must ensure that your Registration Credentials are accurate, truthful and updated. We reserve the right to block the creation of your Account based on our inability to confirm the authenticity of your Registration Credentials.
                                                    </p>
                                                <p className={classes.cardCategory}>As an alternative to registering directly with the Application by providing your Registration Credentials, we give you the option to register and login to your Account via one of your account(s) with social media sites such as Facebook, Twitter or Google+ (the "Third Party Account(s)"). Please see our Privacy Policy for more information about logging in to your Account via a Third Party Account.</p>
                                                <p className={classes.cardCategory}>Unless you log into your Account via a Third Party Account, please note that your email address and password which you provide as part of the Registration Credentials will be used to login in to your Account. You will be solely responsible for maintaining the confidentiality of your email address and password and must immediately notify us of any unauthorized use of your Account. You are solely responsible for all activity and usage on your Account, including, but not limited to, use of the Account by any third party authorized by you to use your email address and password.</p>
                                                <p className={classes.cardCategory}>We permit you to maintain only one Account to access the Services at any time and you hereby represent that you currently have no other Account(s).</p>
                                                <p className={classes.cardCategory}>We reserve the right to terminate your Account, in our sole discretion, at any time without notice. You may terminate your Account at any time via sending an e-mail to Ingenuity. Upon termination, you will receive a confirmation e-mail.</p>
                                            </div>
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                    
                <Card>
                        <CardBody>
                        <Accordion
                            active={0}
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>DISCLAIMER</h3>,
                                        content:
                                            <div>
                                                <p className={classes.cardCategory}>Due to the number of sources from which the content presented on the Application is obtained, and the inherent hazards of electronic distribution, there may be delays, omissions or inaccuracies in such content and the Application.</p>
                                                <p className={classes.cardCategory}>THE CONTENT AND THE WEB APPLICATION ARE PROVIDED "AS IS", WITHOUT ANY WARRANTIES. INGENUITY, ITS EMPLOYEES, OFFICERS, DIRECTORS, AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, TIMELINESS, NONINFRINGEMENT, TITLE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE CONTENT AVAILABLE THROUGH THE APPLICATION, OR THE APPLICATION ITSELF, AND INGENUITY HEREBY DISCLAIMS ANY SUCH EXPRESS OR IMPLIED WARRANTIES.</p>
                                                <p className={classes.cardCategory}>IN NO EVENT SHALL INGENUITY, ITS EMPLOYEES, OFFICERS, DIRECTORS, AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR TO ANY THIRD PARTY OR TO ANYONE ELSE FOR ANY KIND OF FINANCIAL LOSS, LOST PROFITS, ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGE OR ANY OTHER SIMILAR DAMAGE OR ANY OTHER LOSS OR INJURY, RESULTING DIRECTLY OR INDIRECTLY FROM USE OF THE APPLICATION, CAUSED IN WHOLE OR PART BY ITS NEGLIGENCE OR CONTINGENCIES BEYOND ITS CONTROL IN PROCURING, COMPILING, INTERPRETING, REPORTING OR DELIVERING THE APPLICATION AND ANY CONTENT ON THE APPLICATION.</p>
                                                <p className={classes.cardCategory}>IN NO EVENT SHALL INGENUITY, ITS EMPLOYEES, OFFICERS, DIRECTORS, AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY OR ANY ONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH CONTENT ON THE APPLICATION OR THE APPLICATION ITSELF.</p>
                                            </div>
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Legal Restrictions</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>Without limiting the foregoing, you understand that laws regarding financial contracts vary throughout the world, and it is your sole obligation to ensure that you fully comply with any law, regulation or directive, applicable to your country of residence with regards to the use of the Application. The ability to use the Application does not necessarily mean that our Services, and/or your activities via the Application, are legal under the laws, regulations or directives applicable to your country of residence.</p>
                                            <p className={classes.cardCategory}>This Application does not constitute, and may not be used for the purposes of, an offer or solicitation to anyone in any jurisdiction in which such an offer or solicitation is not authorized, or to any person to whom it is unlawful to make such an offer or solicitation. Access to this Application, and the offering of financial contracts via this Application, may be restricted in certain jurisdictions, and, accordingly, users accessing this Application are required to inform themselves of, and to observe, such restrictions.</p>

                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Limited License</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>Ingenuity grants you a non-exclusive, non-transferable and limited personal license to access and use the Application (the "License"). This License is conditional on your full and continuing compliance with these Terms and Conditions. You agree not to "deep-link" to the Application, resell or permit access to the Application to others, and not to copy any materials appearing on the Application for resale or for any other purpose to others without the prior written consent of Ingenuity . You shall be responsible and bound by any unauthorized use of the Application , made in breach of this section. You agree not to use any electronic communication feature of Services on the Application for any purpose that is unlawful, tortious, abusive, and intrusive on another's privacy, harassing, libelous, defamatory, embarrassing, obscene, threatening or hateful. The License granted under these Terms and Conditions will terminate if Ingenuity  believes that any information provided by you, including (but not limited to) your Registration Credentials, is no longer current or accurate, or if you fail to otherwise comply with any term or condition of these Terms and Conditions and all rules and guidelines for each of the Services. Upon such violation, you agree to cease accessing Services. You agree that Ingenuity , at its sole discretion and with or without notice, may terminate your access to any or all Services, and remove and discard any information or content within the Services.</p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Links to Third Party Sites</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>This Application contains hyperlinks to web sites operated by persons other than Ingenuity. Such hyperlinks are provided for your reference and convenience only. You agree not to hold Ingenuity responsible for the content or operation of such web sites. A hyperlink from this Application to another website or application does not imply that Ingenuity endorses the content on that web site or the operator or operations of that site. You are solely responsible for determining the extent to which you may use any content at any other web sites to which you might link from this Application.</p>
                                            <p className={classes.cardCategory}>If you wish to make purchases from an area of the Application , you may be asked to supply certain information, including credit card, debit card, or other payment mechanism information.</p>
                                            <p className={classes.cardCategory}>You agree not to hold Ingenuity liable for any loss or damage of any sort incurred as a result of any such dealings. You agree that all information you provide in connection with such purchase will be accurate, complete and current. You agree to pay all charges incurred by use of your credit card, debit card, or other payment mechanism at the prices in effect when such charges are incurred. You also will pay any applicable taxes relating to any purchases you make.</p>
                                            <p className={classes.cardCategory}>THE LIABILITY OF INGENUITY, ITS OFFICERS, DIRECTORS OR EMPLOYEES, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE AMOUNT OF MONEY YOU TRANSFERRED TO INGENUITY IN RELATION TO THE TRANSACTION GIVING RISE TO SUCH LIABILITY.</p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Market Information</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>Ingenuity may make available to you through one or more of its Services a broad range of financial information that is generated internally or obtained from agents, vendors or partners ("Third Party Providers"). This includes, but is not limited to, financial market data, quotes, news, analyst opinions, research reports, graphs and data ("Market Information").</p>
                                            <p className={classes.cardCategory}>Market Information provided on the Application is not intended as investment advice. Ingenuity does not endorse or approve the Market Information, and we make it available to you only as a service for your own convenience. Ingenuity and its Third Party Providers do not guarantee the accuracy, timeliness, completeness or correct sequencing of the Market Information, or warrant any results from your use or reliance on the Market Information.</p>
                                            <p className={classes.cardCategory}>Market Information may quickly become unreliable for various reasons including, for example, changes in market conditions or economic circumstances. Neither Ingenuity nor the Third Party Providers are obligated to update any information or opinions contained in any Market Information, and we may discontinue offering Market Information at any time without notice. You agree that neither Ingenuity nor the Third Party Providers will be liable in any way for the termination, interruption, delay or inaccuracy of any Market Information. You will not "deep-link", redistribute or facilitate the redistribution of Market Information, nor will you provide access to Market Information to anyone who is not authorized by Ingenuity to receive Market Information.</p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Use & Access to the Application</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>You shall be responsible for providing and maintaining the means by which you access the Application, which may include, but is not limited to, your personal computer or mobile device, connectivity hardware, and telecommunication lines.</p>
                                            <p className={classes.cardCategory}>You shall be responsible for all access and service fees necessary to connect to the Application and assume all charges incurred by use of such connectivity services. You further assume all risks associated with the use and storage of information on your personal computer, mobile device or on any other computer or device through which you will gain access to the Application and the Services (hereinafter referred to as "Devices").</p>
                                            <p className={classes.cardCategory}>You represent and warrant that you have implemented and plan to operate and maintain appropriate protection in relation to the security and control of your Devices and any information and data included therein.</p>
                                            <p className={classes.cardCategory}>You agree that Ingenuity will not be liable in any way to you in the event of failure of or damage or destruction to your Devices systems, data or records or any part thereof, or for delays, losses, errors or omissions resulting from the failure or mismanagement of any telecommunications or Devices equipment or software.</p>
                                            <p className={classes.cardCategory}>You will not in any way, whether directly or indirectly, expose Ingenuity or any of Ingenuity's online service providers to any computer virus or other similarly harmful or inappropriate material or device.</p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Company's Rights</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>Ingenuity reserves the right to suspend the operation of this Application or any part or sections of it at any time and no claims may be entertained against the Company in connection thereto.</p>
                                            
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Force Majeure</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>You agree that Ingenuity will not be liable in any way to you or to any other person in the event of force majeure (including, but not limited to, the act of any government or legal authority) or for the failure of or damage or destruction to your devices systems, data or records or any part thereof, or for delays, losses, errors or omissions resulting from the failure or mismanagement of any telecommunications or computer equipment or software.</p>
                                            
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Technical Problems</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>You understand that technical problems or other conditions may delay or prevent you from accessing the Application.</p>
                                            <p className={classes.cardCategory}>Ingenuity shall not be liable, and you agree not to hold or seek to hold Ingenuity  or any of its agents or service providers liable, for any technical problems, system failures and malfunctions, communication line failures, equipment or software failures or malfunctions, system access issues, system capacity issues, high Internet traffic demand, security breaches and unauthorized access, and other similar computer problems and defects.</p>
                                            <p className={classes.cardCategory}>Ingenuity  does not represent, warrant or guarantee that you will be able to access or use the Application at times or locations of your choosing, or that Ingenuity will have adequate capacity for the Application as a whole or in any geographic location.</p>
                                            <p className={classes.cardCategory}>Ingenuity does not represent, warrant or guarantee that the Application will provide uninterrupted and error-free service. Ingenuity does not make any warranties or guarantees with respect to the Application and its content, including but not limited to, warranties for merchantability or fitness for a particular purpose.</p>
                                            <p className={classes.cardCategory}>Without limiting the foregoing Ingenuity will not be responsible for an impossibility to execute orders and requirements due to failures in the operation of informational systems caused by technical faults, which are beyond its control.</p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Trademarks and Copyrights</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>All content, trademarks, services marks, trade names, logos and icons are owned by Ingenuity, or its affiliates, or other licensors and are protected by copyright and trademark laws, and international treaties and provisions.</p>
                                            <p className={classes.cardCategory}>You agree not to delete any copyright notices or other indications of protected intellectual property rights from materials that you print or download from the Application. You will not obtain any intellectual property rights or any right or license to use such materials or the Application, other than as expressly set out in these Terms and Conditions.</p>
                                            <p className={classes.cardCategory}>Images displayed on the Application are either the property of Ingenuity or its licensors. You agree not to upload, post, reproduce or distribute any information, software or other material protected by copyright or any other intellectual property right (as well as rights of publicity and privacy) without first obtaining the permission of the owner of such rights and the prior written consent of Ingenuity.</p>
                                            <p className={classes.cardCategory}>Nothing contained on the Application may be construed as granting, by implication, estoppels, or otherwise, any license or right to use any trademark without the written permission of Ingenuity or any third party that may own the trademarks. Your use of trademarks, or any other content of the Application, except as provided herein, is strictly prohibited.</p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Accordion
                            active={0}
                            collapses={[
                                {
                                    title: <h3 className={classes.cardProductTitle}>Breach</h3>,
                                    content:
                                        <div>
                                            <p className={classes.cardCategory}>You agree to fully indemnify, defend and hold harmless Ingenuity, its corporate affiliates and their respective officers, directors and employees immediately upon demand from and against all claims, demands, liabilities, damages, losses, costs and expenses, including legal fees and other charges whatsoever, howsoever caused, that may arise as a result of: (i) any breach of these Terms and Conditions by you or (ii) violation by you of any law or the rights of any third party.</p>
                                            <p className={classes.cardCategory}>Without prejudice to any other rights in these Terms and Conditions, if you breach in whole or in part any provision contained herein, Ingenuity or any of its corporate affiliates which provides Services to you reserves the right to take such action as it sees fit, including (but not limited to) terminating any agreement in place with you, terminating or blocking the Services  and/or taking legal action against you.</p>
                                        </div>
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
                </GridItem>
            </GridContainer>
        );
}
