import React from "react";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "components/Accordion/Accordion.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

//Custom stuff
import UserManager from "managers/UserManager";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: LearningView ";
const DEBUG = false;
const userManager = new UserManager();

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

export default function LearningView() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    React.useEffect(() => {
        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            }
        });
    }, []);

    if (adBlockDetected) {
        return (<SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="An Blocker is detected !"
            onConfirm={() => window.location.reload()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="I have disabled AdBlock"
        >
            This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
        </SweetAlert>);
    } else if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        const login = () => {
            history.push("/auth/login-page");
        };
        const goBack = () => {
            history.goBack();
        };

        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <GridContainer>
                <MetaTags>
                    <title>IPO Learning</title>
                    <meta name="description" content="Discover IPO (initial public offering), SPAC (Special Purpose Acquisition Company) and direct listing. Learning material, courses and books.
Learn about IPO investing. Learn about SPAC investing. Learn about IPO process." />
                    <meta property="og:title" content="IPO Learning" />
                </MetaTags>

                <GridItem xs={12}>
                    <AdsenseView slotType="homeTop" />
                    <Card>
                        <CardBody>
                            <Accordion
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>What is an IPO (Initial public offerings) ?</h3>,
                                        content:
                                            <div>
                                            <p className={classes.cardCategory}>Initial public offerings are milestones in the stock market calendar. An IPO is where a company gives the public an opportunity to buy shares in the company for the first time.</p>
                                            <p className={classes.cardCategory}>The IPO process consist of offering shares of a private corporation to the public in a new stock issuance.
                                                Public share issuance allows a company to raise capital from public investors. The transition from a private to a public company can be an important time for private investors to fully realize gains from their investment as it typically includes share premiums for current private investors.</p>
                                                <br />
                                                <br />
                                                <iframe title="What is an IPO" class="center" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="500" height="300" src="https://www.youtube.com/embed/l4HMCr5roAM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                
                                            </div>
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Accordion
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>Why do companies want to become listed?</h3>,
                                        content:
                                            <div>
                                                <h4 className={classes.cardCategory}><strong>1. They need to raise funds</strong></h4>
                                                <p className={classes.cardCategory}>As a company grows, it will usually need more money to fund their expansion, for example hiring new employees and purchasing equipment.</p>
                                                <p className={classes.cardCategory}>Companies have four main fundraising routes:</p>
                                                <ul className={classes.cardCategory}><li>Obtaining a loan from a bank</li><li>Borrowing from the debt markets by issuing corporate bonds to investors</li><li>Inviting private investors to contribute funds and receive some new shares</li><li>Raise funds via an initial public offering of new shares on a stock exchange</li></ul>
                                                <p className={classes.cardCategory}>Selling shares is preferable for larger companies which have already taken on an optimal level of debt. Debt is a useful way for companies to increase their return to shareholders, but the share price will begin to suffer if the debt burden becomes significant.</p>

                                                <h4 className={classes.cardCategory}><strong>2. Public companies obtain cheaper funding than their private counterparts</strong></h4>
                                                <p className={classes.cardCategory}>As we explained in the Science of Diversification, investors demand a higher return from a riskier investment. In practice, this means they are prepared to pay a higher price now to receive a stream of dividends in the future if they will be exposed to less risk.</p>
                                                <p className={classes.cardCategory}>Quoted shares are less risky than their private counterparts. This is because:</p>
                                                <ul className={classes.cardCategory}><li>Quoted shares are liquid &#8211; an investor can sell their shareholdings on the same day.</li><li>Executive managers tend to exercise better oversight over public companies. This is because regulations force them to comply with more stringent corporate governance requirements. This increases the focus on identifying business risks and monitoring conflicts of interest on the Board of Directors.</li><li>Public companies have higher quality financial reporting. Regulations require them to develop robust internal controls to detect fraud and error. External and Internal audits subject the financial statements to a rigorous series of checks, which increases the trust that shareholders place on them.</li></ul>
                                                <p className={classes.cardCategory}>As a result of this reduced risk, a public company is able to raise funds by selling fewer shares at a higher price per share, compared to an identical private company.</p>

                                                <h4 className={classes.cardCategory}><strong>3. Listing creates an exit opportunity</strong></h4>
                                                <p className={classes.cardCategory}>A common motivation to go public is to provide an easy way for founders and early investors to sell their shareholdings at the best price.</p>

                                                <p className={classes.cardCategory}>It usually takes five years or more for a company to meet all the conditions for a successful IPO. By this point, many different shareholders will sit on the shareholder register. Some of these parties will be eager to find a way to convert their successful investment into cash.</p>
                                                <p className={classes.cardCategory}>Early investors could include:</p>
                                                <ul className={classes.cardCategory}><li>The founders</li><li>Seed or angel investors (early-stage investors which help start-ups get off the ground)</li><li>Venture capital funds (later stage investors which can provide large cash injections while a start-up is loss-making).</li><li>Employees who have received shares</li><li>Banks which may have converted their loans into shares</li></ul>
                                                
                                            </div>
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Accordion
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>How does an IPO work?</h3>,
                                        content:
                                            <div>
                                                <h4 className={classes.cardCategory}><strong>Step 1: Building a team</strong></h4>
                                                <p className={classes.cardCategory}>The company brings several external experts on board. This is usually a selection of investment banks and an audit firm. Because IPOs are such rare events, a company will never have the expertise in-house to go it alone.</p>
                                                <p className={classes.cardCategory}>In addition, Investment banks offer a service called &#8216;underwriting&#8217;. This is where, for a fee, they promise to step in and purchase any remaining unsold shares if there is insufficient demand on the day of the IPO.</p>
                                                <h4 className={classes.cardCategory}><strong>Step 2: Creating a Prospectus</strong></h4>
                                                <p className={classes.cardCategory}>With the assistance of the investment bank, the company produces a &#8216;Prospectus&#8217;. This document is subject to many rules and requirements. It provides insights into the operations, strategy and future plans of the company.</p>

                                                <h4 className={classes.cardCategory}><strong>Step 3: Setting a price</strong></h4>
                                                <p className={classes.cardCategory}>Through dialogue with potential investors together with their own analyst research, the investment banks help the company decide on a fixed price or a price range for their shares.</p>
                                                <p className={classes.cardCategory}>Setting the right IPO price is crucial. A company won&#8217;t sell its shares if the market believes they are overpriced. Any subscriber that pays a premium over the true value will suffer an immediate loss when the shares begin trading at their lower, fairer value in secondary trading when markets open the following day.</p>
                                                <br/>
                                                <iframe title="Setting IPO price" class="center" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="500" height="300"  src="https://www.youtube.com/embed/ZakR9fUjEh8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                <br />
                                                <p className={classes.cardCategory}>Therefore, companies deliberately underprice their IPO to ensure their success. Sometimes the discount is as high as 25%. An underpriced share is likely to &#8216;pop&#8217; or rise on the first trading day as it aligns with its fair value. This price surge is a gift to all IPO subscribers. The prospect of a large &#8216;pop&#8217; in the share price on day one drivers much of the excitement around IPOs.</p>
                                                <h4 className={classes.cardCategory}><strong>Step 4: Creating demand</strong></h4>
                                                <p className={classes.cardCategory}>Behind the scenes and in the press, the investment banks use their network of contacts to drum up interest in the company ahead of the IPO date. The hype machine enters into full swing, while financial journalists begin speculating about whether the IPO will be oversubscribed.</p>
                                                <p className={classes.cardCategory}>If the bankers have chosen an appropriate price point, investors should not need too much convincing. However, if the stock market conditions become gloomy, or some negative press emerges during the IPO process, it can become a real struggle to keep the market consensus within the original price range announced.</p>
                                                <p className={classes.cardCategory}>Stockbrokers will contact their clients in the period leading up to the IPO, to offer them the opportunity to participate. Some stockbrokers email their client base about upcoming IPOs, but others will simply list them on the IPO section of their website. Beyond a stockbroker trading fee, no other fees are charged for an IPO.</p>

                                                <h4 className={classes.cardCategory}><strong>Step 5: Going Public</strong></h4>
                                                <p className={classes.cardCategory}>The IPO itself &#8211; the primary sale &#8211; occurs the evening before the stock market allows secondary trading in the shares. An IPO that is successfully marketed, will be heavily oversubscribed. With too few shares to satisfy demand, shares are allocated to subscribers using one of two allocation methods. Which method is used will depend on how the final IPO price was decided.</p>
                                                <ul className={classes.cardCategory}><li>Fixed price &#8211; Shares are allocated across all subscribers proportionately to the order size.</li><li>Price range &#8211; The book building method sees shares allocated first to the subscribers who placed orders at the top-end of the price range.</li></ul>
                                                <p className={classes.cardCategory}>Depending on the allocation method, investors either have an incentive to over-order, or increase their subscription price, if they want to increase their chances of receiving the desired number of shares.</p>
                                                <br />
                                                <iframe title="IPO process" class="center" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="500" height="300" src="https://www.youtube.com/embed/YagSUvKqkR4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                
                                            </div>
                                    }
                                ]}
                            />

                        </CardBody>
                    </Card>
                    <AdsenseView slotType="newsFeed" />
                    <Card>
                        <CardBody>
                            <Accordion
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>Investing in an IPO ?</h3>,
                                        content:
                                            <div>
                                                <p className={classes.cardCategory}>When a company decides to raise money via an IPO it is only after careful consideration and analysis that this particular exit strategy will maximize the returns of early investors and raise the most capital for the business. Therefore, when the IPO decision is reached, the prospects for future growth are likely to be high, and many public investors will line up to get their hands on some shares for the first time. IPOs are usually discounted to ensure sales, which makes them even more attractive, especially when they generate a lot of buyers from the primary issuance.</p>
                                                <p className={classes.cardCategory}>Initially, the price of the IPO is usually set by the underwriters through their pre-marketing process. At its core, the IPO price is based on the valuation of the company using fundamental techniques. The most common technique used is discounted cash flow, which is the net present value of the company &lsquo; s expected future cash flows.</p>
                                                <p className={classes.cardCategory}>Underwriters and interested investors look at this value on a per-share basis. Other methods that may be used for setting the price include equity value, enterprise value, comparable firm adjustments, and more. The underwriters do factor in demand but they also typically discount the price to ensure success on the IPO day.</p>
                                                <p className={classes.cardCategory}>It can be quite hard to analyze the fundamentals and technicals of an IPO issuance. Investors will watch news headlines but the main source for information should be the prospectus, which is available as soon as the company files its S-1 Registration. The prospectus provides a lot of useful information. Investors should pay special attention to the management team and their commentary as well as the quality of the underwriters and the specifics of the deal. Successful IPOs will typically be supported by big investment banks that have the ability to promote a new issue well.</p>
                                                <p className={classes.cardCategory}>Overall, the road to an IPO is a very long one. As such, public investors building interest can follow developing headlines and other information along the way to help supplement their assessment of the best and potential offering price.</p>
                                                <p className={classes.cardCategory}>The pre-marketing process typically includes demand from large private accredited investors and institutional investors, which heavily influence the IPO &lsquo; s trading on its opening day. Investors in the public don &lsquo; t become involved until the final offering day. All investors can participate but individual investors specifically must have trading access in place. The most common way for an individual investor to get shares is to have an account with a brokerage platform that itself has received an allocation and wishes to share it with its clients.</p>
                                                
                                            </div>
                                    }
                                ]}
                            />

                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Accordion
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>Performance of an IPO ?</h3>,
                                        content:
                                            <div>
                                                <p className={classes.cardCategory}>There are several factors that may affect the return from an IPO which is often closely watched by investors. Some IPOs may be overly-hyped by investment banks which can lead to initial losses. However, the majority of IPOs are known for gaining in short-term trading as they become introduced to the public. There are a few key considerations for IPO performance.</p>
                                                <h4 className={classes.cardCategory}><strong> Lock-Up </strong></h4>
                                                <p className={classes.cardCategory}>If you look at the charts following many IPOs, you&#39;ll notice that after a few months the stock takes a steep downturn. This is often because of the expiration of the lock-up period. When a company goes public, the underwriters make company insiders such as officials and employees sign a lock-up agreement.</p>
                                                <p className={classes.cardCategory}>Lock-up agreements are legally binding contracts between the underwriters and insiders of the company, prohibiting them from selling any shares of stock for a specified period of time. The period can range anywhere from three to 24 months. Ninety days is the minimum period stated under <a href="https://www.investopedia.com/terms/r/rule144.asp" data-component="link" data-source="inlineLink" data-type="internalLink" data-ordinal="1" rel="noopener noreferrer">Rule 144</a> (SEC law) but the lock-up specified by the underwriters can last much longer. The problem is, when lockups expire, all the insiders are permitted to sell their stock. The result is a rush of people trying to sell their stock to realize their profit. This excess supply can put severe downward pressure on the stock price.</p>
                                                <br />
                                                <iframe title="IPO lockup period" class="center" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="500" height="300" src="https://www.youtube.com/embed/1YpkTxRN104" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                <br />
                                                <h4 className={classes.cardCategory}><strong> Waiting Periods </strong></h4>
                                                <p className={classes.cardCategory}>Some investment banks include waiting periods in their offering terms. This sets aside some shares for purchase after a specific period of time. The price may increase if this allocation is bought by the underwriters and decrease if not.</p>
                                                <h4 className={classes.cardCategory}><strong> Flipping </strong></h4>
                                                <p className={classes.cardCategory}> Flipping is the practice of reselling an IPO stock in the first few days to earn a quick profit. It is common when the stock is discounted and soars on its first day of trading.</p>
                                                <h4 className={classes.cardCategory}><strong> Spin-offs </strong></h4>
                                                <p className={classes.cardCategory}>Closely related to a traditional IPO is when an existing company spins off a part of the business as its own standalone entity, creating tracking stocks. The rationale behind spin-offs and the creation of tracking stocks is that in some cases individual divisions of a company can be worth more separately than as a whole. For example, if a division has high growth potential but large current losses within an otherwise slowly growing company, it may be worthwhile to carve it out and keep the parent company as a large shareholder then let it raise additional capital from an IPO.</p>
                                                <p className={classes.cardCategory}>From an investor &lsquo; s perspective, these can be interesting IPO opportunities. In general, a spin-off of an existing company provides investors with a lot of information about the parent company and its stake in the divesting company. More information available for potential investors is usually better than less and so savvy investors may find good opportunities from this type of scenario. Spin-offs can usually experience less initial volatility because investors have more awareness.</p>
                                                <h4 className={classes.cardCategory}><strong> IPOs Over the Long-Term </strong></h4>
                                                <p className={classes.cardCategory}>IPOs are known for having volatile opening day returns that can attract investors looking to benefit from the discounts involved. Over the long-term, an IPO&#39;s price will settle into a steady value, which can be followed by traditional stock price metrics like moving averages. Investors who like the IPO opportunity but may not want to take the individual stock risk may look into managed funds focused on IPO universes.</p>
                                                
                                            </div>
                                    }
                                ]}
                            />

                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Accordion
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>What is a DPO (Direct Public Offering)?</h3>,
                                        content:
                                            <div>
                                                <p className={classes.cardCategory}>The major difference between a direct listing and an IPO is that one sells existing stocks while the other issues new stock shares. In a direct listing, employees and investors sell their existing stocks to the public. In an IPO, a company sells part of the company by issuing new stocks. The goal of companies that become public through a direct listing is not focused on raising additional capital, which is why new shares are not necessary.</p>
                                                <p className={classes.cardCategory}>The second difference is that in a direct listing there are no underwriters. Underwriters work for investment banks to help sell stocks of a company that is going public. They make large purchases which adds value to companies as those shares are taken off their hands. However, the shares are typically sold at a discount to their true value.</p>
                                                <br />
                                                <iframe title="What is direct listing" class="center" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="500" height="300" src="https://www.youtube.com/embed/SSGCw5Zi2-g" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                <br />
                                                <p className={classes.cardCategory}>The process of using underwriters and selling at a discount increases the time and cost for a company that is issuing new shares. The practice of investment banks buying stocks and then selling the stock themselves is not as common now. Instead, the investment banks will use their network to help market the stocks and drive sales.</p>
                                                <p className={classes.cardCategory}>Lastly, the direct listing process also does not have the &ldquo;lock-up&rdquo; period that applies to IPOs. In traditional IPOs, though not always required, companies have lock-up periods in which existing shareholders are not allowed to sell their shares in the public market. It prevents an overly large supply in the market that would decrease the price of the stock.</p>
                                                <p className={classes.cardCategory}>In direct listings, existing shareholders can sell their shares right when the company goes public. Since no new shares are issued, transactions will only occur if existing shareholders sell their shares.</p>
                                                
                                            </div>
                                    }
                                ]}
                            />
                            
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Accordion
                                collapses={[
                                    {
                                        title: <h3 className={classes.cardProductTitle}>What is a SPAC (Special Purpose Acquisition Company)?</h3>,
                                        content:
                                            <div>
                                                <p className={classes.cardCategory}>A special purpose acquisition company is a company with no commercial operations that is formed strictly to raise capital through an initial public offering for the purpose of acquiring an existing company. Also known as &#34;blank check companies,&#34; SPACs have been around for decades. In recent years, they&#39;ve gone mainstream, attracting big-name underwriters and investors and raising a record amount of IPO money in 2019. In 2020, more than 50 SPACs have been formed in the U.S., as of the beginning of August, raising some $21.5 billion.</p>
                                                <br />
                                                <GridContainer justify="center">
                                                    <GridItem xs={12} sm={12} md={5}>
                                                        <iframe title="Spacs bloomberg" width="500" height="300" src="https://www.youtube.com/embed/dSMrSUDwOk8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={5}>
                                                        <iframe title="Spacs yahoo finance" width="500" height="300" src="https://www.youtube.com/embed/18PrdpctM64" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                    </GridItem>
                                                </GridContainer>
                                                <br />
                                                <p className={classes.cardCategory}>SPACs are generally formed by investors, or sponsors, with expertise in a particular industry or business sector, with the intention of pursuing deals in that area. In creating a SPAC, the founders sometimes have at least one acquisition target in mind, but they don&#39;t identify that target to avoid extensive disclosures during the IPO process. (This is why they are called &#34;blank check companies.&#34; IPO investors have no idea what company they ultimately will be investing in.) SPACs seek underwriters and institutional investors before offering shares to the public.</p>
                                                <p className={classes.cardCategory}>The money SPACs raise in an IPO is placed in an interest-bearing trust account. These funds cannot be disbursed except to complete an acquisition or to return the money to investors if the SPAC is liquidated. A SPAC generally has two years to complete a deal or face liquidation. In some cases, some of the interest earned from the trust can be used as the SPAC&#39;s working capital. After an acquisition, a SPAC is usually listed on one of the major stock exchanges.</p>
                                            </div>
                                    }
                                ]}
                            />

                        </CardBody>
                    </Card>
                    <AdsenseView slotType="homeBottom" />
                </GridItem>
            </GridContainer>
        );
        return userConnectedLayout;
    }
}
