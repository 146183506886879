import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// custom spinner
import { css } from "@emotion/core";
import Spinner from "react-spinners/ScaleLoader";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardImage from "components/Card/CardImage.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import { successColor } from "assets/jss/material-dashboard-pro-react.js";

//Custom stuff
import StockLogo from "utilities/StockLogo";
import WatchlistManager from "managers/WatchlistManager";
import SearchChips from "utilities/SearchChips";

//Global variables
const TAG = "Ipo-Tracker: IpoHeaderCard ";
const DEBUG = false;

const useStyles = makeStyles(styles);
const watchlistManager = new WatchlistManager();
const WatchlistButtonStates = {
    HIDE: 1,
    ADD: 2,
    REMOVE: 3,
};
const override = css`
  display: block;
`;

async function addToWatchList(stockBuilder_, setwatchListButtonState_) {
    if (DEBUG) console.log(TAG + "addToWatchList called");
    setwatchListButtonState_(WatchlistButtonStates.HIDE);
    await watchlistManager.addToWatchlist(stockBuilder_);
    setwatchListButtonState_(WatchlistButtonStates.REMOVE);
}

async function removeFromWatchList(stockBuilder_, setwatchListButtonState_) {
    if (DEBUG) console.log(TAG + "removeFromWatchList called");
    setwatchListButtonState_(WatchlistButtonStates.HIDE);
    await watchlistManager.removeFromWatchlist(stockBuilder_);
    setwatchListButtonState_(WatchlistButtonStates.ADD);
}

export default function IpoHeader(props) {
    const {
        stockBuilder
    } = props;

    const classes = useStyles();
    const [ShowProfile, setShowProfile] = React.useState(false);
    const [watchListButtonState, setwatchListButtonState] = React.useState(WatchlistButtonStates.HIDE);

    React.useEffect(() => {
        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            let newWatchlistData = await watchlistManager.getWatchlistStocks();
            if (newWatchlistData && !newWatchlistData.length < 1) {
                //check if ipo is in watchlist
                for (let i = 0; i < newWatchlistData.length; i++) {
                    let stock_ = newWatchlistData[i];
                    if (stock_.getDealId() === stockBuilder.getDealId()) {
                        //show remove button
                        if (DEBUG) console.log(TAG + "fetchData found in watchlist");
                        setwatchListButtonState(WatchlistButtonStates.REMOVE);
                        return;
                    }
                }
            }
            //show add button
            if (DEBUG) console.log(TAG + "fetchData not found in watchlist");
            setwatchListButtonState(WatchlistButtonStates.ADD);
        } 
        fetchData();
    }, [stockBuilder, classes]);

    function createVariationItem(trendingItem) {
        let isIpoPrice = trendingItem.isUnknown(trendingItem.getLastPrice());
        let priceText1 = "";
        let priceText2 = "";
        let textColorClass = classes.cardTitle;
        if (isIpoPrice) {
            priceText1 = trendingItem.getPrice();
            priceText2 = "(Ipo price)";
        } else {
            priceText1 = trendingItem.getLastPrice();
            priceText2 = trendingItem.getVariation();
            if (priceText2.includes("-")) {
                textColorClass = classes.dangerText;
            } else if (priceText2.includes("+")) {
                textColorClass = classes.successText;
            }
        }
        if (trendingItem.isUnknown(priceText1)) {
            priceText1 = "";
            priceText2 = "";
        } else priceText1 = "$" + priceText1;
        if (trendingItem.isUnknown(priceText2)) priceText2 = "";
        return (
            <h3 className={classes.cardTitle}>
                {priceText1} &nbsp;
                <small>

                    <span className={textColorClass}>
                        {priceText2}
                    </span>{" "}

                </small>

            </h3>
        );
    }

    let watchListButton = (
        <Button justIcon simple className={classes.marginRight}>
            <Spinner className={classes.marginRight} css={override} size={10} color={successColor[0]} />
        </Button>
    );

    if (watchListButtonState === WatchlistButtonStates.ADD) {
        watchListButton = (
            <Button
                justIcon
                round
                color="success"
                className={classes.marginRight}
            >
                <PlaylistAddIcon className={classes.icons} style={{ margin: "auto" }}
                    onClick={e => {
                        e.preventDefault();
                        addToWatchList(stockBuilder, setwatchListButtonState);
                    }} />
            </Button>
        );

    } else if (watchListButtonState === WatchlistButtonStates.REMOVE) {
        watchListButton = (
            <Button
                justIcon
                round
                color="danger"
                className={classes.marginRight}
            >
                <DeleteForeverIcon className={classes.icons} style={{ margin: "auto" }}
                    onClick={e => {
                        e.preventDefault();
                        removeFromWatchList(stockBuilder, setwatchListButtonState);
                    }} />
            </Button>
        );
    }

    let profileButton = null;
    if (stockBuilder && !stockBuilder.isUnknown(stockBuilder.getDescription())) {
        profileButton = (
            <Button color="success" round size="sm" className={classes.marginRight} onClick={e => {
            e.preventDefault();
            setShowProfile(!ShowProfile);
        }}>
                Company Profile <UnfoldMoreIcon className={classes.icons} style={{ paddingLeft: "5px", margin: "auto" }} />
        </Button>);
    }

    let profileView = null;
    if (stockBuilder && ShowProfile) {
        try {
            let descriptionText = stockBuilder.getDescription();
            let descriptionFirst = descriptionText.slice(0, descriptionText.indexOf("\n"));
            let descriptionSecond = descriptionText.slice(descriptionText.indexOf("\n") + 1);
            profileView = (<CardBody>
                <p align="justify" className={classes.cardCategory} style={{ whiteSpace: 'pre-wrap' }}> <b> {descriptionFirst} </b> </p>
                <p align="justify" className={classes.cardCategory} style={{ whiteSpace: 'pre-wrap' }}> {descriptionSecond} </p>
            </CardBody>);
        } catch (err) {
            profileView = (<CardBody>
                <p align="justify" className={classes.cardCategory} style={{ whiteSpace: 'pre-wrap' }}> {stockBuilder.getDescription()} </p>
            </CardBody>);
        }
    }

    return (
        stockBuilder ?
            <Card profile>
                <CardHeader icon>
                    <CardImage color="transparent">
                        <StockLogo stock={stockBuilder} round={false} width="160" height="60" minWidth="140px" minHeight="140px" />
                    </CardImage>
                    <br />
                    <h1 className={classes.cardTitle}>{stockBuilder.getName()} {watchListButton} </h1>
                    {createVariationItem(stockBuilder)}
                    {profileButton}                    
                </CardHeader>
                {profileView}
                <CardFooter stats>
                    Last update: {!stockBuilder.isUnknown(stockBuilder.getLastUpdate()) ? stockBuilder.getLastUpdate() : stockBuilder.getDate()}
                    <SearchChips stock={stockBuilder}/>
                </CardFooter>
            </Card>
            : <div />
    );
}

IpoHeader.defaultProps = {
    stockBuilder: null

};
