import React from "react";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import FlagIcon from "@material-ui/icons/EmojiFlags";
import WinnerCup from "@material-ui/icons/EmojiEvents";
import StarsIcon from '@material-ui/icons/Stars';
import FlashOnIcon from '@material-ui/icons/FlashOn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Accordion from "components/Accordion/Accordion.js";

//Custom stuff
import UserManager from "managers/UserManager";
import TopPerformersManager from "managers/TopPerformersManager";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: TopPerformersView ";
const DEBUG = false;
const topPerformersManager = new TopPerformersManager();
const userManager = new UserManager();
const showLoadingBar = <LinearProgress />;
const defaultTableHeader = ["#", "", "Name", "Symbol", "Price" , "Variation" , "Date"];
const emptyTableHeader = ["No data to be displayed"];
const emptyTableContent = [[""]];
const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};
const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

export default function RegularTables() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();
    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [tableHeaderThisMonth, setTableHeaderThisMonth] = React.useState(defaultTableHeader);
    const [tableHeaderLastMonth, setTableHeaderLastMonth] = React.useState(defaultTableHeader);
    const [tableHeaderThisYear, setTableHeaderThisYear] = React.useState(defaultTableHeader);
    const [tableHeaderAllTime, setTableHeaderAllTime] = React.useState(defaultTableHeader);
    const [thisMonthData, setThisMonthData] = React.useState([]);
    const [lastMonthData, setLastMonthData] = React.useState([]);
    const [thisYearData, setThisYearData] = React.useState([]);
    const [allTimeData, setAllTimeData] = React.useState([]);
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    React.useEffect(() => {
        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            setLoadingBar(showLoadingBar);
            let newThisMonthData = await topPerformersManager.getThisMonthPerformers(history);
            if (newThisMonthData && !newThisMonthData.length < 1) {
                setTableHeaderThisMonth(defaultTableHeader);
                await setThisMonthData(newThisMonthData);
            } else {
                setTableHeaderThisMonth(emptyTableHeader);
                setThisMonthData(emptyTableContent);
            }

            let newLastMonthData = await topPerformersManager.getLastMonthPerformers(history);
            if (newLastMonthData && !newLastMonthData.length < 1) {
                setTableHeaderLastMonth(defaultTableHeader);
                await setLastMonthData(newLastMonthData);
            } else {
                setTableHeaderLastMonth(emptyTableHeader);
                setLastMonthData(emptyTableContent);
            }

            let newthisYearData = await topPerformersManager.getThisYearPerformers(history);
            if (newthisYearData && !newthisYearData.length < 1) {
                setTableHeaderThisYear(defaultTableHeader);
                await setThisYearData(newthisYearData);
            } else {
                setTableHeaderThisYear(emptyTableHeader);
                setThisYearData(emptyTableContent);
            }

            let newAllTimeData = await topPerformersManager.getAllTimePerformers(history);
            if (newAllTimeData && !newAllTimeData.length < 1) {
                setTableHeaderAllTime(defaultTableHeader);
                await setAllTimeData(newAllTimeData);
            } else {
                setTableHeaderAllTime(emptyTableHeader);
                setAllTimeData(emptyTableContent);
            }

            setLoadingBar();
        }

        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            } else {
                if (userManager.isConnected()) {
                    fetchData();
                }
            }
        });
    }, [history]);

    if (adBlockDetected) {
        return (<SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="An Blocker is detected !"
            onConfirm={() => window.location.reload()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="I have disabled AdBlock"
        >
            This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
        </SweetAlert>);
    } else if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        const login = () => {
            history.push("/auth/login-page");
        };
        const goBack = () => {
            history.goBack();
        };
        
        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <div>
                <AdsenseView slotType="homeTop"/>
                <GridContainer>
                <MetaTags>
                    <title>Top performers</title>
                    <meta name="description" content="Discover this month's, this year's and all time top performing IPO. Compare track and analyse IPO performance." />
                    <meta property="og:title" content="Top performing IPO" />
                </MetaTags>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <Accordion
                                active={0}
                                collapses={[
                                    {
                                        title:
                                            <CardHeader color="info" icon justify="center" style={{ minWidth: "400px" }} >
                                                <CardIcon color="info">
                                                    <FlagIcon />
                                                </CardIcon>
                                                <h4 className={classes.cardIconTitle}>Top performers this month</h4>
                                            </CardHeader>
                                        , content:
                                            <Card>
                                                <CardBody style={{}}>
                                                    {loadingBar}
                                                    <Table
                                                        striped
                                                        hover
                                                        tableHeaderColor="primary"
                                                        tableHead={tableHeaderThisMonth}
                                                        tableData={thisMonthData}
                                                        coloredColls={[6]}
                                                        colorsColls={["primary"]}
                                                    />
                                                </CardBody>
                                            </Card>
                                    }, {
                                        title:
                                            <CardHeader color="success" icon justify="center" style={{ minWidth: "400px" }} >
                                                <CardIcon color="success">
                                                    <WinnerCup />
                                                </CardIcon>
                                                <h4 className={classes.cardIconTitle}>Top performers last month</h4>
                                            </CardHeader>
                                        , content:
                                            <Card>
                                                <CardBody>
                                                    {loadingBar}
                                                    <Table
                                                        striped
                                                        hover
                                                        tableHeaderColor="primary"
                                                        tableHead={tableHeaderLastMonth}
                                                        tableData={lastMonthData}
                                                        coloredColls={[6]}
                                                        colorsColls={["primary"]}
                                                    />
                                                </CardBody>
                                            </Card>
                                    },
                                    {
                                        title:
                                            <CardHeader color="warning" icon justify="center" style={{ minWidth: "400px" }} >
                                                <CardIcon color="warning">
                                                    <StarsIcon />
                                                </CardIcon>
                                                <h4 className={classes.cardIconTitle}>Top performers this year</h4>
                                            </CardHeader>
                                        , content:
                                            <Card>
                                                <CardBody>
                                                    {loadingBar}
                                                    <Table
                                                        striped
                                                        hover
                                                        tableHeaderColor="primary"
                                                        tableHead={tableHeaderThisYear}
                                                        tableData={thisYearData}
                                                        coloredColls={[6]}
                                                        colorsColls={["primary"]}
                                                    />
                                                </CardBody>
                                            </Card>
                                    },
                                    {
                                        title:
                                            <CardHeader color="rose" icon justify="center" style={{ minWidth: "400px" }} >
                                                <CardIcon color="rose">
                                                    <FlashOnIcon />
                                                </CardIcon>
                                                <h4 className={classes.cardIconTitle}>Top performers since 2013</h4>
                                            </CardHeader>
                                        , content:
                                            <Card>
                                                <CardBody>
                                                    {loadingBar}
                                                    <Table
                                                        striped
                                                        hover
                                                        tableHeaderColor="primary"
                                                        tableHead={tableHeaderAllTime}
                                                        tableData={allTimeData}
                                                        coloredColls={[6]}
                                                        colorsColls={["primary"]}
                                                    />
                                                </CardBody>
                                            </Card>
                                    }]} />
                        </CardBody>
                    </Card>,
                </GridItem>
                </GridContainer>
                <AdsenseView slotType="homeBottom" />
            </div>
        );
        return userConnectedLayout;
    }
}
