import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import FireBaseManager from "managers/FireBaseManager";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

const hist = createBrowserHistory();
const firebaseManager = new FireBaseManager();
firebaseManager.initialize().then(() => {

    ReactDOM.render(
        <Router history={hist}>
            <Switch>
                <Route path="/auth" component={AuthLayout} />
                <Route path="/u" component={AdminLayout} />
                <Redirect from="/mobile" to="/mobile" />
                <Redirect from="/" to="/u/home" />
                <Redirect from="/index.html" to="/u/home" />
            </Switch>
        </Router>,
        document.getElementById("root")
    );
});
