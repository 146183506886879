import React from "react";
import FireBaseManager from "managers/FireBaseManager";
import UserManager from "managers/UserManager";
import StockLogo from "utilities/StockLogo";

const TAG = "Ipo-Tracker: TopPerformersManager ";
const DEBUG = false;

function TopPerformersManager(classes) {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.userManager = new UserManager();
    this.lastAuthenticationState = null;
}

TopPerformersManager.prototype.getThisMonthPerformers = async function (history_) {
    if (DEBUG) console.log(TAG + "getThisMonthPerformers called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.thisMonthList) {
        this.thisMonthList = [];
        await this.firebaseManager.getTopPerformers("thisMonth").then(async (stockList) => {
            if (!stockList) {
                console.log(TAG + "Unable to get this month top performers list");
                return null;
            }
            let sortedStockList_ = sortStocksByVariation(stockList);
            let rank = 0;
            for (var stock_ of sortedStockList_) {
                rank++;
                this.thisMonthList.push(createStockLine(rank, stock_, history_));
            }

        }).catch(e => {
            console.error(TAG + "getThisMonthPerformers Catched exeption: " + e);
        });
    }
    return this.thisMonthList;
}

TopPerformersManager.prototype.getLastMonthPerformers = async function (history_) {
    if (DEBUG) console.log(TAG + "getLastMonthPerformers called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.lastMonthList) {
        this.lastMonthList = [];
        await this.firebaseManager.getTopPerformers("lastMonth").then(async (stockList) => {
            if (!stockList) {
                console.log(TAG + "Unable to get last month top performers list");
                return null;
            }
            let sortedStockList_ = sortStocksByVariation(stockList);
            let rank = 0;
            for (var stock_ of sortedStockList_) {
                rank++;
                this.lastMonthList.push(createStockLine(rank, stock_, history_));
            }

        }).catch(e => {
            console.error(TAG + "getLastMonthPerformers Catched exeption: " + e);
        });
    }
    return this.lastMonthList;
}

TopPerformersManager.prototype.getThisYearPerformers = async function (history_) {
    if (DEBUG) console.log(TAG + "getThisYearPerformers called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.thisYearList) {
        this.thisYearList = [];
        await this.firebaseManager.getTopPerformers("thisYear").then(async (stockList) => {
            if (!stockList) {
                console.log(TAG + "Unable to get this year top performers list");
                return null;
            }
            let sortedStockList_ = sortStocksByVariation(stockList);
            let rank = 0;
            for (var stock_ of sortedStockList_) {
                rank++;
                this.thisYearList.push(createStockLine(rank, stock_, history_));
            }

        }).catch(e => {
            console.error(TAG + "getThisYearPerformers Catched exeption: " + e);
        });
    }
    return this.thisYearList;
}

TopPerformersManager.prototype.getAllTimePerformers = async function (history_) {
    if (DEBUG) console.log(TAG + "getAllTimePerformers called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.allTimeList) {
        this.allTimeList = [];
        await this.firebaseManager.getTopPerformers("allTime").then(async (stockList) => {
            if (!stockList) {
                console.log(TAG + "Unable to get all time top performers list");
                return null;
            }
            let sortedStockList_ = sortStocksByVariation(stockList);
            let rank = 0;
            for (var stock_ of sortedStockList_) {
                rank++;
                this.allTimeList.push(createStockLine(rank, stock_, history_));
            }

        }).catch(e => {
            console.error(TAG + "getAllTimePerformers Catched exeption: " + e);
        });
    }
    return this.allTimeList;
}

TopPerformersManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.thisMonthList = null;
    this.lastMonthList = null;
    this.thisYearList = null;
    this.allTimeList = null;
}

function sortStocksByVariation(stockList_) {
    let stockListCleaned = [];
    for (var stock_ of stockList_) {
        if (stock_.getVariation()) stockListCleaned.push(stock_);
    }
    let sortedStocksList = stockListCleaned.sort(function compareVariations(stock1_, stock2_) {
        let var1 = stock1_.getVariation();
        let var2 = stock2_.getVariation();
        if (!var1 || !var2) return 0;
        let floatVar1 = parseFloat(var1);
        let floatVar2 = parseFloat(var2);
        if (!floatVar1 || !floatVar2) return 0;
        return Math.fround(floatVar2) - Math.fround(floatVar1);
    });
    return sortedStocksList;
}

function createStockLine(rank_, stock_, history_ ) {
    if (!stock_) return [];
    let variationText = stock_.isUnknown(stock_.getLastPrice()) ? "Ipo price" : stock_.getVariation();
    let textColor = "";
    if (variationText.includes("-")) {
        textColor = "red";
    } else if (variationText.includes("+")) {
        textColor = "green";
    }
    let variationLine = (<span style={{ color: textColor }}> {variationText} </span>);

    return [
        rank_,
        <StockLogo stock={stock_} round={false} width="40" height="20" minWidth="20px" minHeight="20px" />,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_); }} style={{ color: "black" }}> {stock_.getName()} </a> </p>,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_); }} style={{ color: "black" }}> {stock_.getSymbol()} </a> </p>,
        stock_.isUnknown(stock_.getLastPrice()) ? stock_.getPrice() : stock_.getLastPrice(),
        variationLine,
        stock_.getDate()
    ];
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.userManager.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userManager.userInfo) return;
    let currentUserUid = context_.userManager.getFirebaseUser().uid;
    let lastUserUid = context_.userManager.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}

function navigateToStockInfo(stockBuilder_, history_) {
    if (!stockBuilder_ || !history_) return;
    if (stockBuilder_.isUnknown(stockBuilder_.getDealId())) return;
    history_.push("/u/ipo-info?id=" + stockBuilder_.getDealId());
}

export default TopPerformersManager;