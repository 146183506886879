import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import UserBuilder from 'builders/UserBuilder';

const TAG = "Ipo-Tracker: FireBaseUserManager ";
const DEBUG = false;

function FireBaseUserManager() {
    if (DEBUG) console.log(TAG + "Instance created");
}

FireBaseUserManager.prototype.loginCrawlerAccount = async function () {

    if (DEBUG) console.log(TAG + "loginCrawlerAccount called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return "Internal Error";
    }
    await this.addAthenticationListener();
    const CrawlerEmail = "fake.amanallah.one@gmail.com";
    const CrawlerPassword = "googleBot2021";
    return firebase.auth().signInWithEmailAndPassword(CrawlerEmail, CrawlerPassword)
        .then((userCredential) => {
            if (DEBUG) console.log(TAG + "loginCrawlerAccount success");
            return "Crawler logged In";
        })
        .catch((error) => {
            if (DEBUG) console.log(TAG + "loginCrawlerAccount catched exception " + error.message);
            return error.message;
        });
}

FireBaseUserManager.prototype.authenticateAnonymous = async function () {

    if (DEBUG) console.log(TAG + "authenticateAnonymous called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return "Internal Error";
    }
    await this.addAthenticationListener();
    return firebase.auth().signInAnonymously().then(async function (result) {
        if (result && result.user) return null;
        if (DEBUG) console.log(TAG + "authenticateAnonymous unable to get user info");
        return "Unable to get user info";
    }).catch(function (error) {
        if (DEBUG) console.log(TAG + "authenticateAnonymous catched exception " + error.message);
        return error.message;
    });
}

FireBaseUserManager.prototype.authenticateGoogle = async function () {

    if (DEBUG) console.log(TAG + "authenticateGoogle called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return "Internal Error";
    }
    await this.addAthenticationListener();
    var provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider).then(async function (result) {
        if (result && result.user) return null;
        if (DEBUG) console.log(TAG + "authenticateGoogle unable to get user info");
        return "Unable to get user info";
    }).catch(function (error) {
        if (DEBUG) console.log(TAG + "authenticateGoogle catched exception " + error.message);
        return error.message;
    });
}

FireBaseUserManager.prototype.authenticateFacebook = async function () {
    if (DEBUG) console.log(TAG + "authenticateFacebook called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return "Internal Error";
    }
    await this.addAthenticationListener();
    var provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider).then(async function (result) {
        if (result && result.user) return null;
        if (DEBUG) console.log(TAG + "authenticateFacebook unable to get user info");
        return "Unable to get user info";
    }).catch(function (error) {
        if (DEBUG) console.log(TAG + "authenticateFacebook catched exception " + error.message);
        return error.message;
    });
}

FireBaseUserManager.prototype.authenticateTwitter = async function () {
    if (DEBUG) console.log(TAG + "authenticateTwitter called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return "Internal Error";
    }
    await this.addAthenticationListener();
    var provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider).then(async function (result) {
        if (result && result.user) return null;
        if (DEBUG) console.log(TAG + "authenticateTwitter unable to get user info");
        return "Unable to get user info";
    }).catch(function (error) {
        if (DEBUG) console.log(TAG + "authenticateTwitter catched exception " + error.message);
        return error.message;
    });
}

FireBaseUserManager.prototype.signOut = async function () {
    if (DEBUG) console.log(TAG + "signOut called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    return firebase.auth().signOut().then(function () {
        if (DEBUG) console.log(TAG + "signOut success");
        return true;
    }).catch(function (error) {
        if (DEBUG) console.log(TAG + "signOut catched exception " + error.message);
        return false;
    });
}

FireBaseUserManager.prototype.getUserInfo = function () {
    if (DEBUG) console.log(TAG + "getUserInfo called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return null;
    }
    return firebase.auth().currentUser;
}

FireBaseUserManager.prototype.getUserExtraInfo = async function () {
    if (DEBUG) console.log(TAG + "getUserExtraInfo called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return null;
    }
    if (!firebase.auth().currentUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return null;
    }
    const db = firebase.firestore();
    try {
        const doc = await db.collection('users').doc(firebase.auth().currentUser.uid).get();
        let userInfo = new UserBuilder(
            doc.get("id"),
            doc.get("name"),
            doc.get("mail"),
            doc.get("pseudo"),
            doc.get("inscription"),
            doc.get("last"),
            firebase.auth().currentUser.photoURL,
            doc.get("watchlist"));

        return userInfo;
    } catch (e) {
        console.error(TAG + "error: " + e);
        return null;
    }
}

FireBaseUserManager.prototype.addNewUser = async function () {
    if (DEBUG) console.log(TAG + "addNewUser called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    let firebaseUser = firebase.auth().currentUser;
    if (!firebaseUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return;
    }
    try {
        let userInfo = new UserBuilder(
            firebaseUser.uid,
            firebaseUser.displayName ? firebaseUser.displayName : firebaseUser.Name,
            firebaseUser.email,
            firebaseUser.displayName ? firebaseUser.displayName : firebaseUser.Name,
            getTodayDate(),
            getTodayDate(),
            firebaseUser.photoURL,
            null);
        const db = firebase.firestore();
        const docRef = db.collection('users').doc(userInfo.getId());
        await docRef.set({
            id: userInfo.getId(),
            inscription: userInfo.getInscription(),
            last: userInfo.getLast(),
            mail: userInfo.getMail(),
            name: userInfo.getName(),
            pseudo: userInfo.getPseudo()
        }, { merge: true }).then((res) => {
        if (DEBUG) console.log(TAG + "User added to : users/" + userInfo.getId());
        if (DEBUG) console.log(TAG + "Result : " + JSON.stringify(res));
        }).catch(e => {
            console.error(TAG + "Catched exeption: " + e);
        });

    } catch (e) {
        console.error(TAG + "error: " + e);
    }
}

FireBaseUserManager.prototype.updateLastLogin = async function () {
    if (DEBUG) console.log(TAG + "updateLastLogin called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    let firebaseUser = firebase.auth().currentUser;
    if (!firebaseUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return;
    }
    try {
        const db = firebase.firestore();
        const docRef = db.collection('users').doc(firebaseUser.uid);
        let newDate = getTodayDate();
        await docRef.update({
            last: newDate
        }).then((res) => {
            if (DEBUG) console.log(TAG + "Last login info updated to : users/" + firebaseUser.uid);
            if (DEBUG) console.log(TAG + "Result : " + JSON.stringify(res));
        }).catch(e => {
            console.error(TAG + "Catched exeption: " + e);
        });

    } catch (e) {
        console.error(TAG + "error: " + e);
    }
}

FireBaseUserManager.prototype.updatePseudo = async function (newPseudo_) {
    if (DEBUG) console.log(TAG + "updatePseudo called with " + newPseudo_);
    if (!newPseudo_) {
        if (DEBUG) console.log(TAG + "Invalid new pseudo");
        return;
    }
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    let firebaseUser = firebase.auth().currentUser;
    if (!firebaseUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return;
    }
    try {
        const db = firebase.firestore();
        const docRef = db.collection('users').doc(firebaseUser.uid);
        await docRef.update({
            pseudo: newPseudo_
        }).then((res) => {
            if (DEBUG) console.log(TAG + "updatePseudo updated for : users/" + firebaseUser.uid);
            if (DEBUG) console.log(TAG + "Result : " + JSON.stringify(res));
        }).catch(e => {
            console.error(TAG + "Catched exeption: " + e);
        });

    } catch (e) {
        console.error(TAG + "error: " + e);
    }
}

FireBaseUserManager.prototype.syncUserInfo = async function () {
    if (DEBUG) console.log(TAG + "syncUserInfo called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    //Check if user exist
    let userInfo = await this.getUserExtraInfo();
    if (userInfo && !userInfo.isUnknown()) {
        //User exist, update last login
        await this.updateLastLogin();
    } else {
        //New user add to database
        await this.addNewUser();
    }
}

FireBaseUserManager.prototype.addAthenticationListener = async function () {
    if (DEBUG) console.log(TAG + "addAthenticationListener");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    try {
        await firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                if (DEBUG) console.log(TAG + "AthenticationListener user connected " + user.displayName);
                this.syncUserInfo();
            } else {
                if (DEBUG) console.log(TAG + "AthenticationListener user disconnected");
            }
        });
    } catch (e) {
        if (DEBUG) console.log(TAG + "addAthenticationListener catched exception " + e);
        return;
    }
}

FireBaseUserManager.prototype.deleteUser = async function () {
    if (DEBUG) console.log(TAG + "deleteUser called");
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    let firebaseUser = firebase.auth().currentUser;
    if (!firebaseUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return;
    }
    try {
        const db = firebase.firestore();
        const docRef = db.collection('users').doc(firebaseUser.uid);
        
        await docRef.delete().then(async (res) => {
            if (DEBUG) console.log(TAG + "User info deleted from : users/" + firebaseUser.uid);
            if (DEBUG) console.log(TAG + "Result : " + JSON.stringify(res));
            await firebase.auth().currentUser.delete();
        }).catch(e => {
            console.error(TAG + "Catched exeption: " + e);
        });

    } catch (e) {
        console.error(TAG + "error: " + e);
    }
}

FireBaseUserManager.prototype.addToWatchlist = async function (stock_) {
    if (!stock_ || stock_.isUnknown(stock_.getName())) {
        if (DEBUG) console.log(TAG + "Invalid new stock");
        return;
    }
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    if (DEBUG) console.log(TAG + "addToWatchlist called for " + stock_.getName());
    let firebaseUser = firebase.auth().currentUser;
    if (!firebaseUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return;
    }
    try {
        const db = firebase.firestore();
        let watchlistUpdate = firebase.firestore.FieldValue.arrayUnion(stock_.getName());
        if (!watchlistUpdate) return;
        const docRef = db.collection('users').doc(firebaseUser.uid);
        await docRef.update({
            watchlist: watchlistUpdate
        }).then((res) => {
            if (DEBUG) console.log(TAG + "addToWatchlist updated for : users/" + firebaseUser.uid);
            if (DEBUG) console.log(TAG + "Result : " + JSON.stringify(res));
        }).catch(e => {
            console.error(TAG + "Catched exeption: " + e);
        });

    } catch (e) {
        console.error(TAG + "error: " + e);
    }
}

FireBaseUserManager.prototype.removeFromWatchlist = async function (stock_) {
    if (!stock_ || stock_.isUnknown(stock_.getName())) {
        if (DEBUG) console.log(TAG + "Invalid new stock");
        return;
    }
    if (firebase.apps.length === 0) {
        if (DEBUG) console.log(TAG + "Firebase not initialized yet");
        return;
    }
    if (DEBUG) console.log(TAG + "removeFromWatchlist called for " + stock_.getName());
    let firebaseUser = firebase.auth().currentUser;
    if (!firebaseUser) {
        if (DEBUG) console.log(TAG + "user Not connected");
        return;
    }
    try {
        const db = firebase.firestore();
        let watchlistUpdate = firebase.firestore.FieldValue.arrayRemove(stock_.getName());
        const docRef = db.collection('users').doc(firebaseUser.uid);
        await docRef.update({
            watchlist: watchlistUpdate
        }).then((res) => {
            if (DEBUG) console.log(TAG + "removeFromWatchlist updated for : users/" + firebaseUser.uid);
            if (DEBUG) console.log(TAG + "Result : " + JSON.stringify(res));
        }).catch(e => {
            console.error(TAG + "Catched exeption: " + e);
        });

    } catch (e) {
        console.error(TAG + "error: " + e);
    }
}

FireBaseUserManager.prototype.checkIfAllowedCrawler = function () {
    const robots = new RegExp([
        /bot/, /spider/, /crawl/,                            // GENERAL TERMS
        /APIs-Google/, /AdsBot/, /Googlebot/,                // GOOGLE ROBOTS
        /mediapartners/, /Google Favicon/,
        /FeedFetcher/, /Google-Read-Aloud/,
        /DuplexWeb-Google/, /googleweblight/,
        /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/,        // OTHER ENGINES
        /ecosia/, /ia_archiver/,
        /facebook/, /instagram/, /pinterest/, /reddit/,       // SOCIAL MEDIA
        /slack/, /twitter/, /whatsapp/, /youtube/,
        /semrush/,                                         // OTHER
    ].map((r) => r.source).join("|"), "i");               // BUILD REGEXP + "i" FLAG
    const userAgent = navigator.userAgent;
    return robots.test(userAgent);
}

function getTodayDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
}

export default FireBaseUserManager;