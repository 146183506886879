import React from "react";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import PricedIcon from '@material-ui/icons/AlarmOn';
import ExpectedIcon from '@material-ui/icons/Snooze';
import FiledIcon from '@material-ui/icons/AddAlarm';
import WithdrawnIcon from '@material-ui/icons/AlarmOff';
import ErrorIcon from '@material-ui/icons/Warning';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Info from "components/Typography/Info.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardImage from "components/Card/CardImage.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from "components/Snackbar/Snackbar.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

//Custom stuff
import TrendingManager from "managers/TrendingManager";
import { NewsProviders } from "managers/NewsManager";
import CalendarManager from "managers/CalendarManager";
import StockLogo from "utilities/StockLogo";
import NewsView from "utilities/NewsViewComponent";
import AdsenseView from 'utilities/AdsenseView';
import playstoreBanner from "assets/img/playstore_banner.png";

//Global variables
const TAG = "Ipo-Tracker: HomeView ";
const DEBUG = false;
const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.ingenuity.ipotracker";
const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);
const showLoadingBar = <LinearProgress />;
const emptyContent = <div></div>;
const defaultTableHeader = ["", "Symbol", "Name", "Price", "Variation", "Date"];
const emptyTableHeader = ["No data to be displayed"];
const emptyTableContent = [[""]];

const trendingManager = new TrendingManager();
const calendarManager = new CalendarManager();

function getStatusLogo(status_) {
    if (!status_ || status_.length < 1) return PricedIcon;
    switch (status_.toLowerCase()) {
        case "priced":
            return (<Success> <PricedIcon /></Success>);
        case "expected":
            return (<Info> <ExpectedIcon /></Info>);
        case "filed":
            return (<Warning> <FiledIcon /></Warning>);
        case "withdrawn":
            return (<Danger> <WithdrawnIcon /> </Danger>);
        default:
            return (<Success> <PricedIcon /></Success>);
    }
}

function openPlayStore() {
    var win = window.open(PLAY_STORE_URL, '_blank');
    win.focus();
}

export default function Home() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const [trendingloadingBar, setTrendingLoadingBar] = React.useState(showLoadingBar);
    const [trendingListItems, setTrendingListItems] = React.useState([]);
    const [CalendarLoadingBar, setCalendarLoadingBar] = React.useState(showLoadingBar);
    const [showCalendarTables, setShowCalendarTables] = React.useState(false);
    const [tableHeaderPriced, setTableHeaderPriced] = React.useState(defaultTableHeader);
    const [tableHeaderExpected, setTableHeaderExpected] = React.useState(defaultTableHeader);
    const [pricedData, setPricedData] = React.useState([]);
    const [expectedData, setExpectedData] = React.useState([]);
    const [snackVisible, setSnackVisible] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState("Something Went Wrong");
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    const history = useHistory();

    React.useEffect(() => {

        function displaySnackBar() {
            setSnackMessage("Our servers reached maximum capacity, services will be restored soon");
            setSnackVisible(true);
        }

        function navigateToStockInfo(trendingitem_) {
            history.push("/u/ipo-info?id=" + trendingitem_.getDealId());
        }

        function createVariationItem(trendingItem) {
            let isIpoPrice = trendingItem.isUnknown(trendingItem.getLastPrice());
            let priceText1 = "";
            let priceText2 = "";
            let textColorClass = classes.cardTitle;
            if (isIpoPrice) {
                priceText1 = trendingItem.getPrice();
                if (trendingItem.isUnknown(priceText1)) {
                    priceText2 = "";
                } else {
                    priceText2 = "(Ipo price)";
                }
            } else {
                priceText1 = trendingItem.getLastPrice();
                priceText2 = trendingItem.getVariation();
                if (priceText2.includes("-")) {
                    textColorClass = classes.dangerText;
                } else if (priceText2.includes("+")) {
                    textColorClass = classes.successText;
                }
            }
            if (trendingItem.isUnknown(priceText1)) priceText1 = "";
            else priceText1 = "$" + priceText1;
            if (trendingItem.isUnknown(priceText2)) priceText2 = "";
            return (
                <h3 className={classes.cardTitle}>
                    {priceText1} &nbsp;
                <small>
                        
                        <span className={textColorClass}>
                            {priceText2}
                        </span>{" "}
                     
                </small>

                </h3>
            );
        }

        function createTrendingItem(trendingitem_) {
            return (
                <GridItem xs={12} sm={6} md={6} lg={3} key={trendingitem_.getName()}>
                    <Card >
                        <CardHeader stats icon>
                            <CardImage color="transparent">
                                <StockLogo stock={trendingitem_} round={false} width="80" height="40" minWidth="80px" minHeight="80px" />
                            </CardImage>
                            
                            <p className={classes.cardCategory}>
                                <a href="/#" className={classes.cardCategory}
                                    onClick={e => {
                                    e.preventDefault();
                                    navigateToStockInfo(trendingitem_);
                                }}>
                                    {trendingitem_.getName()}
                                </a>
                            </p>
                            
                            {createVariationItem(trendingitem_)}
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                {getStatusLogo(trendingitem_.getStatus())}
                                {trendingitem_.getDate()}    
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            );
        }

        function updateTrendingView(trendingList_) {
            let trendingListItems_ = emptyContent;
            if (trendingList_ && trendingList_.length >= 1) {
                trendingListItems_ = [];
                for (let i = 0; i < trendingList_.length; i++) {
                    let trendingitem_ = trendingList_[i];
                    trendingListItems_.push(createTrendingItem(trendingitem_));
                }
            }
            setTrendingListItems(trendingListItems_);
        }

        async function fetchTrending() {
            if (DEBUG) console.log(TAG + "fetchTrending called");
            setTrendingLoadingBar(showLoadingBar);
            let newTrendingData = await trendingManager.getHomeTrending(8);
            if (newTrendingData && !newTrendingData.length < 1) {
                updateTrendingView(newTrendingData);
            } else {
                updateTrendingView([]);
            }
            setTrendingLoadingBar();
        }

        async function fetchHomeCalendar() {
            if (DEBUG) console.log(TAG + "fetchHomeCalendar called");
            setCalendarLoadingBar(showLoadingBar);
            setShowCalendarTables(false);
            let newPricedData = await calendarManager.getLatestList();
            if (newPricedData && !newPricedData.length < 1) {
                if (DEBUG) console.log(TAG + "fetchHomeCalendar found latest");
                setTableHeaderPriced(defaultTableHeader);
                await setPricedData(newPricedData);
            } else {
                if (DEBUG) console.log(TAG + "fetchHomeCalendar not found latest");
                setTableHeaderPriced(emptyTableHeader);
                setPricedData(emptyTableContent);
            }

            let newExpectedData = await calendarManager.getUpcomingList();
            if (newExpectedData && !newExpectedData.length < 1) {
                if (DEBUG) console.log(TAG + "fetchHomeCalendar found upcoming");
                setTableHeaderExpected(defaultTableHeader);
                await setExpectedData(newExpectedData);
            } else {
                setTableHeaderExpected(emptyTableHeader);
                setExpectedData(emptyTableContent);
            }
            if (DEBUG) console.log(TAG + "fetchHomeCalendar returned");
            setShowCalendarTables(true);
            setCalendarLoadingBar();
        }

        async function fetchData() {
            await fetchTrending();
            await fetchHomeCalendar();
            let isQuotaExceeded = await calendarManager.isQuotaExceeded();
            if(DEBUG) console.log(TAG + "fetchHomeCalendar isQuotaExceeded: " + isQuotaExceeded);
            if (isQuotaExceeded) {
                displaySnackBar();
            }
        }

        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            } else {
                fetchData();
            }
        });
        

    }, [classes, history]);

    return (
        adBlockDetected?
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="An Blocker is detected !"
                onConfirm={() => window.location.reload()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="I have disabled AdBlock"
            >
                This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
            </SweetAlert>
            :
            <div>
            <MetaTags>
                <title>IPO Tracker</title>
                <meta name="description" content="Ipo Tracker is the place to go for learning about the latest and upcoming US initial public offerings.
We provides ipo news and rumors, sec filings information, performance tracking and monitoring, top ipo brokers and more.
Also check our ipotracker mobile app for real time updates and notification." />
            </MetaTags>

            <a href="/#" onClick={e => {
                e.preventDefault();
                openPlayStore();
            }}>
                <img src={playstoreBanner} alt="..." width="100%" height="100%" style={{ borderRadius: "10px" }} />
            </a>

            <h3>Trending IPOs</h3>
            {trendingloadingBar}
            <GridContainer>
                {trendingListItems}
            </GridContainer>
            <AdsenseView slotType="homeTop"/>
            <h3>IPO Calendar</h3>
            {CalendarLoadingBar}
            {showCalendarTables ?
                <GridContainer>
                    <GridItem md={6}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <PricedIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}> Latest IPOs</h4>
                            </CardHeader>
                            <CardBody >
                                <GridContainer justify="space-between" style={{ overflow: "auto", maxHeight: "500px", minHeight: "500px"}}>
                                    <GridItem >
                                        <Table
                                            hover
                                            tableHeaderColor="primary"
                                            tableHead={tableHeaderPriced}
                                            tableData={pricedData}
                                            coloredColls={[5]}
                                            colorsColls={["primary", "primary"]}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem md={6}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="info">
                                    <ExpectedIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}> Upcoming IPOs</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer justify="space-between" style={{ overflow: "auto", maxHeight: "500px", minHeight: "500px" }}>
                                    <GridItem>
                                        <Table
                                            hover
                                            tableHeaderColor="primary"
                                            tableHead={tableHeaderExpected}
                                            tableData={expectedData}
                                            coloredColls={[5]}
                                            colorsColls={["primary", "primary"]}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                : <div />}
            <AdsenseView slotType="homeMiddle"/>
            <h3>Latest News</h3>
            <div>
                < br />
                <NewsView newsProvider={NewsProviders.GNEWS} />
            </div>
            <AdsenseView slotType="homeBottom"/>
            <Snackbar
                place="tc"
                color="rose"
                icon={ErrorIcon}
                message={snackMessage}
                open={snackVisible}
                closeNotification={() => setSnackVisible(false)}
                close
            />
            </div>
            
    );
}
