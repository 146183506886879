import React from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons


// core components
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

//Custom stuff
import UserManager from "managers/UserManager";

//Global variables
const TAG = "Ipo-Tracker: LearningView ";
const DEBUG = false;
const PLAYSTORE_LINK = "https://play.google.com/store/apps/details?id=com.ingenuity.ipotracker";

const userManager = new UserManager();

const useStylesAlert = makeStyles(alertStyles);

function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}

export default function LearningView() {
    const alertClasses = useStylesAlert();
    const history = useHistory();

    const login = () => {
        history.push("/auth/login-page");
    };

    const goToPlaystore = () => {
        history.goBack();
        openInNewTab(PLAYSTORE_LINK);

    };
    const goBack = () => {
        history.goBack();
    };

    if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        

        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Comming Soon"
            onConfirm={() => goToPlaystore()}
            onCancel={() => goBack()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="Go to Playstore"
            cancelBtnText="Go back"
            showCancel>
                The performance tracker tools are still under development and will be available soon.
                Meanwhile, you can try the beta version on the IPO tracker Android app.
        </SweetAlert>
        );
        return userConnectedLayout;
    }
}
