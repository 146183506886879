import React from "react";
import { useHistory } from "react-router-dom";
import VizSensor from 'react-visibility-sensor';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import Popper from '@material-ui/core/Popper';

import stylesDashboard from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

import StockBuilder from 'builders/StockBuilder';
import StockLogo from "utilities/StockLogo";

//Global declarations

const TAG = "Ipo-Tracker: Autocomplete ";
const DEBUG = false;
const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(stylesDashboard);

export default function CustomInput(props) {
    const classes = useStyles();
    const classesDashboard = useStylesDashboard();
    const history = useHistory();

    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        white,
        inputRootCustomClasses,
        success,
        helperText,
        hits,
        currentRefinement,
        refine,
        onTopSuggestionChanged,
        onSuggestionCleared
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }
    var helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });
    let newInputProps = {
        maxLength:
            inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
        minLength:
            inputProps && inputProps.minLength ? inputProps.minLength : undefined
    };

    //States Declaration
    const [inputValue, setInputValue] = React.useState(null);
    const [showSuggestion, setShowSuggestion] = React.useState(null);
    const [suggestionContent, setSuggestionContent] = React.useState(<div/>);

    //Functions Declaration
    const onInputChange = (event) => {
        let newValue = event.target.value;
        if (DEBUG) console.log(TAG + "onInputChange called for: " + newValue);
        if (!newValue) {
            onSuggestionCleared();
            setSuggestionContent(<div />);
            setShowSuggestion(null);
        } else {
            setShowSuggestion(event.currentTarget);
        }
        setInputValue(newValue);
    };

    const onBlurEvent = (event) => {
        if (DEBUG) console.log(TAG + "onBlurEvent called ");

        if (event && event.relatedTarget && event.relatedTarget.id) {
            if (event.relatedTarget.id.includes("searchItem")) {
                if (DEBUG) console.log(TAG + "onBlurEvent skip hiding popper ");
                return;
            }
        }
        setShowSuggestion(null);
    };

    const open = Boolean(showSuggestion);

    

    React.useEffect(() => {

        function navigateToStockInfo(stockItem_) {
            setShowSuggestion(null);
            history.push("/u/ipo-info?id=" + stockItem_.getDealId());
        }

        function createSearchItem(stockItem_) {
            return (
                <Card id="searchItem_card" key={stockItem_.getName()}
                    style={{ marginTop: "10px" ,marginButtom:"0" }} tabIndex="0">
                    <CardHeader id="searchItem_header" style={{ padding: "0", marging: "0" }}>
                        <GridContainer id="searchItem_girdC" justify="space-between" style={{ padding: "3px" }}>
                            <GridItem id="searchItem_girdI" style={{ maxWidth: "200px" }}>
                                <p className={classesDashboard.cardCategory} style={{ padding: "0", paddingLeft: "3px" }}>
                                    <a id="searchItem_href" href="/#" className={classesDashboard.cardCategory}
                                        onClick={e => {
                                            e.preventDefault();
                                            navigateToStockInfo(stockItem_);
                                        }}>
                                        {stockItem_.getName()}
                                    </a>
                                </p>
                                <h5 className={classesDashboard.cardTitle} style={{ padding: "0", paddingLeft: "3px" }}>
                                    {stockItem_.getSymbol()}
                                </h5>
                            </GridItem>
                            <GridItem id="searchItem_img">
                                <StockLogo stock={stockItem_} round={false} width="50" height="5" minWidth="40px" minHeight="40px" />
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            );
        }

        function requestSearch() {
            if (DEBUG) console.log(TAG + "request results for: " + inputValue);
            setSuggestionContent(<div />);
            refine(inputValue);
        }

        function showResults() {
            if (DEBUG) console.log(TAG + "Showing results for: " + currentRefinement);
            let suggestionList = [];
            for (let index = 0; index < hits.length; index++) {
                let hit = hits[index];
                if (index === 0) {
                    onTopSuggestionChanged(hit.objectID);
                }
                let stockItem_ = new StockBuilder(hit.name, hit.symbol, null, null, null, null, null, null, hit.objectID);
                stockItem_.setLogo(hit.logoUrl);
                suggestionList.push(createSearchItem(stockItem_));
            }
            setSuggestionContent(suggestionList);
        }

        if (inputValue) {
            if (inputValue !== currentRefinement) {
                requestSearch();
            } else {
                showResults();
            }
        }

    }, [onTopSuggestionChanged, history, classesDashboard, refine, inputValue, currentRefinement, hits])

    return (
        <VizSensor
            active={true}
            scrollCheck={true}
            onChange={(isVisible_) => {
                if (!isVisible_) setShowSuggestion(null);
            }}>
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + " " + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </InputLabel>
            ) : null}
            <Input
                classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: classes.underlineSuccess
                }}
                id={id}
                {...inputProps}
                inputProps={newInputProps}
                style={{ width:"300px" }}
                onChange={onInputChange}
                onBlur={onBlurEvent}
            />

            {helperText !== undefined ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}

            <Popper id={id + "-popper"}
                style={{
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    borderRadius: "15px",
                    width: "300px",
                    paddingTop: "5px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    zIndex:"100"
                }}
                tabIndex="0"
                open={open}
                anchorEl={showSuggestion}>
                {suggestionContent}
                </Popper>
            </FormControl>
        </VizSensor>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node,

    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
    onSuggestionSelected: PropTypes.func.isRequired,
    onSuggestionCleared: PropTypes.func.isRequired
};
