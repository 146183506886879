import React from "react";
import VizSensor from 'react-visibility-sensor';
import { Chart, LineAdvance, Interval, Tooltip, Axis, Legend, Coordinate, Interaction } from 'bizcharts';
import { TradingViewStockChartWidget } from 'react-tradingview-components';

// @material-ui/core components
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// custom spinner
import { css } from "@emotion/core";
import Spinner from "react-spinners/ScaleLoader";

// material-ui icons
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PieChartIcon from '@material-ui/icons/PieChart';
import Timeline from "@material-ui/icons/Timeline";
import BarChartIcon from '@material-ui/icons/BarChart';

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { successColor } from "assets/jss/material-dashboard-pro-react.js";

//Custom stuff
import StockBuilder from 'builders/StockBuilder';
import EtfBuilder from 'builders/EtfBuilder';
import StockInfoManager from "managers/StockInfoManager";

//Global variables
const TAG = "Ipo-Tracker: CustomChart ";
const DEBUG = false;
const useStyles = makeStyles(styles);
const ChartTypes = ["quoteChart","volumeChart", "sectorsPie" , "holdingsPie","advancedChart"];
var cachedQuotes = [];

const override = css`
  display: block;
  text-align: center;
  margin: auto;
  padding-top: 25%;
  width: 80%;
  height: 80%;
`;

function chartTooltipFormatter(date, open, close, high, low, volume) {
    return {
        name: date,
        value: '<br/><br><table style={{ marginLeft: "auto", marginRight: "auto" }}>' +
            '<tr>' +
            '<td> Close price </td>' +
            '<td>: $' + parseFloat(close).toFixed(3) + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td> Open price </td>' +
            '<td>: $' + parseFloat(open).toFixed(3) + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td> High </td>' +
            '<td>: $' + parseFloat(high).toFixed(3) + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td> Low </td>' +
            '<td>: $' + parseFloat(low).toFixed(3) + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td> Volume </td>' +
            '<td>:' + parseInt(volume).toFixed(1) + 'K</td>' +
            '</tr>' +
            '</table></br>'
    }
}

const PieChartHoverValueFormatter = {
    percent: {
        formatter: val => {
            val = val * 100;
            return val.toFixed(1) + '%';
        },
    },
};

function formattDate(date_) {
    if (!date_) return date_;
    let yyyy = date_.slice(0, 4);
    let mm = date_.slice(4, 6);
    let dd = date_.slice(-2);
    return yyyy + "-" + mm + "-" + dd +" ";
}

function getColorFromType(type_) {
    switch (type_) {
        case ChartTypes[0]: return "success";
        case ChartTypes[1]: return "info";
        case ChartTypes[2]: return "rose";
        case ChartTypes[3]: return "warning";
        case ChartTypes[4]: return "success";
        default:
            return "warning";
    }
}

function getIconFromType(type_) {
    switch (type_) {
        case ChartTypes[0]: return <Timeline />;
        case ChartTypes[1]: return <BarChartIcon />;
        case ChartTypes[2]: return <PieChartIcon />;
        case ChartTypes[3]: return <AccountBalanceWalletIcon />;
        case ChartTypes[4]: return <Timeline />;
        default:
            return <BarChartIcon />;
    }
}

function getAxisFromType(type_) {
    switch (type_) {
        case ChartTypes[0]: return <Axis name="close" label={{ formatter: val => `$${val ? parseFloat(val).toFixed(0) : val}` }} />;
        case ChartTypes[1]: return <Axis name="volume" label={{ formatter: val => `${val ? parseInt(val).toFixed(0) : val}K` }} />;
        case ChartTypes[2]:
        case ChartTypes[3]:
            return <Axis visible={false} />;
        default:
            return null;
    }
}

function getLegendFromType(type_) {
    switch (type_) {
        case ChartTypes[0]:
        case ChartTypes[1]:
        case ChartTypes[2]:
        case ChartTypes[3]:
        default:
            return <Legend visible={false} />;
    }
}

function getTooltipFromType(type_) {
    switch (type_) {
        case ChartTypes[0]:
        case ChartTypes[1]:
            return <Tooltip
                showTitle={false}
                showMarkers={true}
                itemTpl={'<li class="g2-tooltip-list-item" data-index={index}>'
                    + '<span style="background-color:{color};" class="g2-tooltip-marker"></span>'
                    + '{name}{value}</li>'}
            />;
        case ChartTypes[2]:
        case ChartTypes[3]:
            return <Tooltip showTitle={false} />;
        default:
            return null;
    }
}

function getChartCoreFromType(type_) {
    switch (type_) {
        case ChartTypes[0]:
            return <LineAdvance
                
                area
                position="date*close"
                color="#4caf50"
                tooltip={['date*open*close*high*low*volume',
                    (date, open, close, high, low, volume) => { return chartTooltipFormatter(date, open, close, high, low, volume); }
                ]}
            />
        case ChartTypes[1]:
            return <Interval position="date*volume"
                color={
                    ['date*open*close*volume', (date, open, close, volume) => { return (parseInt(close) - parseInt(open)) >= 0 ? '#36c361' : '#ff5957'; }]}
                tooltip={['date*open*close*high*low*volume',
                    (date, open, close, high, low, volume) => { return chartTooltipFormatter(date, open, close, high, low, volume); }
                ]} />;
        case ChartTypes[2]:
        case ChartTypes[3]:
            return <Interval
                position="percent"
                adjust="stack"
                color="item"
                style={{
                    lineWidth: 1,
                    stroke: '#fff',
                }}
                label={['count', {
                    content: (data) => {
                        return `${data.item} (${data.count ? data.count : data.percent}%)`;
                    },
                }]}
            />;
        default:
            return null;
    }
}

function getCoordinateFromType(type_) {
    switch (type_) {
        case ChartTypes[0]:
        case ChartTypes[1]:
            return null;
        case ChartTypes[2]:
        case ChartTypes[3]:
            return <Coordinate type="theta" radius={0.75} />;
        default:
            return null;
    }
}

function getInteractionFromType(type_) {
    switch (type_) {
        case ChartTypes[0]:
        case ChartTypes[1]:
            return null;
        case ChartTypes[2]:
        case ChartTypes[3]:
            return <Interaction type='element-single-selected' />;
        default:
            return null;
    }
}

function getScaleFromType(type_) {
    switch (type_) {
        case ChartTypes[0]:
        case ChartTypes[1]:
            return null;
        case ChartTypes[2]:
        case ChartTypes[3]:
            return PieChartHoverValueFormatter;
        default:
            return null;
    }
}

function getG2FromType(type_) {
    switch (type_) {
        case ChartTypes[0]:
        case ChartTypes[1]:
            return null;
        case ChartTypes[2]:
        case ChartTypes[3]:
            return c => {
                c.geometries[0].elements.forEach((e, idx) => {
                    e.setState('selected', idx === 0 ? true : false);
                })
            };
        default:
            return null;
    }
}

export default function CustomChart(props) {
    const {
      type,
      title,
      builder,
  } = props;

    const classes = useStyles();
    const [chartData, setChartData] = React.useState([]);
    const [hideChart, setHideChart] = React.useState(true);
    const [isVisible, setIsVisible] = React.useState(false);
    const [dataNotFound, setDataNotFound] = React.useState(false);

    React.useEffect(() => {

        async function fetchStockQuotes(builder) {
            if (DEBUG) console.log(TAG + "fetchStockQuotes called");
            let newData = null;
            if (cachedQuotes[builder.getSymbol()]) {
                if (DEBUG) console.log(TAG + "fetchStockQuotes use cached quote");
                newData = cachedQuotes[builder.getSymbol()];
            } else {
                const stockInfoManager = new StockInfoManager();
                newData = await stockInfoManager.getQuoteBySymbol(builder.getSymbol());
                cachedQuotes[builder.getSymbol()] = newData;
            }
            if (newData && newData.getQuotes()) {
                updateQuoteCharts(newData.getQuotes());
                setHideChart(false);
            } else {
                setDataNotFound(true);
            }
        }

        async function updateQuoteCharts(quotes_) {
            if (DEBUG) console.log(TAG + "updateQuoteCharts called");
            let quoteData = JSON.parse(quotes_);
            let data = []
            for (var index in quoteData) {
                let quote = quoteData[index];
                data.push({
                    date: formattDate(quote.date),
                    open: quote.open,
                    close: parseFloat(quote.close),
                    high: quote.high,
                    low: quote.low,
                    volume: parseInt(quote.volume) / 1000
                });
            }
            if (data) {
                setChartData(data);
                setHideChart(false);
            } else {
                setDataNotFound(true);
            }
        }

        async function updateSectorsPie(etfBuilder_) {
            if (DEBUG) console.log(TAG + "updateSectorsPie called");
            let sectorsData = JSON.parse(etfBuilder_.getSectors());
            let data = []
            for (var sector in sectorsData) {
                let percentage = sectorsData[sector].percent;
                let percentageValue = parseFloat(percentage).toFixed(1);
                let percentageFloat = parseFloat(percentageValue) / 100.0;
                data.push({ item: sectorsData[sector].sector, count: percentageValue, percent: percentageFloat });
            }
            if (data) {
                setChartData(data);
                setHideChart(false);
            } else {
                setDataNotFound(true);
            }
        }

        async function updateHoldingsData(etfBuilder_) {
            let holdingData = JSON.parse(etfBuilder_.getHoldings());
            let data = []
            for (var holding in holdingData) {
                let percentage = holdingData[holding].percent;
                let percentageValue = parseFloat(percentage).toFixed(1);
                let percentageFloat = parseFloat(percentageValue) / 100.0;
                data.push({ item: holdingData[holding].holding, count: percentageValue, percent: percentageFloat });
            }
            if (data) {
                setChartData(data);
                setHideChart(false);
            } else {
                setDataNotFound(true);
            }
        }

        async function fetchData() {
            if (builder instanceof StockBuilder) {
                switch (type) {
                    case ChartTypes[0]:
                    case ChartTypes[1]:
                        fetchStockQuotes(builder);
                        break;
                    default:
                        return;
                }
            } else if (builder instanceof EtfBuilder) {
                switch (type) {
                    case ChartTypes[0]:
                    case ChartTypes[1]:
                        updateQuoteCharts(builder.getQuotes());
                        break;
                    case ChartTypes[2]:
                        updateSectorsPie(builder);
                        break;
                    case ChartTypes[3]:
                        updateHoldingsData(builder);
                        break;
                    default:
                        return;
                }
            }
        }

        if (isVisible && hideChart) {
            fetchData();
        }
        

    }, [builder, type, isVisible, hideChart])

    if (!builder || !type || dataNotFound) return (<div />);
    if (builder instanceof StockBuilder && builder.getStatus() !== "priced") return (<div />);
    if (builder instanceof StockBuilder && type === ChartTypes[4] && builder.getSymbol()) return (
        <Card>
            <CardHeader color={getColorFromType(type)} icon>
                <CardIcon color={getColorFromType(type)}>
                    {getIconFromType(type)}
                </CardIcon>
                <h4 className={classes.cardIconTitle}> {title} </h4>
            </CardHeader>
            <CardBody>
                <TradingViewStockChartWidget width="100%" symbol={builder.getSymbol()} timezone="Etc/UTC" theme="light" locale="en" toolbar_bg="#f1f3f6"
                    enable_publishing={false} withdateranges={true} range="1M" hide_side_toolbar={true}
                    allow_symbol_change={true} details={true} hotlist={true} calendar={true} />
            </CardBody>
        </Card>

        );
  return (
      <Card>
          <CardHeader color={getColorFromType(type)} icon>
              <CardIcon color={getColorFromType(type)}>
                  {getIconFromType(type)}
              </CardIcon>
              <h4 className={classes.cardIconTitle}> {title} </h4>
          </CardHeader>
          <CardBody>
              <VizSensor
                  active={true}
                  scrollCheck={true}
                  onChange={(isVisible_) => {
                      setIsVisible(isVisible_);
                  }}>

                  {!hideChart ?

                      <Chart height={300} data={chartData} autoFit scale={getScaleFromType(type)} onGetG2Instance={getG2FromType(type)}>
                          {getAxisFromType(type)}
                          {getLegendFromType(type)}
                          {getTooltipFromType(type)}
                          {getChartCoreFromType(type)}
                          {getCoordinateFromType(type)}
                          {getInteractionFromType(type)}
                      </Chart>

                      :
                      <div height={300} style={{ minHeight:"300px"}}>
                          <Spinner  css={override} size={10} color={successColor[0]} />
                      </div>
                  }

              </VizSensor>
          </CardBody>
      </Card>
  );
}

CustomChart.defaultProps = {
    builder: null,
    title: "",
    type: null
};

CustomChart.propTypes = {
    type: PropTypes.oneOf(ChartTypes)
};