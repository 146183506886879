import React from "react";
import FireBaseManager from "managers/FireBaseManager";
import UserManager from "managers/UserManager";
import StockLogo from "utilities/StockLogo";

const TAG = "Ipo-Tracker: TrendingManager ";
const DEBUG = false;

function TrendingManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.userManager = new UserManager();
    this.lastAuthenticationState = null;
}

TrendingManager.prototype.getTrending = async function (history_) {
    if (DEBUG) console.log(TAG + "getTrending called");
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return null;
    }
    if (!this.trendingList) {
        this.trendingList = [];
        await this.firebaseManager.getTrending().then(async (trendingDealIds) => {
            if (!trendingDealIds) {
                console.log(TAG + "Unable to get trending list");
                return null;
            }
            let stockList = [];
            for (let i = 0; i < trendingDealIds.length; i++) {
                try {
                    let trendingStock_ = trendingDealIds[i];
                    await this.firebaseManager.getStocksByDealIdAndStatus(trendingStock_.getStatus(), trendingStock_.getDealId()).then(async (foundStock) => {
                        if (!foundStock) {
                            console.log(TAG + "Unable to find stock " + trendingStock_.getName());
                            return null;
                        }
                        stockList.push(foundStock);
                    });
                } catch (error) {
                    console.log(TAG + "Unable to find trending stock ");
                }
            }

            let rank = 0;
            for (var stock_ of stockList) {
                rank++;
                this.trendingList.push(createStockLine(rank, stock_, history_));
            }

        }).catch(e => {
            console.error(TAG + "getTrending Catched exeption: " + e);
        });
    }
    return this.trendingList;
}

TrendingManager.prototype.getHomeTrending = async function (maxItemsCount_) {
    if (DEBUG) console.log(TAG + "getHomeTrending called");
    if (!this.trendingList) {
        this.trendingList = [];
        await this.firebaseManager.getTrending().then(async (trendingDealIds) => {
            if (!trendingDealIds) {
                console.log(TAG + "Unable to get trending list");
                return null;
            }
            let stockList = [];
            for (let i = 0; i < trendingDealIds.length && i < maxItemsCount_; i++) {
                try {
                    let trendingStock_ = trendingDealIds[i];
                    await this.firebaseManager.getStocksByDealIdAndStatus(trendingStock_.getStatus(), trendingStock_.getDealId()).then(async (foundStock) => {
                        if (!foundStock) {
                            console.log(TAG + "Unable to find stock " + trendingStock_.getName());
                            return null;
                        }
                        stockList.push(foundStock);
                    });
                } catch (error) {
                    console.log(TAG + "Unable to find trending stock " + error);
                }
            }
            for (var stock_ of stockList) {
                this.trendingList.push(stock_);
            }

        }).catch(e => {
            console.error(TAG + "getTrending Catched exeption: " + e);
        });
    }
    return this.trendingList;
}

TrendingManager.prototype.clearAllData = function () {
    if (DEBUG) console.log(TAG + "clearAllData called");
    this.trendingList = null;
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.userManager.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userManager.userInfo || !context_.userManager.getFirebaseUser()) return;
    let currentUserUid = context_.userManager.getFirebaseUser().uid;
    let lastUserUid = context_.userManager.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}

function createStockLine(rank_, stock_, history_) {
    if (!stock_) return [];
    let variationText = stock_.isUnknown(stock_.getLastPrice()) ? "Ipo price" : stock_.getVariation();
    let textColor = "";
    if (variationText.includes("-")) {
        textColor = "red";
    } else if (variationText.includes("+")) {
        textColor = "green";
    }
    let variationLine = (<span style={{ color: textColor }}> {variationText} </span>);

    return [
        rank_,
        <StockLogo stock={stock_} round={false} width="40" height="20" minWidth="20px" minHeight="20px" />,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_); }} style={{ color: "black" }}> {stock_.getName()} </a> </p>,
        <p> <a href="/#" onClick={e => { e.preventDefault(); navigateToStockInfo(stock_, history_); }} style={{ color: "black" }}> {stock_.getSymbol()} </a> </p>,
        stock_.isUnknown(stock_.getLastPrice()) ? stock_.getPrice() : stock_.getLastPrice(),
        stock_.isUnknown(stock_.getPrice()) ? "--" : variationLine,
        stock_.getStatus().toUpperCase(),
        stock_.getDate()
    ];
}

function navigateToStockInfo(stockBuilder_, history_) {
    if (!stockBuilder_ || !history_) return;
    if (stockBuilder_.isUnknown(stockBuilder_.getDealId())) return;
    history_.push("/u/ipo-info?id=" + stockBuilder_.getDealId());
}

export default TrendingManager;