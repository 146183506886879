import fetch from "node-fetch" ;
import cheerio from "cheerio";
import FireBaseManager from "managers/FireBaseManager";
import UserManager from "managers/UserManager";
import RemoteConfigManager from "managers/RemoteConfigManager";

const TAG = "Ipo-Tracker: SearchLinkManager ";
const DEBUG = false;

const investingUrlBase = "https://www.investing.com/equities/";
const robinhoodUrlBase = "https://robinhood.com/stocks/";
//const robinhoodAppLinkUrlBase = "https://robinhood.com/applink/instrument/?symbol=MSFT";
const webullUrlBase = "https://www.webull.com/quote/";
const etoroUrlBase = "https://www.etoro.com/markets/";
const ameritradeUrlBase = "https://research.tdameritrade.com/grid/public/research/stocks/summary?symbol=";
const fidelityUrlBase = "https://eresearch.fidelity.com/eresearch/evaluate/snapshot.jhtml?symbols=";

const EXTRA_FORMATS = [["holdings", "holding"], ["inc", ""], ["corp", ""], ["holding", "holdings"]];

function SearchLinkManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.firebaseManager = new FireBaseManager();
    this.userManager = new UserManager();
    this.lastAuthenticationState = null;
    this.remoteConfigManager = new RemoteConfigManager();
    this.corsProxy = this.remoteConfigManager.getCorsProxyUrl();
    if (!this.corsProxy) this.corsProxy = 'https://cors-anywhere.herokuapp.com/';
    
}

SearchLinkManager.prototype.getInvestingLink = async function (name_) {
    if (DEBUG) console.log(TAG + "getInvestingLink called for " + name_);
    this.corsProxy = this.remoteConfigManager.getCorsProxyUrl();
    let formattedName = getFormattedName(name_);
    let testLink = getInvestingTestLink(this.corsProxy, formattedName);
    return fetch(testLink)
        .then(resp => {
            if (!resp.ok) {
                if (DEBUG) console.log(TAG + "getInvestingLink link (" + testLink + ") Failed ");
                return testExtraFormats(this.corsProxy, formattedName);
            } else {
                if (DEBUG) console.log(TAG + "getInvestingLink link (" + testLink + ") Success ");
                return testLink.replace(this.corsProxy, "");
            }
        }).catch(networkError => {
            console.error(TAG + 'Error: ' + networkError);
            return null;
        });
}

SearchLinkManager.prototype.getRobinhoodLink = async function (symbol_) {
    if (DEBUG) console.log(TAG + "getRobinhoodLink called for " + symbol_);
    this.corsProxy = this.remoteConfigManager.getCorsProxyUrl();
    let testLink = getRobinhoodTestLink(this.corsProxy, getFormattedSymbol(symbol_));
    return fetch(testLink)
        .then(async(resp) => {
            if (!resp.ok) {
                if (DEBUG) console.log(TAG + "getRobinhoodLink link (" + testLink + ") Failed ");
                return null;
            } else {
                try {
                    let body = await resp.text();
                    if (body) {
                        var $ = cheerio.load(body);
                        var title = $("title").text();
                        if (DEBUG) console.log(TAG + "getRobinhoodLink title: " + title);
                        if (!title || title.includes("not found") || title.includes("404")) return null;
                    }
                } catch (e) {
                    if (DEBUG) console.log(TAG + "getRobinhoodLink unable to get title: " + e);
                }
                if (DEBUG) console.log(TAG + "getRobinhoodLink link (" + testLink + ") Success ");
                return testLink.replace(this.corsProxy, "");
            }
        }).catch(networkError => {
            console.error(TAG + 'Error: ' + networkError);
            return null;
        });
}

SearchLinkManager.prototype.getWebullLink = async function (symbol_ , exchange_) {
    if (DEBUG) console.log(TAG + "getWebullLink called for " + symbol_ + "/" + exchange_);
    this.corsProxy = this.remoteConfigManager.getCorsProxyUrl();
    let testLink = getWebullTestLink(this.corsProxy, getFormattedSymbol(symbol_), getFormattedExchange(exchange_));
    return fetch(testLink)
        .then(async (resp) => {
            if (!resp.ok) {
                if (DEBUG) console.log(TAG + "getWebullLink link (" + testLink + ") Failed ");
                return null;
            } else {
                try {
                    let body = await resp.text();
                    if (body) {
                        var $ = cheerio.load(body);
                        var title = $("title").text();
                        if (DEBUG) console.log(TAG + "getWebullLink title: " + title);
                        if (title.includes("not found") || title.includes("404")) return null;
                    }
                } catch (e) {
                    if (DEBUG) console.log(TAG + "getWebullLink unable to get title: " + e);
                }
                if (DEBUG) console.log(TAG + "getWebullLink link (" + testLink + ") Success ");
                return testLink.replace(this.corsProxy, "");
            }
        }).catch(networkError => {
            console.error(TAG + 'Error: ' + networkError);
            return null;
        });
}

SearchLinkManager.prototype.getEtoroLink = async function (symbol_ , enableUnverified_) {
    if (DEBUG) console.log(TAG + "getEtoroLink called for " + symbol_);
    this.corsProxy = this.remoteConfigManager.getCorsProxyUrl();
    let testLink = getEtoroTestLink(this.corsProxy, getFormattedSymbol(symbol_));
    if (enableUnverified_) return testLink.replace(this.corsProxy, "");
    return fetch(testLink)
        .then(resp => {
            if (!resp.ok) {
                if (DEBUG) console.log(TAG + "getEtoroLink link (" + testLink + ") Failed ");
                return null;
            } else {
                if (DEBUG) console.log(TAG + "getEtoroLink link (" + testLink + ") Success ");
                return testLink.replace(this.corsProxy, "");
            }
        }).catch(networkError => {
            console.error(TAG + 'Error: ' + networkError);
            return null;
        });
}

SearchLinkManager.prototype.getAmeritradeLink = async function (symbol_) {
    if (DEBUG) console.log(TAG + "getAmeritradeLink called for " + symbol_);
    this.corsProxy = this.remoteConfigManager.getCorsProxyUrl();
    let testLink = getAmeritradeTestLink(this.corsProxy, getFormattedSymbol(symbol_));
    return fetch(testLink)
        .then(async (resp) => {
            if (!resp.ok) {
                if (DEBUG) console.log(TAG + "getAmeritradeLink link (" + testLink + ") Failed ");
                return null;
            } else {
                try {
                    let body = await resp.text();
                    if (body) {
                        var $ = cheerio.load(body);
                        var title = $("title").text();
                        if (DEBUG) console.log(TAG + "getAmeritradeLink title: " + title);
                        if (!title || !title.toLowerCase().includes("stock summary")) return null;
                        var alertMessage = $(".alert-message-content");
                        if (alertMessage) {
                            if (alertMessage.children() && alertMessage.children().first() && alertMessage.children().first().text()) {
                                if (alertMessage.children().first().text().toLowerCase().includes("changed")) {
                                    return null;
                                }
                            }                            
                        }
                    }
                } catch (e) {
                    if (DEBUG) console.log(TAG + "getAmeritradeLink unable to parse body: " + e);
                }
                if (DEBUG) console.log(TAG + "getAmeritradeLink link (" + testLink + ") Success ");
                return testLink.replace(this.corsProxy, "");
            }
        }).catch(networkError => {
            console.error(TAG + 'Error: ' + networkError);
            return null;
        });
}

SearchLinkManager.prototype.getFidelityLink = async function (symbol_) {
    if (DEBUG) console.log(TAG + "getFidelityLink called for " + symbol_);
    this.corsProxy = this.remoteConfigManager.getCorsProxyUrl();
    let testLink = getFidelityTestLink(this.corsProxy, getFormattedSymbol(symbol_));
    return fetch(testLink)
        .then(async (resp) => {
            if (!resp.ok) {
                if (DEBUG) console.log(TAG + "getFidelityLink link (" + testLink + ") Failed ");
                return null;
            } else {
                try {
                    let body = await resp.text();
                    if (body) {
                        var $ = cheerio.load(body);
                        var title = $("title").text();
                        if (DEBUG) console.log(TAG + "getFidelityLink title: " + title);
                        if (!title || !title.toLowerCase().includes("stock snapshot")) return null;
                    }
                } catch (e) {
                    if (DEBUG) console.log(TAG + "getFidelityLink unable to parse body: " + e);
                }
                if (DEBUG) console.log(TAG + "getFidelityLink link (" + testLink + ") Success ");
                return testLink.replace(this.corsProxy, "");
            }
        }).catch(networkError => {
            console.error(TAG + 'Error: ' + networkError);
            return null;
        });
}

SearchLinkManager.prototype.updateStockSearchLinks = async function (stock_, newLinksArray_) {
    if (DEBUG) console.log(TAG + "updateStockSearchLinks called");
    if (!stock_ || !newLinksArray_ || Object.keys(newLinksArray_).length < 1) return;
    checkAuthenticationChanged(this);
    if (!this.userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        return;
    }
    await this.firebaseManager.updateStockSearchLinks(stock_, newLinksArray_)
        .catch(e => {
            console.error(TAG + "updateStockSearchLinks Catched exeption: " + e);
        });
}

async function testExtraFormats(corsProxy_, formattedName_) {
    if (DEBUG) console.log(TAG + "testExtraFormats called ");
    var foundValidLink = false;
    var alreadyTestedLinks = {};
    alreadyTestedLinks[formattedName_] = true;
    for (var index_ in EXTRA_FORMATS) {
        let testName = formattedName_.replace(new RegExp(EXTRA_FORMATS[index_][0], 'g'), EXTRA_FORMATS[index_][1]);
        testName = testName.replace(new RegExp("--", 'g'), "-");
        if (testName.endsWith("-")) testName = testName.substring(0, testName.length - 1);
        if (alreadyTestedLinks[testName]) {
            if (DEBUG) console.log(TAG + "testExtraFormats Skip already tested " + testName);
            continue;
        } else {
            alreadyTestedLinks[testName] = true;
        }
        let testLink = getInvestingTestLink(corsProxy_, testName);
        if (DEBUG) console.log(TAG + "testExtraFormats testing " + testName);
        foundValidLink = await new Promise(async (resolve) => {
            await fetch(testLink)
                .then(resp => {
                    if (!resp.ok) {
                        if (DEBUG) console.log(TAG + "testExtraFormats link (" + testLink + ") Failed ");
                        resolve(false);
                    } else {
                        if (DEBUG) console.log(TAG + "testExtraFormats link (" + testLink + ") Success ");
                        resolve(true);
                    }
                }).catch(networkError => {
                    console.error(TAG + 'Error: ' + networkError);
                    resolve(false);
                });
        });
        if (foundValidLink) return testLink.replace(this.corsProxy , "");
    }
    return null;
}

function getFormattedName(name_) {
    let formattedName = name_;
    formattedName = formattedName.replace(new RegExp(" ", 'g'), "-");
    formattedName = formattedName.replace(new RegExp("&", 'g'), "and");
    formattedName = formattedName.replace(new RegExp("\\.", 'g'), "");
    formattedName = formattedName.replace(new RegExp(",", 'g'), "");
    formattedName = formattedName.replace(new RegExp("'", 'g'), "");
    formattedName = formattedName.toLowerCase();
    if (DEBUG) console.log(TAG + "getFormattedName returned " + formattedName);
    return formattedName;
}

function getFormattedExchange(exchange_) {
    let formattedName = exchange_;
    formattedName = formattedName.toLowerCase();
    if (formattedName.includes("nasdaq")) return "nasdaq";
    else if (formattedName.includes("nyse")) return "nyse";
    return formattedName;
}

function getFormattedSymbol(symbol_) {
    let formattedSymbol = symbol_;
    formattedSymbol = formattedSymbol.toLowerCase();
    return formattedSymbol;
}

function getInvestingTestLink(corsProxy_ , formattedName_) {
    return corsProxy_ + investingUrlBase + formattedName_;
}

function getRobinhoodTestLink(corsProxy_, formattedSymbol_) {
    return corsProxy_ + robinhoodUrlBase + formattedSymbol_;
}

function getWebullTestLink(corsProxy_, formattedSymbol_ , formattedExchange_) {
    return corsProxy_ + webullUrlBase + formattedExchange_ + "-" + formattedSymbol_;
}

function getEtoroTestLink(corsProxy_, formattedSymbol_) {
    return corsProxy_ + etoroUrlBase + formattedSymbol_;
}

function getAmeritradeTestLink(corsProxy_, formattedSymbol_) {
    return corsProxy_ + ameritradeUrlBase + formattedSymbol_;
}

function getFidelityTestLink(corsProxy_, formattedSymbol_) {
    return corsProxy_ + fidelityUrlBase + formattedSymbol_;
}

function checkAuthenticationChanged(context_) {
    let newAuthenticationState = context_.userManager.isConnected();
    if (context_.lastAuthenticationState == null) {
        context_.lastAuthenticationState = newAuthenticationState;
        return;
    }
    if (context_.lastAuthenticationState !== newAuthenticationState) {
        if (DEBUG) console.log(TAG + "Authentication state changed");
        context_.lastAuthenticationState = newAuthenticationState;
        context_.clearAllData();
        return
    }
    if (!context_.userManager.userInfo) return;
    let currentUserUid = context_.userManager.getFirebaseUser().uid;
    let lastUserUid = context_.userManager.userInfo.getId();
    if (lastUserUid !== currentUserUid) {
        if (DEBUG) console.log(TAG + "User has changed");
        context_.clearAllData();
    }
}

export default SearchLinkManager;