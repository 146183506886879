import React from "react";
import { useHistory } from "react-router-dom";
import { VectorMap } from "react-jvectormap";
import { overwrite,getCode } from 'country-list';
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import NavPills from "components/NavPills/NavPills.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Success from "components/Typography/Success.js";
import Table from "components/Table/Table.js";

//Custom stuff
import UserManager from "managers/UserManager";
import EtfManager from "managers/EtfManager";
import CustomChart from "utilities/CustomChart";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: EtfView ";
const DEBUG = false;
const etfManager = new EtfManager();
const userManager = new UserManager();
const showLoadingBar = <LinearProgress />;
const emptyContent = <div></div>;

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

overwrite([
    {
        code: 'US',
        name: 'united states'
    },
    {
        code: 'GB',
        name: 'united kingdom'
    }
]);

export default function RegularTables() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();

    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [etfView, setEtfView] = React.useState(emptyContent);
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    React.useEffect(() => {

        function geCountriesData(etfList_) {
            let countriesData = JSON.parse(etfList_.getCountries());
            let data = []
            for (var country in countriesData) {
                let percentage = countriesData[country].percent;
                let name = countriesData[country].county;
                let code = getCode(name);
                let flag = null
                let flagImage = "";
                if (code) {
                    try {
                        flag = require("assets/img/flags/" + code.toUpperCase() + ".png");
                        flagImage = <img src={flag} alt="us_flag" key={"flag"} />
                    } catch (e) {
                        flagImage = "";
                    }
                }
                data.push([
                    flagImage,
                    name,
                    percentage
                ]);
            }
            return data;
        }

        function geCountriesVector(etfList_) {
            let countriesData = JSON.parse(etfList_.getCountries());
            let data = {}
            for (var country in countriesData) {
                let percentage = countriesData[country].percent;
                let percentageValue = parseFloat(percentage).toFixed(1);
                let name = countriesData[country].county;
                let code = getCode(name);                
                data[code] = percentageValue;
            }
            return data;
        }

        function createEtfTab(etfList_) {
            if (!etfList_) return {};
            let countriesTableData = geCountriesData(etfList_);
            let countriesVectorData = geCountriesVector(etfList_);
            return (
                {
                    tabButton: etfList_.getSymbol(),
                    tabContent: (
                        <div>
                            <br />
                            <br />
                            <Card profile>
                                <CardAvatar profile style={{ background: "white" }}>
                                    <Success><b> <h1 class="center" height="800px" width="800px" style={{ paddingTop: "10%", paddingRight:"50%", minWidth: "200px", minHeight: "800px" }}> {etfList_.getSymbol()}  </h1></b></Success>
                                </CardAvatar>
                                <CardBody profile>
                                    <h4 className={classes.cardCategory}>{etfList_.getName()}</h4>
                                    <p className={classes.description} style={{ whiteSpace: 'pre-wrap' }}>{etfList_.getDescription()}</p>                                   
                                </CardBody>
                                <CardFooter stats>
                                    <p className={classes.stats} style={{ whiteSpace: 'pre-wrap' }}>{etfList_.getInsight()}</p>
                                </CardFooter>
                            </Card>
                            <br />
                            <GridContainer>
                                <GridItem xs={6}>
                                    <CustomChart title="Quote Chart" type="quoteChart" builder={etfList_} />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomChart title="Volume Chart" type="volumeChart" builder={etfList_} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomChart title="Top Sectors" type="sectorsPie" builder={etfList_} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomChart title="Top Holdings" type="holdingsPie" builder={etfList_} />
                                </GridItem>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader color="primary" icon>
                                            <CardIcon color="primary">
                                                <Language />
                                            </CardIcon>
                                            <h4 className={classes.cardIconTitle}> Top countries </h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer justify="space-between">
                                            <GridItem xs={12} sm={12} md={5}>
                                                <Table
                                                        tableData={countriesTableData}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <VectorMap
                                                    map={"world_mill"}
                                                    backgroundColor="transparent"
                                                    zoomOnScroll={false}
                                                    containerStyle={{
                                                        width: "100%",
                                                        height: "280px"
                                                    }}
                                                    containerClassName="map"
                                                    regionStyle={{
                                                        initial: {
                                                            fill: "#e4e4e4",
                                                            "fill-opacity": 0.9,
                                                            stroke: "none",
                                                            "stroke-width": 0,
                                                            "stroke-opacity": 0
                                                        }
                                                    }}
                                                    series={{
                                                        regions: [
                                                            {
                                                                values: countriesVectorData,
                                                                scale: ["#AAAAAA", "#444444"],
                                                                normalizeFunction: "polynomial"
                                                            }
                                                        ]
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                    )
                });
        }

        function updateEtfView(etfList_) {
            let etfView_ = emptyContent;
            if (etfList_ && etfList_.length >= 1) {
                let etfTabs_ = [];
                for (let i = 0; i < etfList_.length; i++) {
                    let etfItem_ = etfList_[i];
                    etfTabs_.push(createEtfTab(etfItem_));
                }
                etfView_ = (
                    <GridItem xs={12}>
                        <NavPills
                            color="success"
                            alignCenter
                            tabs={etfTabs_}
                        />
                    </GridItem>
                );

            }
            setEtfView(etfView_);
        }

        async function fetchData() {
            if (DEBUG) console.log(TAG + "fetchData called");
            setLoadingBar(showLoadingBar);
            let newData = await etfManager.getEtfs();
            if (newData && !newData.length < 1) {
                await updateEtfView(newData);
            } else {
                await updateEtfView(null);
            }
            setLoadingBar();
        }

        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            } else {
                if (userManager.isConnected()) {
                    fetchData();
                }
            }
        });
    }, [classes]);

    if (adBlockDetected) {
        return (<SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="An Blocker is detected !"
            onConfirm={() => window.location.reload()}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="I have disabled AdBlock"
        >
            This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
        </SweetAlert>);
    } else if (!userManager.isConnected()) {
        if (DEBUG) console.log(TAG + "User not connected");
        const login = () => {
            history.push("/auth/login-page");
        };
        const goBack = () => {
            history.goBack();
        };

        const userNotConnectedLayout = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Access restricted to members"
                onConfirm={() => login()}
                onCancel={() => goBack()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Log in !"
                cancelBtnText="Go back"
                showCancel
            >
                You need to be logged in to access this section!
            </SweetAlert>
        );

        return userNotConnectedLayout;
    } else {
        if (DEBUG) console.log(TAG + "User connected");
        const userConnectedLayout = (
            <div>
                <MetaTags>
                    <title>IPO ETF</title>
                    <meta name="description" content="Discover ETF s dedicated for the initial public offerings, direct listings and SPACs. 
Keep track of ETFs like Renaissance IPO ETF, Defiance Next Gen SPAC Derived ETF, Invesco S&P Spin-Off ETF and First Trust U.S. Equity Opportunities ETF" />
                    <meta property="og:title" content="IPO ETFs" />
                </MetaTags>
                <AdsenseView slotType="homeTop" />
                {loadingBar}
                <GridContainer justify="center">
                    {etfView}
                </GridContainer>
                <AdsenseView slotType="homeBottom" />
            </div>
        );
        return userConnectedLayout;
    }
}
