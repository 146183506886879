import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// custom spinner
import { css } from "@emotion/core";
import Spinner from "react-spinners/BounceLoader";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import HomeIcon from "@material-ui/icons/Home";
import LiveTvIcon from '@material-ui/icons/LiveTv';
import Search from "@material-ui/icons/Search";

// core components
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { successColor } from "assets/jss/material-dashboard-pro-react.js";

//Algolia Search components
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Configure,
    connectAutoComplete,
    connectPoweredBy
} from 'react-instantsearch-dom';
import Autocomplete from 'components/Search/CustomAutocomplete.js';
import AlgoliaLogo from "assets/img/algolia_logo.png";

// Custom imports
import UserManager from "managers/UserManager";
import WatchlistManager from "managers/WatchlistManager";
import CalendarManager from "managers/CalendarManager";
import RemoteConfigManager from "managers/RemoteConfigManager";

//Global variables
const TAG = "Ipo-Tracker: NavbarView ";
const DEBUG = false;
const NOTIF_MAX_IPO_COUNT = 3;
const watchlistManager = new WatchlistManager();
const calendarManager = new CalendarManager();
const userManager = new UserManager();
const remoteConfigManager = new RemoteConfigManager();
const useStyles = makeStyles(styles);
const searchClient = algoliasearch(
    '1M8ME874OL',
    '0d26baed851d4add600c4a976b951c6f'
);
const AutocompleteConnector = connectAutoComplete(Autocomplete);

function AlgoliaIcon(props_) {
    return (
        <img src={AlgoliaLogo} width={100}  alt="..." />
    );
}

const override = css`
  display: block;
  margin-right: 180%;
`;

function getNextMonthDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    //return Next month
    mm = parseInt(mm) + 1;
    if (mm > 12) {
        mm = mm - 12;
        yyyy = yyyy + 1;
    }
    mm = String(mm).padStart(2, '0');

    var endDate_ = yyyy + '-' + mm + '-' + dd;
    if (DEBUG) console.log(TAG + "getNextMonthDate: " + endDate_);
    return endDate_;
}

function getCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    var currentDate_ = yyyy + '-' + mm + '-' + dd;
    if (DEBUG) console.log(TAG + "getCurrentDate: " + currentDate_);
    return currentDate_;
}

var topSuggestionDealId = null;

export default function HeaderLinks(props) {
    const history = useHistory();
    const [openNotification, setOpenNotification] = React.useState(null);
    const [notificationList, setNotificationList] = React.useState([]);
    const [notificationCount, setNotificationCount] = React.useState(0);
    const [notificationLoading, setNotificationLoading] = React.useState(true);
    const handleClickNotification = event => {
        if (openNotification && openNotification.contains(event.target)) {
            setOpenNotification(null);
        } else {
            setOpenNotification(event.currentTarget);
        }
    };
    const handleCloseNotification = () => {
        setOpenNotification(null);
    };
    const [openProfile, setOpenProfile] = React.useState(null);
    const handleClickProfile = event => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };
    const handleCloseProfile = () => {
        setOpenProfile(null);
    };
    const classes = useStyles();
    const { rtlActive } = props;

    const dropdownItem = classNames(classes.dropdownItem, classes.successHover, {
        [classes.dropdownItemRTL]: rtlActive
    });
    const wrapper = classNames({
        [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
        [classes.managerClasses]: true
    });
    let notificationMenu = null;
    let userMenu = null;

    const goToHome = () => {
        history.push("/u/home");
    }

    const goToLiveTV = () => {
        const liveTvUrl = remoteConfigManager.getLiveTvUrl();
        if (DEBUG) console.log(TAG + "goToLiveTV url: " + liveTvUrl);
        if (liveTvUrl && liveTvUrl.length > 1) {
            var win = window.open(liveTvUrl, '_blank');
            win.focus();
        }
    }
    
    const goToProfile = () => {
        handleCloseProfile();
        history.push("/u/user-profile");
    }

    const goToWatchlist = () => {
        handleCloseProfile();
        history.push("/u/watchlist");
    }

    const signIn = () => {
        handleCloseProfile();
        history.push("/auth/login-page");
    }

    const signOut = async () => {
        handleCloseProfile();
        const userManager = new UserManager();
        await userManager.signOut();
        history.push("/auth/login-page");
    }

    const goToBestSearch = () => {
        if(DEBUG) console.log("goToBestSearch called: " + topSuggestionDealId);
        if (topSuggestionDealId) {
            history.push("/u/ipo-info?id=" + topSuggestionDealId);
        }
    };

    const onTopSuggestionChanged = (topSuggestionDealId_) => {
        if (DEBUG) console.log("onTopSuggestionChanged called with " + topSuggestionDealId_);
        topSuggestionDealId = topSuggestionDealId_;
    };

    const onSuggestionCleared = () => {
        if (DEBUG) console.log("onSuggestionCleared called ");
        topSuggestionDealId = null;
    };

    const goToAlgolia = (link_) => {
        var win = window.open(link_, '_blank');
        win.focus();
    }

    React.useEffect(() => {

        var notificationCount_ = 0;

        const goToPage = (link_) => {
            handleCloseNotification();
            if (link_) {
                history.push(link_);
            }
        }

        function createNotificationItem(title_, link_) {
            return (
                <MenuItem
                    color="success"
                    onClick={() => goToPage(link_)}
                    className={dropdownItem}>
                    {title_}
                </MenuItem>
            );
        }

        function showNotification(title_, link_) {
            let notificationList_ = notificationList;
            notificationList_.push(createNotificationItem(title_, link_));
            setNotificationList(notificationList_);
            if (link_) {
                notificationCount_ ++;
                setNotificationCount(notificationCount_);
            }
        }

        function notifyTodayStatus(stock_) {
            if (!stock_.isUnknown(stock_.getName())
                && !stock_.isUnknown(stock_.getStatus())
                && !stock_.isUnknown(stock_.getDealId())) {
                let notificationTitle_ = stock_.getName() + " is " + stock_.getStatus() + " today.";
                let notificationLink_ = "/u/ipo-info?id=" + stock_.getDealId();
                showNotification(notificationTitle_, notificationLink_);
            }
        }

        function notifyNextMonthStatus(stock_) {
            if (!stock_.isUnknown(stock_.getName())
                && !stock_.isUnknown(stock_.getStatus())
                && !stock_.isUnknown(stock_.getDealId())) {
                if (stock_.getStatus().includes("expected")) {
                    let notificationTitle_ = [];
                    notificationTitle_.push(<b key={stock_.getName()}> {stock_.getName()} </b>);
                    notificationTitle_.push(" will be priced on ");
                    notificationTitle_.push(<b key={stock_.getDate()}> {stock_.getDate()} </b>);
                    let notificationLink_ = "/u/ipo-info?id=" + stock_.getDealId();
                    showNotification(notificationTitle_, notificationLink_);
                }
            }
        }

        function notifyTodayExpected(stockList_) {
            let notificationTitle_ = [];
            let notificationLink_ = "/u/calendar";
            let foundItems = 0;
            for (let i = 0; i < stockList_.length && i < NOTIF_MAX_IPO_COUNT; i++) {
                let stock_ = stockList_[i];
                if (stock_.isUnknown(stock_.getName())) continue;
                if (foundItems > 0) {
                    notificationTitle_.push(", ");
                }
                foundItems++;
                notificationTitle_.push(<b key={stock_.getName()} > {stock_.getName()} </b>);
            }
            let remainingCount = stockList_.length - foundItems;
            if (remainingCount > 0) {
                notificationTitle_.push(" and " + remainingCount + " more ");
            }
            if (foundItems > 1) {
                notificationTitle_.push(" are");
            } else {
                notificationTitle_.push(" is");
            }
            notificationTitle_.push(" Expected Today");
            showNotification(notificationTitle_, notificationLink_);
        }

        function checkForNewNotifications(watchListStocks_) {
            let todayDate = Date.parse(getCurrentDate());
            let nextMonthDate = Date.parse(getNextMonthDate());
            if (DEBUG) console.log(TAG + "checkForNewNotifications " + getCurrentDate() + " -> " + getNextMonthDate());
            for (let i = 0; i < watchListStocks_.length; i++) {
                let stock_ = watchListStocks_[i];
                if (!stock_ || stock_.isUnknown(stock_.getDate())) continue;
                let stockDate = Date.parse(stock_.getDate());
                if (DEBUG) console.log(TAG + "Stock date: " + stock_.getDate());
                if (stockDate < todayDate) continue;

                if (stockDate === todayDate) {
                    if (DEBUG) console.log(TAG + "Show today notif");
                    notifyTodayStatus(stock_);
                    continue;
                }

                if (stockDate < nextMonthDate) {
                    if (DEBUG) console.log(TAG + "Show next month notif");
                    notifyNextMonthStatus(stock_);
                }
            }
        }

        async function fetchTodaylist() {
            if (DEBUG) console.log(TAG + "fetchTodaylist called");
            let newWatchlistData = await watchlistManager.getWatchlistStocks();
            if (newWatchlistData && !newWatchlistData.length < 1) {
                if (DEBUG) console.log(TAG + "Found watchlist");
                await checkForNewNotifications(newWatchlistData);
            } else {
                if (DEBUG) console.log(TAG + "Empty watchlist");
            }

            let todayExpectedList = await calendarManager.getTodayList();
            if (todayExpectedList && !todayExpectedList.length < 1) {
                if (DEBUG) console.log(TAG + "Found today expected");
                notifyTodayExpected(todayExpectedList);
            } else {
                if (DEBUG) console.log(TAG + "Empty today expected");
            }

            setNotificationLoading(false);
            if (notificationCount_ < 1) {
                showNotification("No notifications for today", null)
            }
        }

        if (userManager.isConnected()) {
            fetchTodaylist();
        }
    }, [dropdownItem, notificationList, history]);

    if (userManager.isConnected()) {
        notificationMenu = (
            <div className={managerClasses}>

                <Button
                    color="transparent"
                    justIcon
                    aria-label="Notifications"
                    aria-owns={openNotification ? "notification-menu-list" : null}
                    aria-haspopup="true"
                    onClick={handleClickNotification}
                    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                    muiClasses={{
                        label: rtlActive ? classes.labelRTL : ""
                    }}
                >
                    {notificationLoading ? <Spinner className={classes.headerLinksSvg + " " + classes.links} css={override} style={{}} size={30} color={successColor[0]} /> : <Notifications className={classes.headerLinksSvg + " " + classes.links} />}
                    {notificationCount > 0 ? <span className={classes.notifications}> {notificationCount}</span> : null}
                    <Hidden mdUp implementation="css">
                        <span
                            onClick={handleClickNotification}
                            className={classes.linkText}
                        >
                            {"Notification"}
                        </span>
                    </Hidden>
                </Button>

                <Popper
                    open={Boolean(openNotification)}
                    anchorEl={openNotification}
                    transition
                    disablePortal
                    placement="bottom"
                    className={classNames({
                        [classes.popperClose]: !openNotification,
                        [classes.popperResponsive]: true,
                        [classes.popperNav]: true
                    })}>

                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            id="notification-menu-list"
                            style={{ transformOrigin: "0 0 0" }}>

                            <Paper className={classes.dropdown}>
                                <ClickAwayListener onClickAway={handleCloseNotification}>
                                    <MenuList role="menu">
                                        {notificationList}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </div>
        );

        userMenu = (
            <div className={managerClasses}>
                <Button
                    color="transparent"
                    aria-label="Person"
                    justIcon
                    aria-owns={openProfile ? "profile-menu-list" : null}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                    muiClasses={{
                        label: rtlActive ? classes.labelRTL : ""
                    }}
                >
                    <Person
                        className={
                            classes.headerLinksSvg +
                            " " +
                            (rtlActive
                                ? classes.links + " " + classes.linksRTL
                                : classes.links)
                        }
                    />
                    <Hidden mdUp implementation="css">
                        <span onClick={handleClickProfile} className={classes.linkText}>
                            {rtlActive ? "الملف الشخصي" : "Profile"}
                        </span>
                    </Hidden>
                </Button>
                <Popper
                    open={Boolean(openProfile)}
                    anchorEl={openProfile}
                    transition
                    disablePortal
                    placement="bottom"
                    className={classNames({
                        [classes.popperClose]: !openProfile,
                        [classes.popperResponsive]: true,
                        [classes.popperNav]: true
                    })}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list"
                            style={{ transformOrigin: "0 0 0" }}
                        >
                            <Paper className={classes.dropdown}>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={goToProfile}
                                            className={dropdownItem}
                                        >
                                            {"Profile"}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={goToWatchlist}
                                            className={dropdownItem}
                                        >
                                            {"Watchlist"}
                                        </MenuItem>
                                        <Divider light />
                                        <MenuItem
                                            onClick={signOut}
                                            className={dropdownItem}
                                        >
                                            {"Sign Out"}
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );

    } else {
        notificationMenu = null;

        userMenu = (
            <div className={managerClasses}>
                <Button
                    color="transparent"
                    aria-label="Person"
                    justIcon
                    aria-owns={openProfile ? "profile-menu-list" : null}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                    muiClasses={{
                        label: rtlActive ? classes.labelRTL : ""
                    }}
                >
                    <Person
                        className={
                            classes.headerLinksSvg +
                            " " +
                            classes.links
                        }
                    />
                    <Hidden mdUp implementation="css">
                        <span onClick={handleClickProfile} className={classes.linkText}>
                            {"Profile"}
                        </span>
                    </Hidden>
                </Button>
                <Popper
                    open={Boolean(openProfile)}
                    anchorEl={openProfile}
                    transition
                    disablePortal
                    placement="bottom"
                    className={classNames({
                        [classes.popperClose]: !openProfile,
                        [classes.popperResponsive]: true,
                        [classes.popperNav]: true
                    })}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list"
                            style={{ transformOrigin: "0 0 0" }}
                        >
                            <Paper className={classes.dropdown}>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={signIn}
                                            className={dropdownItem}
                                        >
                                            {"Log In"}
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }

    const PoweredBy = ({ url }) => (
        <Button
            color="transparent"
            simple
            aria-label="powered by algolia"
            justIcon
            className={classes.buttonLink}
            onClick={() => goToAlgolia(url)}
            style={{ marginRight: "50px" }}
            muiClasses={{
                label: ""
            }}>
            <AlgoliaIcon className={classes.headerLinksSvg + " " + classes.searchIcon} />
        </Button>
    );

    const CustomPoweredBy = connectPoweredBy(PoweredBy);

    return (
        <div className={wrapper}>

            <InstantSearch
                className={classes.searchInput}
                searchClient={searchClient}
                indexName="Stock_List"
            >
                <CustomPoweredBy/>

                <Configure hitsPerPage={5} />

                <AutocompleteConnector
                    onTopSuggestionChanged={onTopSuggestionChanged}
                    onSuggestionCleared={onSuggestionCleared}
                    rtlActive={rtlActive}
                    formControlProps={{
                        className: classes.top + " " + classes.search
                    }}
                    inputProps={{
                        placeholder: "Type an IPO Name or symbol (since 2013)",
                        inputProps: {
                            "aria-label": "Search",
                            className: classes.searchInput
                        }
                    }}
                />
            </InstantSearch>

            <Button
                color="transparent"
                simple
                aria-label="edit"
                justIcon
                className={classes.buttonLink}   
                onClick={goToBestSearch}
                muiClasses={{
                    label: ""
                }}>

                <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
            </Button>

            <Button
                color="transparent"
                simple
                aria-label="Home"
                justIcon
                className={classes.buttonLink}
                onClick={goToHome}
                muiClasses={{
                    label: ""
                }}>
                <HomeIcon
                    className={
                        classes.headerLinksSvg +
                        " " +
                        (classes.links)
                    }
                />
                <Hidden mdUp implementation="css">
                    <span className={classes.linkText}>
                        {"Home"}
                    </span>
                </Hidden>
            </Button>
            <Button
                color="transparent"
                simple
                aria-label="Live TV"
                justIcon
                className={classes.buttonLink}
                onClick={goToLiveTV}
                muiClasses={{
                    label: ""
                }}>
                <LiveTvIcon
                    className={
                        classes.headerLinksSvg +
                        " " +
                        (classes.links)
                    }
                />
                <Hidden mdUp implementation="css">
                    <span className={classes.linkText}>
                        {"Live TV"}
                    </span>
                </Hidden>
            </Button>

            {notificationMenu}
            {userMenu}
        </div>
    );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
