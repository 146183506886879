import React from "react";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { successColor, dangerColor } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

//Global variables
const TAG = "Ipo-Tracker: IpoStatusCard ";
const DEBUG = false;

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: successColor[0],
        },
    },
    completed: {
        '& $line': {
            borderColor: successColor[0],
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    stepIconRoot: {
        color: "#eaeaf0",
        "&.MuiStepIcon-active": {
            color: successColor[0]
        },
        "&.MuiStepIcon-completed": {
            color: successColor[0]
        }
    }
});

const useQontoStepIconStylesRed = makeStyles({
    stepIconRoot: {
        color: "red",
        "&.MuiStepIcon-active": {
            color: dangerColor[0]
        },
        "&.MuiStepIcon-completed": {
            color: dangerColor[0]
        }
    }
});

const useStyles = makeStyles(styles);

export default function IpoStatus(props) {
    const {
        stocksList
    } = props;

    const classes = useStyles();
    const stepperClasses = useQontoStepIconStyles();
    const stepperClassesRed = useQontoStepIconStylesRed();
    const steps = getSteps();
    const stockBuilder = (stocksList && stocksList[0]) ? stocksList[0] : null;

    function getSteps() {
        return ['Private', 'Filed', 'Expected', 'Priced'];
    }

    function getStepIndexFromStatus(status_) {
        switch (status_) {
            case "priced": return 3;
            case "expected": return 2;
            case "filed": return 1;
            case "withdrawn": return 0;
            default: return 0;
        }
    }

    function getStatusFromIndex(index_) {
        switch (index_) {
            case 3: return "priced";
            case 2: return "expected";
            case 1: return "filed";
            case 0: return "withdrawn";
            default: return "filed";
        }
    }

    const [ipoStatusDates, setipoStatusDates] = React.useState([]);

    React.useEffect(() => {

        async function getIpoStatusDates() {
            if (DEBUG) console.log(TAG + "getIpoStatusDates called");
            let statusDates = [];
            for (var stock_ of stocksList) {
                if (stock_ && !stock_.isUnknown(stock_.getStatus()) && !stock_.isUnknown(stock_.getDate())) {
                    statusDates[stock_.getStatus()] = stock_.getDate();
                }
            }
            setipoStatusDates(statusDates);
        }

        if (!stocksList || !stocksList[0]) return;
        getIpoStatusDates();
    }, [stocksList, stockBuilder, classes])
 
    return (
        stockBuilder ?
            <Card xs={6}>
                    <CardBody >
                    <Stepper activeStep={getStepIndexFromStatus(stockBuilder.getStatus())} connector={<QontoConnector />}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            
                            let labelDate = ipoStatusDates[getStatusFromIndex(index)];
                            if (labelDate && labelDate.length > 1) {
                                labelProps.optional = <p> {labelDate} </p>;
                            }
                            let stepperClasses_ = stepperClasses;
                            if (index === 0 && stockBuilder.getStatus().includes("withdrawn")) {
                                label = "Withdrawn";
                                stepperClasses_ = stepperClassesRed;
                                labelProps.error = true;
                            }
                            labelProps.StepIconProps = { classes: { root: stepperClasses_.stepIconRoot } };
                            return (
                                <Step
                                    key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    </CardBody>
                </Card>
            : <div/>
    );
}

IpoStatus.defaultProps = {
    stocksList: null
    
};
