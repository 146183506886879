import NewsBuilder from 'builders/NewsBuilder';
import Scraper from 'utilities/ImageScrapper';
import RssParser from 'utilities/RssParser/RssParser';
import FireBaseManager from "managers/FireBaseManager";

const TAG = "Ipo-Tracker: NewsManager ";
const DEBUG = false;

const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"
//const CORS_PROXY = "https://thingproxy.freeboard.io/fetch/"
//const CORS_PROXY = "http://localhost:5000/"; 
//const CORS_PROXY = "https://young-depths-78916.herokuapp.com/";
//const CORS_PROXY = "";

const NEWS_SERVER = "https://ipo-tracker-news-server.herokuapp.com"; 
const NEWS_SERVER_PATH = "/news/get-news/?provider="; 
const NEWS_SERVER_SEARCH_PATH = "/news/search-news/"; 

const KEY_THUMBNAILS_CACHE = "ipoTracker_thumbnails";
const MAX_CACHED_THUMBNAILS = 100;
const DELAY_TO_UPDATE_NEWS = 5 * 60 * 60* 1000; //3 Hours

const NewsProviders = {
    GNEWS: {
        name: "MIX",
        url: "https://news.google.com/rss/search?q=ipo&hl=en-US&gl=US&ceid=US:en",
        newsServerUrl: NEWS_SERVER + NEWS_SERVER_PATH + "gnews",
        storagePath: "newsFeed/gnews/feed.xml"
    },
    CNBC: {
        name: "CNBC",
        url: "https://www.cnbc.com/id/10000666/device/rss",
        newsServerUrl: NEWS_SERVER + NEWS_SERVER_PATH + "cnbc",
        storagePath: "newsFeed/cnbc/feed.xml"
    },
    NASDAQ: {
        name: "NASDAQ",
        url: "https://www.nasdaq.com/feed/rssoutbound?category=IPOs",
        newsServerUrl: NEWS_SERVER + NEWS_SERVER_PATH + "nasdaq",
        storagePath: "newsFeed/nasdaq/feed.xml"
    },
    YAHOO: {
        name: "Yahoo Finane",
        url: "https://news.google.com/rss/search?q=ipo Yahoo finance&hl=en-US&gl=US&ceid=US:en",
        newsServerUrl: NEWS_SERVER + NEWS_SERVER_PATH + "yahoo",
        storagePath: "newsFeed/yahoo/feed.xml"
    },
    WSJ: {
        name: "WSJ",
        url: "https://news.google.com/rss/search?q=ipo wall street journal&hl=en-US&gl=US&ceid=US:en",
        newsServerUrl: NEWS_SERVER + NEWS_SERVER_PATH + "wsj",
        storagePath: "newsFeed/wsj/feed.xml"
    },
    MARKETWATCH: {
        name: "Market Watch",
        url: "https://news.google.com/rss/search?q=ipo marketwatch&hl=en-US&gl=US&ceid=US:en",
        newsServerUrl: NEWS_SERVER + NEWS_SERVER_PATH + "market-watch",
        storagePath: "newsFeed/market-watch/feed.xml"
    },
    SALPHA: {
        name: "Seeking Alpha",
        url: "https://seekingalpha.com/tag/ipo-analysis.xml",
        newsServerUrl: NEWS_SERVER + NEWS_SERVER_PATH + "seeking-alpha",
        storagePath: "newsFeed/seeking-alpha/feed.xml"
    },
    MFOOL: {
        name: "Motley Fool",
        url: "https://news.google.com/rss/search?q=ipo motley fool&hl=en-US&gl=US&ceid=US:en",
        newsServerUrl: NEWS_SERVER + NEWS_SERVER_PATH + "motley-fool",
        storagePath: "newsFeed/motley-fool/feed.xml"
    }
};

function NewsManager() {
    if (DEBUG) console.log(TAG + "Instance created");
    this.newsList = null;
    this.rssParser = new RssParser({
        headers: {
            'User-Agent': 'PostmanRuntime/7.26.5',
            'Accept': '*/*',
            'Connection': 'keep-alive'
        },
        customFields: {
            item: [
                ['media:content', 'media'],
                ['thumbnail', 'thumbnail'],
            ]
        }
    });
    this.cachedThumbnails = {};
    
    let cachedList = localStorage.getItem(KEY_THUMBNAILS_CACHE);
    if (cachedList) this.cachedThumbnails = JSON.parse(cachedList);
    if (this.cachedThumbnails && Object.keys(this.cachedThumbnails).length > MAX_CACHED_THUMBNAILS) {
        if (DEBUG) console.log(TAG + "Clear all cached thumbnails");
        this.cachedThumbnails = {};
        localStorage.removeItem(KEY_THUMBNAILS_CACHE);
    }
}

NewsManager.prototype.fetchNews = async function (provider_) {
    if (DEBUG) console.log(TAG + "fetchNews called for " + provider_.name);
    if (!this.newsList) {
        this.newsList = [];

        let feed = await getNewsFromFirebase(this, provider_);
        if (!feed || chackIfNewsNeedUpdate(feed.pubDate)) {
            feed = await getFromNewsServer(this, provider_);
        }

        if (!feed || !feed.items || feed.items.length < 1) {
            return this.newsList;
        }
        feed.items.forEach(item => {
            let foundNews_ = new NewsBuilder(item.title, item.link, item.description, item.pubDate, item.guid);
            if (item.thumbnail) {
                foundNews_.setThumbnail(item.thumbnail);
            } else if (item.media) {
                foundNews_.setThumbnail(parseThumbnail(item.media));
            }
            this.newsList.push(foundNews_);
        });
    }
    return this.newsList;
}

NewsManager.prototype.fetchSpecificNews = async function (companyName_ ) {
    if (DEBUG) console.log(TAG + "fetchSpecificNews called for " + companyName_);

    if (!this.newsList) {
        this.newsList = [];

        let feed = await getSpecificNewsFromFirebase(this, companyName_);
        if (!feed || chackIfNewsNeedUpdate(feed.pubDate)) {
            feed = await getSpecificFromNewsServer(this, companyName_);
        }

        if (!feed || !feed.items || feed.items.length < 1) {
            return this.newsList;
        }
        feed.items.forEach(item => {
            let foundNews_ = new NewsBuilder(item.title, item.link, item.description, item.pubDate, item.guid);
            if (item.thumbnail) {
                foundNews_.setThumbnail(item.thumbnail);
            } else if (item.media) {
                foundNews_.setThumbnail(parseThumbnail(item.media));
            }
            this.newsList.push(foundNews_);
        });
    }
    return this.newsList;
}

NewsManager.prototype.fetchNewsThumbnail = async function (newsLink_) {
    if (DEBUG) console.log(TAG + "fetchThumbnail called ");
    if (!newsLink_ || newsLink_.length < 1) {
        console.error(TAG + "Invalid news Link ");
        return null;
    }

    if (this.cachedThumbnails && this.cachedThumbnails[newsLink_]) {
        if (DEBUG) console.log(TAG + "fetchThumbnail return from cache");
        return this.cachedThumbnails[newsLink_];
    }
    let context = this;
    return new Promise(async (resolve, reject) => {
        try {
            this.scraper = new Scraper(newsLink_, CORS_PROXY);
            let selectedImage = null;
            
            let success = this.scraper.scrape(function (image) {

                if (selectedImage) return;
                if (!image) return;

                /*****************************************
                * Check if has meta image
                ***************************************/
                if (image.isMetaProp) {
                    if (image.address) {
                        selectedImage = image;
                        return;
                    }
                    return;
                }

                /*****************************************
                 * Check if valid image Url
                 * **************************************/
                if (!image.address) {
                    if (DEBUG) console.log("invaid image url");
                    return;
                }
                try {
                    const checkIfValidUrl = new URL(image.address);
                    if (!checkIfValidUrl.hostname || checkIfValidUrl.hostname.length < 1) {
                        return;
                    }
                } catch (e) {
                    return;
                }
                if (image.address.includes(CORS_PROXY)) {
                    console.log("image with proxy url, Fix Me !");
                    return;
                }

                /*****************************************
                 * Check if logo or user image
                 * **************************************/
                let modifiedAttributes = image.attributes;
                let stringAttributes = modifiedAttributes ? JSON.stringify(modifiedAttributes).toLowerCase() : "";

                if (stringAttributes.includes("logo")
                    || stringAttributes.includes("icon")
                    || stringAttributes.includes("rss-sub")
                    || stringAttributes.includes("avatar")
                    || stringAttributes.includes("author")
                    || stringAttributes.includes("loading")
                    || stringAttributes.includes("users_profile")
                    || stringAttributes.includes("themes")
                    || stringAttributes.includes("hidden")) {
                    if (DEBUG) console.log("Skipped image for logo");
                    return;
                }

                if (stringAttributes.includes("article")) {
                    if (DEBUG) console.log("image att with article");
                    selectedImage = image;
                    return;
                }


                /*****************************************
                * Check if small image
                ***************************************/
                if (image.attributes.width &&
                    (image.attributes.width === "1"
                        || image.attributes.width === "0"
                        || image.attributes.width.includes("1.0"))) {
                    if (DEBUG) console.log("Skipped image small width");
                    return;
                }

                /*****************************************
                * Check if button or widget image
                ***************************************/
                /*if (stringAttributes.includes("misc")
                    || stringAttributes.includes("marker")
                    || stringAttributes.includes("button")
                    || stringAttributes.includes("scorecard")) {
                    console.log("Skipped image for buttons");
                    return;
                }*/

                /*****************************************
                * Check if specific ul to filter
                ***************************************/
                if (stringAttributes.includes("miro.medium.com/fit/")
                    || stringAttributes.includes("miro.medium.com/max/60")) {
                    if (DEBUG) console.log("Skipped custom urls");
                    return;
                }

                if (DEBUG) console.log("Found image: " + image.address);
                if (DEBUG) console.log("Found attributes: " + JSON.stringify(image.attributes));
                selectedImage = image;

            });
            if (!success) {
                resolve(null);
            }
            this.scraper.on("end", function () {
                if (selectedImage && selectedImage.address) {
                    if (DEBUG) console.log(TAG + "fetchThumbnail found thumbnail ");
                    addCachedThumbnail(context, newsLink_, selectedImage.address);
                    resolve(selectedImage.address);
                } else {
                    if (DEBUG) console.log(TAG + "fetchThumbnail unable to find thumbnail ");
                    addCachedThumbnail(context, newsLink_, null);
                    resolve(null);
                }
            });
        } catch (e) {
            if (DEBUG) console.error(TAG + "fetchThumbnail error: " + e);
            resolve(null);
        }
    });

}

NewsManager.prototype.StopFetchNewsThumbnail = async function () {
    if (DEBUG) console.log(TAG + "StopFetchNewsThumbnail called");
    if (this.scraper) this.scraper.stop();
}

async function getNewsFromFirebase(context_, provider_) {
    if (DEBUG) console.log(TAG + "getNewsFromFirebase called for: " + provider_.name);
    const firebaseManager = new FireBaseManager();
    let feedUrl_ = await firebaseManager.getNewsDownloadUrl(provider_.storagePath);
    if (!feedUrl_ || feedUrl_.length < 1) return null;

    return new Promise(async (resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'text';
        xhr.onload = async function (event) {
            if (this.status === 200) {
                let feed = await context_.rssParser.parseString(xhr.responseText)
                    .catch(e => {
                        if (DEBUG) console.error(TAG + "getNewsFromNewsServer catched : " + e);
                        resolve(null);
                    });
                resolve(feed);
            } else {
                resolve(null);
            }
        };
        xhr.open('GET', feedUrl_);
        xhr.send();
    });
}

async function getFromNewsServer(context_, provider_) {
    if (DEBUG) console.log(TAG + "getFromNewsServer called for: " + provider_.name);
    let feed = await context_.rssParser.parseURL(provider_.newsServerUrl)
        .catch(e => {
            if (DEBUG) console.error(TAG + "getNewsFromNewsServer error: " + e);
            return null;
        });
    return feed;
}

async function getSpecificNewsFromFirebase(context_, name_) {
    if (DEBUG) console.log(TAG + "getSpecificNewsFromFirebase called for: " + name_);
    let formattedName = formattName(name_);
    let storagePath = "newsFeed/" + formattedName + "/feed.xml";
    const firebaseManager = new FireBaseManager();
    let feedUrl_ = await firebaseManager.getNewsDownloadUrl(storagePath);
    if (!feedUrl_ || feedUrl_.length < 1) return null;

    return new Promise(async (resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'text';
        xhr.onload = async function (event) {
            if (this.status === 200) {
                let feed = await context_.rssParser.parseString(xhr.responseText)
                    .catch(e => {
                        if (DEBUG) console.error(TAG + "getSpecificNewsFromFirebase catched : " + e);
                        resolve(null);
                    });
                resolve(feed);
            } else {
                if (DEBUG) console.error(TAG + "getSpecificNewsFromFirebase http error : " + this.status);
                resolve(null);
            }
        };
        xhr.open('GET', feedUrl_);
        xhr.send();
    });
}

async function getSpecificFromNewsServer(context_, name_) {
    if (DEBUG) console.log(TAG + "getSpecificFromNewsServer called for: " + name_);
    let searchUrl = NEWS_SERVER + NEWS_SERVER_SEARCH_PATH;
    let searchQuery = "?";
    let searchText_ = formatTextForQuery(name_);
    if (searchText_) { searchQuery = searchQuery + "name=" + searchText_ }
    searchUrl = searchUrl + searchQuery;
    if (DEBUG) console.log(TAG + "getSpecificFromNewsServer search url: " + searchUrl);
    let feed = await context_.rssParser.parseURL(searchUrl)
        .catch(e => {
            if (DEBUG) console.error(TAG + "getSpecificFromNewsServer error: " + e);
            return null;
        });
    return feed;
}

function addCachedThumbnail(context_, newsLink_, thumbnailLink_) {
    if (DEBUG) console.log(TAG + "addCachedThumbnail called");
    if (!context_ || !newsLink_) return;
    let cachedList = localStorage.getItem(KEY_THUMBNAILS_CACHE);
    if (cachedList) context_.cachedThumbnails = JSON.parse(cachedList);
    if (!context_.cachedThumbnails) context_.cachedThumbnails = {};
    context_.cachedThumbnails[newsLink_] = thumbnailLink_;
    localStorage.setItem(KEY_THUMBNAILS_CACHE, JSON.stringify(context_.cachedThumbnails));
}

function parseThumbnail(mediaItem_) {
    try {
        return mediaItem_.$.url;
    } catch (err) {
        return null;
    }
}

function formatTextForQuery(text_) {
    if (!text_) return null;
    let searchText_ = text_.toLowerCase();
    searchText_ = searchText_.replace(/\W/g, '+');
    searchText_ = searchText_.replace(/\s+/g, '+');
    return searchText_;
}

function formattName(companyName_) {
    let searchText_ = companyName_.toLowerCase();
    searchText_ = searchText_.replace(/\W/g, '');
    searchText_ = searchText_.replace(/\s+/g, '');
    searchText_ = searchText_.trim();
    return searchText_;
}

function chackIfNewsNeedUpdate(newsPubDate_) {
    if (!newsPubDate_) return true;
    if(DEBUG) console.log(TAG + "chackIfNewsNeedUpdate news date: " + newsPubDate_);
    var feedDate = new Date(newsPubDate_);
    var nowdate = new Date();
    if (DEBUG) console.log(TAG + "chackIfNewsNeedUpdate now date: " + nowdate.toUTCString());
    var dateDiff = nowdate.getTime() - feedDate.getTime() ;
    if (DEBUG) console.log(TAG + "chackIfNewsNeedUpdate diff date in minutes: " + Math.round(((dateDiff % 86400000) % 3600000) / 60000));
    let result = dateDiff > DELAY_TO_UPDATE_NEWS;
    if (DEBUG) console.log(TAG + "chackIfNewsNeedUpdate return " + result);
    return result
}

export { NewsManager, NewsProviders };