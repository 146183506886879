/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Success from "components/Typography/Success.js";

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertStyles);

export default function Footer(props) {
  const classes = useStyles();
  const history = useHistory();
  const alertClasses = useStylesAlert();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
    const [alert, setAlert] = React.useState(null);
    const showContact = () => {
        console.log("show contact called");
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Contact Information"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.info}
            >
                For help, suggestions, feedback or bug report please contact : 
                <h3> <Success> costumers.ingenuity@gmail.com </Success> </h3>
            </SweetAlert>
        );
    };

  return (
      <footer className={classes.footer}>
          {alert}
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
            <a href="" onClick={e => {
                e.preventDefault();
                history.push("/u/home");
            }}>
                {"Home"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <a href="" onClick={e => {
                e.preventDefault();
                history.push("/u/terms-conditions");
            }}>
                {"Terms & Conditions"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <a href="" onClick={e => {
                e.preventDefault();
                history.push("/u/privacy-policy");
            }}>
                {"Privacy policy"}
            </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
             <a href="" onClick={e => {
                e.preventDefault();
                history.push("/u/disclaimer");
            }}>
                {"Disclaimer"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
                          <a href="" onClick={e => {
                              e.preventDefault();
                              showContact();
                          }}
                              className={block}>
                              {"Contact"}
                          </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{" "}
          <span style={{ color: "purple" }}>{"Ingenuity Apps "}</span>
          {", made by traders for traders"}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
