import React from "react";
import VizSensor from 'react-visibility-sensor';

// custom spinner
import { css } from "@emotion/core";
import Spinner from "react-spinners/ScaleLoader";
import { successColor } from "assets/jss/material-dashboard-pro-react.js";

//Custom stuff
import { NewsManager } from "managers/NewsManager";
import FireBaseUserManager from "managers/FireBaseUserManager";

//Global variables
const TAG = "Ipo-Tracker: NewsThumbnail ";
const DEBUG = false;
const newsManager = new NewsManager();
const fireBaseUserManager = new FireBaseUserManager();

const DEFAULT_ERROR_IMAGE = "https://firebasestorage.googleapis.com/v0/b/ipo-tracker-30979.appspot.com/o/defaultResources%2Ferror_default.png?alt=media&token=35568e0f-dbf6-4166-a824-c9af53e0429e";
const DEFAULT_NASDAQ_THUMBNAIL = "https://firebasestorage.googleapis.com/v0/b/ipo-tracker-30979.appspot.com/o/defaultResources%2Fnasdaq_default.jpg?alt=media&token=b7cb09e8-cf54-4d4f-adbb-b8e060e8d717";
const DEFAULT_CNBC_THUMBNAIL = "https://firebasestorage.googleapis.com/v0/b/ipo-tracker-30979.appspot.com/o/defaultResources%2Fcnbc_default.jpg?alt=media&token=6e79503f-bb0d-41b5-90b4-0d8134133220";
const DEFAULT_SEEKING_ALPHA = "https://firebasestorage.googleapis.com/v0/b/ipo-tracker-30979.appspot.com/o/defaultResources%2FSeekingalpha_default.jpg?alt=media&token=93b23905-c890-4513-aa3d-0426eb27feff";

const override = css`
  display: block;
  text-align: center;
  width: 100%;
`;

function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}

export default function NewsThumbnail(props) {
    const {
        newsUrl,
        newsThumbnail,
        styleClassName
    } = props;

    const [fetchedThumbnailUrl, setFetchedThumbnailUrl] = React.useState(null);
    const [fetchFinished, setFetchFinished] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        async function fetchThumbnail() {
            if (isVisible) {
                if (DEBUG) console.log(TAG + "fetchThumbnail for " + newsUrl);
                if (newsUrl.includes("www.nasdaq.com")) {
                    setFetchFinished(true);
                    setFetchedThumbnailUrl(DEFAULT_NASDAQ_THUMBNAIL);
                    return;
                }
                newsManager.fetchNewsThumbnail(newsUrl).then(async (foundThumbnail_) => {
                    setFetchFinished(true);
                    if (!foundThumbnail_) {
                        if (newsUrl.includes("www.cnbc.com")) {
                            setFetchFinished(true);
                            setFetchedThumbnailUrl(DEFAULT_CNBC_THUMBNAIL);
                        } else if (newsUrl.includes("seekingalpha.com")) {
                            setFetchFinished(true);
                            setFetchedThumbnailUrl(DEFAULT_SEEKING_ALPHA); 
                        }
                        if (DEBUG) console.log(TAG + "Unable to find thumbnail");
                        return;
                    }
                    setFetchedThumbnailUrl(foundThumbnail_);
                }).catch(e => {
                    console.error(TAG + "fetchThumbnail Catched exeption: " + e);
                });
            } else {
                if (DEBUG) console.log(TAG + "StopFetchThumbnail for " + newsUrl);
                newsManager.StopFetchNewsThumbnail();
            }
        }

        if (!newsUrl || newsUrl.length < 1) return;
        if (newsThumbnail && newsThumbnail.length > 1) return;
        if (fetchFinished) return;
        if (fetchedThumbnailUrl && fetchedThumbnailUrl.legth > 1) return;
        if (fireBaseUserManager.checkIfAllowedCrawler()) return;
        fetchThumbnail();

    }, [newsUrl, newsThumbnail, fetchedThumbnailUrl, isVisible, fetchFinished])

    if (fetchedThumbnailUrl || newsThumbnail) {
        //already have thumbnail
        return (
            <a href="#pablo" className={styleClassName} onClick={e => {
                e.preventDefault();
                openInNewTab(newsUrl);
            }}>
                <img src={newsThumbnail ? newsThumbnail : fetchedThumbnailUrl} alt="..." style={{
                    backgroundColor: 'white',
                    padding: '3px',
                    maxHeight: '300px',
                    minHeight: '300px'
                }}
                onError={(e) => { e.target.onerror = null; e.target.src = DEFAULT_ERROR_IMAGE }}
                />

            </a>
        );
    } else if (fetchFinished) {
        //fetch finished without thumbnail
        return (<div />);
    } else {
        //wait for element to become visible
        return (
            <VizSensor
                className={styleClassName}
                active={true}
                scrollCheck={true}
                onChange={(isVisible_) => {
                    setIsVisible(isVisible_);
                }}>
                <Spinner css={override} size={7} color={successColor[0]}/>
            </VizSensor>
        );
    }
    
}

NewsThumbnail.defaultProps = {
    newsUrl: null,
    newsThumbnail: null,
    styleClassName: null
};
