import React from "react";
import MetaTags from 'react-meta-tags';
import { detectAnyAdblocker } from 'just-detect-adblock';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';

// material-ui icons
import PricedIcon from '@material-ui/icons/AlarmOn';
import ExpectedIcon from '@material-ui/icons/Snooze';
import FiledIcon from '@material-ui/icons/AddAlarm';
import WithdrawnIcon from '@material-ui/icons/AlarmOff';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Pagination from "components/Pagination/Pagination.js";
import Accordion from "components/Accordion/Accordion.js";
import SweetAlert from "react-bootstrap-sweetalert";

//Styles
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

//Custom stuff
import MonthsPagesManager from "managers/MonthsPagesManager";
import AdsenseView from 'utilities/AdsenseView';

//Global variables
const TAG = "Ipo-Tracker: CalendarView ";
const DEBUG = false;
const useStylesAlert = makeStyles(alertStyles);
const monthsPagesManager = new MonthsPagesManager();
const showLoadingBar = <LinearProgress />;
const defaultTableHeader = ["", "Company Name", "Symbol", "Exchange", "Price", "Variation", "Shares", "Offer Amount", "Date"];
const emptyTableHeader = ["No data to be displayed"];
const emptyTableContent = [[""]];

const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function onPageClicked(index_, setPagesStates_, setRequestRefresh_) {
    if (DEBUG) console.log(TAG + "onPageClicked called");
    monthsPagesManager.setSelectedPage(index_);
    setPagesStates_(monthsPagesManager.getPagesStates());
    setRequestRefresh_(true);
}

function onNextPagesClicked(previous_, setPagesStates_, setRequestRefresh_) {
    if (DEBUG) console.log(TAG + "onNextPagesClicked called");
    monthsPagesManager.updatePagesYear(previous_);
    setPagesStates_(monthsPagesManager.getPagesStates());
    setRequestRefresh_(true);
}

export default function RegularTables() {
    const classes = useStyles();
    const alertClasses = useStylesAlert();
    const history = useHistory();
    const [pricedData, setPricedData] = React.useState([]);
    const [expectedData, setExpectedData] = React.useState([]);
    const [filedData, setFiledData] = React.useState([]);
    const [withdrawnData, setWithdrawnData] = React.useState([]);
    const [pagesStates, setPagesStates] = React.useState(monthsPagesManager.getPagesStates());
    const [requestRefresh, setRequestRefresh] = React.useState(true);
    const [loadingBar, setLoadingBar] = React.useState(showLoadingBar);
    const [tableHeaderPriced, setTableHeaderPriced] = React.useState(defaultTableHeader);
    const [tableHeaderExpected, setTableHeaderExpected] = React.useState(defaultTableHeader);
    const [tableHeaderFiled, setTableHeaderFiled] = React.useState(defaultTableHeader);
    const [tableHeaderWithdrawn, setTableHeaderWithdrawn] = React.useState(defaultTableHeader);
    const isRefreshing = React.useRef(false);
    const [adBlockDetected, setAdBlockDetected] = React.useState(false);

    monthsPagesManager.setHistory(history);

    React.useEffect(() => {
        async function fetchData() {
            if (isRefreshing.current) {
                console.log(TAG + "skip fetchData is already running");
                return;
            }
            isRefreshing.current = true;
            if (DEBUG) console.log(TAG + "fetchData called");
            if (requestRefresh) {
                setLoadingBar(showLoadingBar);
            } else {
                setLoadingBar();
            }

            let newPricedData = await monthsPagesManager.getSelectedPriced();
            if (newPricedData && !newPricedData.length < 1) {
                setTableHeaderPriced(defaultTableHeader);
                await setPricedData(newPricedData);
            } else {
                setTableHeaderPriced(emptyTableHeader);
                setPricedData(emptyTableContent);
            }

            let newExpectedData =  await monthsPagesManager.getSelectedExpected();
            if (newExpectedData && !newExpectedData.length < 1) {
                setTableHeaderExpected(defaultTableHeader);
                await setExpectedData(newExpectedData);
            } else {
                setTableHeaderExpected(emptyTableHeader);
                setExpectedData(emptyTableContent);
            }

            let newFiledData = await monthsPagesManager.getSelectedFiled();
            if (newFiledData && !newFiledData.length < 1) {
                setTableHeaderFiled(defaultTableHeader);
                await setFiledData(newFiledData);
            } else {
                setTableHeaderFiled(emptyTableHeader);
                setFiledData(emptyTableContent);
            }

            let newWithdrawnData = await monthsPagesManager.getSelectedWithdrawn();
            if (newWithdrawnData && !newWithdrawnData.length < 1) {
                setTableHeaderWithdrawn(defaultTableHeader);
                await setWithdrawnData(newWithdrawnData);
            } else {
                setTableHeaderWithdrawn(emptyTableHeader);
                setWithdrawnData(emptyTableContent);
            }

            if (requestRefresh) {
                setRequestRefresh(false);
            }
            setLoadingBar();
            isRefreshing.current = false;
            if (DEBUG) console.log(TAG + "finished refresh");
        }

        detectAnyAdblocker().then((detected) => {
            if (detected) {
                // an adblocker is detected
                console.log("Adblocker is detected !!");
                setAdBlockDetected(true);
            } else {
                fetchData();
            }
        });
    }, [requestRefresh]);

    return (
        adBlockDetected ?
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="An Blocker is detected !"
                onConfirm={() => window.location.reload()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="I have disabled AdBlock"
            >
                This an AD sponsored website. Please disable your Ad blocker to enjoy our free tools.
            </SweetAlert>
            :
        <div>
            <AdsenseView slotType="homeTop" />
            <GridContainer justify="center">
            <MetaTags>
                <title>IPO Calendar</title>
                <meta name="description" content="Discover the latest latest and upcoming initial public offering calendar. We also provide historical IPO data." />
                <meta property="og:title" content="IPO Calendar" />
            </MetaTags>

            
            <GridItem xs={12} sm={12}>
                <div style={{ textAlign: "center" }}>
                <Pagination 
                 pages={[
                            { text: "<<<", onClick: () => onNextPagesClicked(true, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(0).getPageTitle(), active: pagesStates.get(0).getPageActive(), onClick: () => onPageClicked(0, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(1).getPageTitle(), active: pagesStates.get(1).getPageActive(), onClick: () => onPageClicked(1, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(2).getPageTitle(), active: pagesStates.get(2).getPageActive(), onClick: () => onPageClicked(2, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(3).getPageTitle(), active: pagesStates.get(3).getPageActive(), onClick: () => onPageClicked(3, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(4).getPageTitle(), active: pagesStates.get(4).getPageActive(), onClick: () => onPageClicked(4, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(5).getPageTitle(), active: pagesStates.get(5).getPageActive(), onClick: () => onPageClicked(5, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(6).getPageTitle(), active: pagesStates.get(6).getPageActive(), onClick: () => onPageClicked(6, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(7).getPageTitle(), active: pagesStates.get(7).getPageActive(), onClick: () => onPageClicked(7, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(8).getPageTitle(), active: pagesStates.get(8).getPageActive(), onClick: () => onPageClicked(8, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(9).getPageTitle(), active: pagesStates.get(9).getPageActive(), onClick: () => onPageClicked(9, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(10).getPageTitle(), active: pagesStates.get(10).getPageActive(), onClick: () => onPageClicked(10, setPagesStates, setRequestRefresh) },
                            { text: pagesStates.get(11).getPageTitle(), active: pagesStates.get(11).getPageActive(), onClick: () => onPageClicked(11, setPagesStates, setRequestRefresh) },
                            { text: ">>>", onClick: () => onNextPagesClicked(false, setPagesStates, setRequestRefresh) }
                ]}
                        color="success"
                />
                </div>
            </GridItem>
            <GridItem xs={12}>
                <Card xs={12}>
                    <CardBody>
                        <Accordion
                            collapses={[
                                {
                                    title:
                                        <CardHeader color="success" icon justify="center" style={{ minWidth:"300px"}} >
                                                <CardIcon color="success">
                                                    <PricedIcon />
                                                </CardIcon>
                                                <h4 className={classes.cardIconTitle}>Priced IPOs</h4>
                                        </CardHeader>    
                            ,content:
                                <Card>
                                    <CardBody>
                                            {loadingBar}
                                        <Table
                                            hover
                                                striped
                                                tableHeaderColor="primary"
                                                tableHead={tableHeaderPriced}
                                                tableData={pricedData}
                                            coloredColls={[8]}
                                                colorsColls={["primary", "primary"]}
                                            />
                                        </CardBody>
                                </Card>
                        }, {
                                    title: 
                                        <CardHeader color="info" icon justify="center" style={{ minWidth: "300px" }} >
                                    <CardIcon color="info">
                                        <ExpectedIcon />
                                            </CardIcon>
                                    <h4 className={classes.cardIconTitle}>Expected IPOs</h4>
                                        </CardHeader>
                                  , content:
                                        <Card>
                                        <CardBody>
                                            {loadingBar}
                                              <Table
                                                  hover
                                            striped

                                                tableHeaderColor="primary"
                                                tableHead={tableHeaderExpected}
                                                tableData={expectedData}
                                                coloredColls={[8]}
                                                colorsColls={["primary", "primary"]}
                                            />
                                        </CardBody>
                                    </Card>
                                },
                                {
                                    title: 
                                        <CardHeader color="warning" icon justify="center" style={{ minWidth: "300px" }} >
                                    <CardIcon color="warning">
                                        <FiledIcon />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>Filed IPOs</h4>
                                </CardHeader>
                                    , content:
                                        <Card>
                                        <CardBody>
                                            {loadingBar}
                                                <Table
                                                    hover
                                            striped
                                                tableHeaderColor="primary"
                                                tableHead={tableHeaderFiled}
                                                tableData={filedData}
                                                    coloredColls={[8]}
                                                colorsColls={["primary", "primary"]}
                                            />
                                        </CardBody>
                                    </Card>
                                },
                                {
                                    title: 
                                        <CardHeader color="danger" icon justify="center" style={{ minWidth: "300px" }} >
                                    <CardIcon color="danger">
                                        <WithdrawnIcon />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>Withdrawn IPOs</h4>
                                </CardHeader>
                                    , content:
                                        <Card>
                                        <CardBody>
                                            {loadingBar}
                                                <Table
                                                    hover
                                            striped
                                                tableHeaderColor="primary"
                                                tableHead={tableHeaderWithdrawn}
                                                tableData={withdrawnData}
                                                    coloredColls={[8]}
                                                colorsColls={["primary", "primary"]}
                                            />
                                        </CardBody>
                                    </Card>
                                }]} />
                    </CardBody>
                </Card>,
        </GridItem>
            
        </GridContainer>
            <AdsenseView slotType="homeBottom" />
        </div>
    );
}
