var fs = require("fs");
var http = require("http");
var https = require("https");
var path = require("path");
var url = require("url");
var events = require("events").EventEmitter;
var util = require("util");

var cheerio = require("cheerio");

const TAG = "Ipo-Tracker: ImageScrapper ";
const DEBUG = false;

// The "Image" class.
function Image(image, address, isMeta) {
	if (!image || !image.attribs) return null;
	this.isMetaProp = isMeta;
	this.attributes = image.attribs;
	var attScr = this.attributes.src;
	if (isMeta) {
		attScr = this.attributes.content;
    }
	if (attScr) {
		this.name = path.basename(attScr, path.extname(attScr));
		this.saveTo = path.dirname(require.main.filename) + "/";
		this.extension = path.extname(attScr);
		this.address = (address && attScr) ? url.resolve(address, attScr) : null;
		this.fromAddress = address;
	}
}

Image.prototype.save = function (callback) {

	var parsedUrl = url.parse(this.address);

	// Make a reference to the current instance.
	var ref = this;

	// Support HTTPS.
	var protocol = http;
	if (parsedUrl.protocol === "https:") {
		protocol = https;
	}

	var request = protocol.request(this.address, function (response) {

		if (response.statusCode !== 200) {

			console.error("Image scraper(3): image couldn't be found. (statusCode:" + response.statusCode + ")");
			return request.end();
		}
		else {

			var imageFile = fs.createWriteStream(path.normalize(ref.saveTo + ref.name + ref.extension));

			imageFile.on("error", function (e) {

				console.error("Image scraper(4): error while loading image: " + e + ".");
			});

			response.on("data", function (data) {

				imageFile.write(data);
			});

			response.on("end", function () {

				imageFile.end();

				if (typeof (callback) === "function") callback.call(ref);
			});
		}
	});

	request.end();
	request.on("error", function (e) {

		console.error(e);
	});
};

function Scraper(address , proxy) {
	events.call(this);
	this.address = address;
	this.proxy = proxy ? proxy : "";
	this.stopResquested = false;
}

// Inherit the methods of "events".
util.inherits(Scraper, events);

Scraper.prototype.scrape = function (callback) {
	if (DEBUG) console.log(TAG + "scrape called");
	// Make a reference to the current instance.
	this.stopResquested = false;
	var ref = this;
	if (!this.address || this.address.length < 1) return false;

	if (typeof (callback) === "function") {

		this.on("image", callback);
	}

	var parsedUrl = url.parse(this.address);

	if (!parsedUrl) return false;

	// Support HTTPS.
	var protocol = http;
	if (parsedUrl.protocol === "https:") {
		protocol = https;
	}

	var parsedProxyUrl = url.parse(this.proxy + this.address);
	var options = {
		path: parsedProxyUrl.pathname,
		host: parsedProxyUrl.hostname,
		port: parsedProxyUrl.port,
		timeout: 3000,
		method: 'GET',
		headers: { 'User-Agent': 'Mozilla/5.0', 'Accept': '*/*' }
	};

	if (ref.stopResquested) {
		if (DEBUG) console.log(TAG + "scrape stopped at 1");
		return true;
	}

	if (DEBUG) console.log(TAG + "http Get called");
	ref.request = protocol.request(options, function (response) {

		if (DEBUG) console.log(TAG + "http Get returned");
		if (ref.stopResquested) {
			if (DEBUG) console.log(TAG + "scrape stopped at 2");
			return true;
		}
		if (response.statusCode !== 200) {
			if (DEBUG) console.log(TAG + "web page couldn't be found. (statusCode:" + response.statusCode + ")");
			ref.emit("end");
			ref.request.end();
		} else {

			response.setEncoding("utf8");

			var previous = "";
			var foundMetaImage = false;

			response.on("data", function (data) {
				if (DEBUG) console.log(TAG + "http onData called");
				if (ref.stopResquested) {
					if (DEBUG) console.log(TAG + "scrape stopped at 3");
					return true;
				}
				var current = previous + data;

				current.replace(/<meta[^>]*?property="og:image"[^>]*?>/ig, function (m) {
					var image = new Image(cheerio.load(m)("meta")[0], ref.address, true);
					if (image) {
						foundMetaImage = true;
						ref.emit("image", image);
					}
				});

				if (!foundMetaImage) {
					current.replace(/<img[\S\s]*?>/ig, function (m) {
						var image = new Image(cheerio.load(m)("img")[0], ref.address, false);
						ref.emit("image", image);
					});
				}

				if (DEBUG) console.log(TAG + "scrape replace returned");
				previous = data;
			});

			response.on("end", function () {
				if (DEBUG) console.log(TAG + "http on end called");
				ref.emit("end");
			});

		}
	});
	ref.request.end();

	ref.request.on('timeout', () => {
		if (DEBUG) console.log(TAG + "http on timeout called");
		ref.request.abort();
		ref.emit("end");
	});

	ref.request.on("error", function (e) {
		if (DEBUG) console.log(TAG + "error while loading web page: " + e);
		ref.emit("end");
	});

	return true;
};

Scraper.prototype.stop = function () {
	if (DEBUG) console.log(TAG + "Stop called");
	this.stopResquested = true;
	if (this.request) this.request.end();
}

module.exports = Scraper;