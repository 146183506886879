import Home from "views/Custom/HomeView";
import News from "views/Custom/NewsView";
import Calendar from "views/Custom/CalendarView";
import TopPerformers from "views/Custom/TopPerformersView";
import Trending from "views/Custom/TrendingView";
import Login from "views/Custom/LoginView.js";
import UserProfile from "views/Custom/UserProfileView.js";
import UserWatchlist from "views/Custom/WatchlistView.js";
import TopAnticipated from "views/Custom/AnticipatedView.js";
import Etfs from "views/Custom/EtfView.js";
import Brokers from "views/Custom/BrokersView.js";
import Learning from "views/Custom/LearningView.js";
import PerformanceTracker from "views/Custom/PerformanceTrackerView.js";
import TermsConditions from "views/Custom/TermsConditionsView.js";
import Disclaimer from "views/Custom/DisclaimerView.js";
import PrivacyPolicy from "views/Custom/PrivacyPolicyView.js";
import StockInfo from "views/Custom/StockView.js";

// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import NewsIcon from '@material-ui/icons/MenuBook';
import CalendarIcon from '@material-ui/icons/Event';
import TopPerformersIcon from '@material-ui/icons/TrendingUp';
import TopTrendingIcon from '@material-ui/icons/ThumbsUpDown';
import TopAnticipatedIcon from '@material-ui/icons/HourglassEmpty';
import PerformanceIcon from '@material-ui/icons/Speed';
import EtfIcon from '@material-ui/icons/AccountBalance';
import BrokersIcon from '@material-ui/icons/AttachMoney';
import LearningIcon from '@material-ui/icons/LibraryBooks';

var dashRoutes = [
    {
        path: "/home",
        name: "Home",
        icon: HomeIcon,
        component: Home,
        layout: "/u"
    },
    {
        path: "/news",
        name: "IPO News",
        icon: NewsIcon,
        component: News,
        layout: "/u"
    },
    {
        path: "/calendar",
        name: "IPO Calendar",
        icon: CalendarIcon,
        component: Calendar,
        layout: "/u"
    },
    {
        path: "/top-performers",
        name: "Top Performers",
        icon: TopPerformersIcon,
        component: TopPerformers,
        layout: "/u"
    },
    {
        path: "/top-trending",
        name: "Top Trending",
        icon: TopTrendingIcon,
        component: Trending,
        layout: "/u"
    },
    {
        path: "/top-anticipated",
        name: "Top Anticipated",
        icon: TopAnticipatedIcon,
        component: TopAnticipated,
        layout: "/u"
    },
    {
        path: "/performance-tracker",
        name: "Performance Tracker",
        icon: PerformanceIcon,
        component: PerformanceTracker,
        layout: "/u"
    },
    {
        path: "/etfs",
        name: "IPO ETFs",
        icon: EtfIcon,
        component: Etfs,
        layout: "/u"
    },
    {
        path: "/brokers",
        name: "IPO Brokers",
        icon: BrokersIcon,
        component: Brokers,
        layout: "/u"
    },
    {
        path: "/learning",
        name: "IPO Learning",
        icon: LearningIcon,
        component: Learning,
        layout: "/u"
    },
    {
        path: "/login-page",
        name: "Log In",
        component: Login,
        layout: "/auth",
        redirect: true
    },
    {
        path: "/user-profile",
        name: "User profile",
        component: UserProfile,
        layout: "/u",
        redirect: true
    },
    {
        path: "/watchlist",
        name: "User watchlist",
        component: UserWatchlist,
        layout: "/u",
        redirect: true
    },
    {
        path: "/terms-conditions",
        name: "Terms & Conditions",
        component: TermsConditions,
        layout: "/u",
        redirect: true
    },
    {
        path: "/privacy-policy",
        name: "Privacy policy",
        component: PrivacyPolicy,
        layout: "/u",
        redirect: true
    },
    {
        path: "/disclaimer",
        name: "Disclaimer",
        component: Disclaimer,
        layout: "/u",
        redirect: true
    },
    {
        path: "/ipo-info",
        name: "IPO Info",
        component: StockInfo,
        layout: "/u",
        redirect: true
    }

];

export default dashRoutes;
